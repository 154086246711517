import { Box, Divider, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Logo from "../../Assets/Svg/sp-jain-logo.svg";
import KenButton from "../KenButton";
import KenInput from "../KenInput";
import converter from "number-to-words";
import { TABLET_BREAKPOINT } from '../../Constants/constant';

const useStyles = makeStyles((theme) => ({
	sectionLabel: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
	sectionSubLabel: {
		color: theme.palette.labels.main,
		fontWeight: 400,
		maxWidth: "512px",
	},
	smallLabel: {
		color: theme.palette.labels.light,
		fontWeight: 400,
		textTransform: "capitalize",
	},
	fee: {
		color: theme.palette.labels.medium,
		fontWeight: 600,
	},
	finalFee: {
		color: theme.palette.labels.main,
		fontWeight: 600,
	},
	applyBtn: {
		position: "relative",
		top: "30px",
		height: "30px",
	},
	payNow: {
		[theme.breakpoints.down(TABLET_BREAKPOINT)]: {
			textAlign: 'center',
			'& > button': {
				width: '80%',
			}
		}
	}
}));

function KenPaymentStep(props) {
	const {
		voucher = false,
		currencyCode = "₹",
		fee = "1000.00",
		finalFee = "750.00",
		feeAfterVoucher,
		feeType = "Application Fee",
		instituteName = "Application for SP Jain,Dubai",
		description = "Your Application for Mr.Aditya Nair for Postgraduation Program, MBA at SP Jain,Dubai has been saved successfully!",
		secondaryFeeLabel = "Application Fee After Discount",
		voucherLabel = "Do you have a discount code?",
		handlePayNow,
		handleApplyVoucher,
		handleRemoveVoucher,
	} = props;

	const classes = useStyles();
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down(
		TABLET_BREAKPOINT
	));

	return (
		<Box>
			<Grid container spacing={1}>
				<Grid item xs={12} md={3} textAlign={isMobileScreen ? 'left' : 'right'} >
					<Box pr={2}>
						<img src={Logo} />
					</Box>
				</Grid>
				<Grid container spacing={1} rowSpacing={9} item xs={12} md={9}>
					<Grid container spacing={1} item xs={12} md={12}>
						<Grid item xs={12} md={7} textAlign='left'>
							<Typography
								align='left'
								className={classes.sectionLabel}
								fontSize='20px'
							>
								{instituteName}
							</Typography>
						</Grid>
						{!isMobileScreen && <Grid item xs={12} md={5} textAlign='right'>
							<Typography variant='subtitle2' className={classes.smallLabel}>
								{feeType}
							</Typography>
						</Grid>
						}
						<Grid item xs={12} md={7} textAlign='left'>
							<Typography
								variant='subtitle2'
								className={classes.sectionSubLabel}
							>
								{description}
							</Typography>
						</Grid>
						{!isMobileScreen && <Grid item xs={12} md={5} textAlign='right'>
							<Typography
								className={classes.fee}
							>{`${currencyCode} ${fee}`}</Typography>
						</Grid>}
					</Grid>

					<Grid container spacing={1} item xs={12} md={12}>
						<Grid item xs={12} md={7} textAlign='left'>
							<Box display='flex' flexDirection='row'>
								<Box maxWidth={"260px"}>
									<KenInput label={voucherLabel} />
								</Box>
								<Box ml={1}>
									<KenButton
										label={feeAfterVoucher ? "Remove" : "Apply"}
										buttonClass={classes.applyBtn}
										onClick={
											feeAfterVoucher ? handleRemoveVoucher : handleApplyVoucher
										}
									/>
								</Box>
							</Box>
						</Grid>
						{voucher && feeAfterVoucher && (
							<Grid item xs={12} md={5} textAlign='right'>
								<Typography
									variant='subtitle2'
									className={classes.smallLabel}
									mb={1}
								>
									{secondaryFeeLabel}
								</Typography>
								<Typography variant='subtitle2' className={classes.finalFee}>
									{`${currencyCode} ${feeAfterVoucher}`}
								</Typography>
							</Grid>
						)}
					</Grid>

					<Grid item xs={12} container spacing={1}>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						{isMobileScreen && <Grid container p={1}>
							<Grid item xs={6} textAlign='left'>
								<Typography variant='subtitle2' className={classes.smallLabel} >
									{feeType}
								</Typography>
							</Grid>
							<Grid item xs={6} textAlign='right'>
								<Typography
									className={classes.fee}
								>{`${currencyCode} ${fee}`}</Typography>
							</Grid>
						</Grid>
						}
						<Grid item xs={12} textAlign='right'>
							<Typography className={classes.smallLabel} variant='subtitle2'>
								{`${converter.toWords(finalFee)} only`}
							</Typography>
						</Grid>
						<Grid item xs={12} textAlign='right' className={classes.payNow}>
							<KenButton
								variant='primary'
								label={`Pay ${currencyCode} ${finalFee}`}
								onClick={handlePayNow}
							></KenButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box >
	);
}
export default KenPaymentStep;
