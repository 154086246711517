import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { TABLET_BREAKPOINT } from "../../Constants/constant";

const useStyles = makeStyles((theme) => ({
  rootAccordionSummary: {
    borderBottom: ` 1px solid ${theme.palette.KenColors.grey4}`,

    '&> .MuiAccordionSummary-expandIconWrapper': {
      color: theme.palette.KenColors.neutral900,
    }
  },
  label: {
    color: theme.palette.KenColors.neutral900,
    textAlign: 'left',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      color: theme.palette.KenColors.neutral900,
    }
  },
  listContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '8px 0 16px 8px',
    }
  }
}));

export default function KenAccordion(props) {
  const { children, label } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(
    TABLET_BREAKPOINT
  ));

  return (
    <Box margin={isMobileScreen ? '16px' : undefined}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          classes={{ root: classes.rootAccordionSummary }}
        >
          <Typography variant='body2' sx={{ marginLeft: "-16px" }} className={classes.label}>
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.listContainer}>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
}
