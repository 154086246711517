import { Grid } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
// import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import KenFieldContent from '../KenFieldContent';
import * as yup from 'yup';
import { createYupSchema } from '../Validations/validationHelper';
import { FormContext } from '../../../../../../Context/formContext';
import { useFormContext, useWatch } from 'react-hook-form';
import { getSplitName } from '../../../../../../Utils/Helpers/stringHelper';

function KenSectionContent(props) {
  const { fields, onFormSubmit, formJSON } = props;
  const { formState, formDispatch } = useContext(FormContext);
  // const yepSchema = fields.reduce(createYupSchema, {});
  // const validateSchema = yup.object().shape(yepSchema);

  const [values, setValues] = useState();
  const methods = useFormContext();

  React.useEffect(() => {
    // console.log('methods?.watch running', values);
    const subscription = methods?.watch((value, { name, type }) => {
      setValues(value);
    });
    return () => subscription.unsubscribe();
  }, [methods?.watch]);

  useEffect(() => {
    // console.log(
    //   'Object.entries(formState?.formData)',
    //   Object.keys(formState?.formData)
    // );
    if (formState?.formData) {
      let obj = {};
      for (const key in formState?.formData) {
        if (formState?.formData.hasOwnProperty(key)) {
          //   console.log(`${key}: ${formState?.formData[key]}`);
          obj[key] = formState?.formData[key];
        }
      }
      //   console.log('allVal', obj);
      setValues(obj);
    }
  }, [formState]);

  //   const control = methods.control;
  //   const watchFieldsSemesterWiseMarks = useWatch({
  //     control,
  //     name: 'graduationMarks[semesteryearwiseMarks]',
  //   });
  //   React.useEffect(() => {
  //     console.log('watchFieldsSemesterWiseMarks', watchFieldsSemesterWiseMarks);
  //     setValues((prev, curr) => {
  //       return {
  //         ...prev,
  //         'graduationMarks[semesteryearwiseMarks]': watchFieldsSemesterWiseMarks,
  //       };
  //     });
  //   }, [watchFieldsSemesterWiseMarks]);

  // React.useEffect(() => {
  // 	console.log("values", values);
  // }, [values]);

  // console.log("formState in sections", formState);
  // const methods = useForm({
  // 	defaultValues: {
  // 		...formState.formData,
  // 	},
  // 	mode: "all",
  // 	resolver: yupResolver(validateSchema),
  // });

  // setSchema, schema = {}
  // const { data, formJSON, activeStep, currentStepDetails } = props;
  // console.log("sections in sections content", currentStepDetails?.sections);

  const getFieldValue = (field) => {
    // console.log("field in getFieldValue", field);
    if (!field) return '';
    try {
      //convert names to executable to get value
      const name = 'values[' + field?.name + ']';
      const keysArray = getSplitName(name, '', true) || [];
      // console.log("keysArray", keysArray);
      let str = '';
      keysArray.map((item, index) => {
        if (index === 0) {
          str = item;
        } else {
          str = `${str}['${item}'] && ${str}['${item}']`;
        }
      });
      str = 'values && ' + str;
      // console.log("str::::", str);
      return eval(str); //covert string to executable
    } catch (err) {
      console.log('error in eval', err);
    }
  };

  return (
    <>
      {/* <form
				id={`form-step${activeStep}`}
				onSubmit={methods.handleSubmit(onFormSubmit)}
				onBlur={methods.handleSubmit(onFormSubmit)}
			> */}
      <Grid container spacing={6} rowSpacing={4}>
        {/* <FormProvider {...methods}> */}
        {/* {fields?.map((field, index) => {
					return (
						<KenFieldContent
							{...field}
							{...props}
							lastItem={fields.length - 1 === index}
						/>
					);
				})} */}

        {fields && fields.length > 0 && (
          <>
            {fields.map((field, index) => {
              let conditionalField;
              let isConditionMatching;
              if (field.conditional) {
                try {
                  conditionalField = formJSON
                    ?.filter((s) => field.condition.stepId === s.stepId)[0]
                    ?.sections.filter(
                      (sec) => field.condition.sectionId === sec.sectionId
                    )[0]
                    ?.fields.filter(
                      (f) => field.condition.fieldId === f.fieldId
                    )[0];
                } catch (err) {
                  // alert(
                  // 	`The Step Id or Section Id provided in ${field.name} does not exists`
                  // );
                  console.log(
                    'err in searching conditional field',
                    field.condition.fieldId,
                    err
                  );
                  return true;
                }

                switch (field?.condition?.conditionType?.operation) {
                  case 'equals':
                    isConditionMatching =
                      getFieldValue(conditionalField) ===
                      field.condition.conditionType.value;
                    break;

                  case 'lessThan':
                    isConditionMatching =
                      getFieldValue(conditionalField) <
                      field.condition.conditionType.value;
                    break;
                  case 'greaterThan':
                    isConditionMatching =
                      getFieldValue(conditionalField) >
                      field.condition.conditionType.value;
                    break;
                  case 'lessThanEqual':
                    isConditionMatching =
                      getFieldValue(conditionalField) <=
                      field.condition.conditionType.value;
                    break;
                  case 'greaterThanEqual':
                    isConditionMatching =
                      getFieldValue(conditionalField) >=
                      field.condition.conditionType.value;
                    break;
                  case 'true':
                    isConditionMatching = true;
                    break;
                  default:
                }
              }
              // console.log("isConditionMatching field:::", isConditionMatching);
              return field?.condition?.behaviorType === 'DISPLAY_CONTROL' ? (
                isConditionMatching && field?.condition?.control?.display && (
                  <KenFieldContent
                    {...field}
                    {...props}
                    lastItem={fields.length - 1 === index}
                  />
                )
              ) : (
                <KenFieldContent
                  {...field}
                  {...props}
                  lastItem={fields.length - 1 === index}
                />
              );
            })}
          </>
        )}

        {/* </FormProvider> */}
        {/* <button type='submit'>Submit</button> */}
      </Grid>
      {/* </form> */}
    </>
  );
}
export default KenSectionContent;
