import { Box, Fade, Modal, Typography, useTheme, useMediaQuery, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import Clock from "../../../../Assets/Svg/clock.svg";
import KenButton from "../../../../Components/KenButton";
import KenInput from "../../../../Components/KenInput";
import CalendarComponent from "../../../../Components/KenCalendar";
import { useForm, Controller } from "react-hook-form";
import { TABLET_BREAKPOINT } from "../../../../Constants/constant";

const style = {
  position: "absolute",
  bottom: "0%",
  right: "0%",
  width: 400,
  bgcolor: "background.paper",
  border: 0,
  boxShadow: 24,
};

const useStyles = makeStyles((theme) => ({
  containerWrapper: {
    minHeight: 703,
    maxWidth: 360,
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'height': '92%',
      'width': '100%',
      'border-radius': '0',
      'max-width': '100%',
      'box-shadow': 'none',
      overflow: 'auto',
      top: "64px",
    }
  },
  containerOverflow: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      height: 'calc(100vh - 120px)',
      overflow: 'scroll',
    },
  },
  container: {
    padding: 16,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      minHeight: '500px'
    }
  },
  addTaskTitle: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "18px",
    "line-height": "100%",
    "text-transform": "capitalize",
    color: "#091E42",
  },
  description: {
    marginTop: 16,
    minHeight: 118,
  },
  footer: {
    width: "100%",
    "box-shadow": "inset 0px 1px 4px #F4F5F7",
    minHeight: 80,
    position: "absolute",
    bottom: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'bottom': '0',
      'left': '0',
      'overscroll-behavior': 'none',
      'position': 'fixed',
      'right': '0',
      'background': 'white',
    }
  },
  cancel: {
    "box-sizing": "border-box",
    filter:
      "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04))",
    "border-radius": "32px !important",

    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "100%",
    color: "#092682",
    marginRight: 16,
  },
  add: {
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
    padding: "9px 32px",
    background: "#092682",
    "box-shadow":
      "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
    "border-radius": "32px !important",
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "100%",
    color: "#FFFFFF",
    marginRight: 8,
  },
  calendar: {
    position: "absolute",
    top: "40px",
    left: "-5px",
    "z-index": "2",
    "min-width": "310px",
  },
  date: {
    width: "117px",
    height: "24px",
    left: "40px",
    top: "58px",
    background: "#DFE8FF",
    "border-radius": "4px",
    padding: 4,

    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "16px",
    "line-height": "100%",
    color: "#061938",
  },
  datepicker: {
    maxWidth: "70px",
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "16px",
    "line-height": "100%",
    color: "#42526E",
    border: 0,
    outline: 0,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'color': '#061938',
      background: '#F3F5F7',
    }
  },
  timePicker: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F3F5F7',
    borderRadius: '4px',
    width: '100%'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 16px 8px 16px',
    'font-size': '16px',
    'line-height': '100%',
    'text-transform': 'capitalize',
    'color': '#A8AFBC',
  },
  datePickerCalendarClass: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "center",

    "& .react-datepicker__time-container,.react-datepicker__time ": {
      width: "100%",
    },

    "& .react-datepicker__time-box": {
      margin: "0 !important",
      width: "100% !important",

      "& ul": {
        "&::-webkit-scrollbar": {
          width: 8,
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: 5,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#B8B8B8",
          borderRadius: 10,
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#D0D0D0",
        },
      },
    },

    "& .react-datepicker__header": {
      display: "none",
    },

    "& li.react-datepicker__time-list-item": {
      "font-family": "Poppins",
      "font-style": "normal",
      "font-weight": "400",
      "font-size": "14px",
      "line-height": "100%",
      display: "flex",
      "align-items": "center",
      justifyContent: "flex-end",
      color: "#091E42 !important",
    },

    "& li.react-datepicker__time-list-item:hover, li.react-datepicker__time-list-item--selected":
    {
      background: "#DFE8FF !important",
    },
    minWidth: 100,
  },
  datePickerWrapper: {
    color: "blue",
  },
}));

export default function AddTask({ saving, onAdd, onCancel, showAddTask }) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(
    moment(new Date()).add(30, "minutes").toDate()
  );
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(
    TABLET_BREAKPOINT
  ));

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      description: "",
      title: "",
      date: startDate,
      start: startTime,
      end: endTime,
    },
  });

  const handleChange = (date) => {
    setIsOpen(!isOpen);
    setStartDate(date);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Modal open={showAddTask} hideBackdrop={isMobileScreen}>
      <Fade in={showAddTask}>
        <Box
          sx={style}
          display="flex"
          flexDirection="column"
          className={classes.containerWrapper}
        >
          <form onSubmit={handleSubmit(onAdd)}>
            <Box
              className={classes.containerOverflow}
            >
              <Box className={classes.container}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.addTaskTitle}
                >
                  Add Task
                </Typography>
                <Box
                  margin={isMobileScreen ? '32px auto 8px auto' : '32px auto'}
                  display="flex"
                  flexDirection={isMobileScreen ? 'column' : 'row'}
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                    width={isMobileScreen ? '100%' : undefined}
                    style={{ background: isMobileScreen ? '#DFE8FF' : undefined, padding: isMobileScreen ? 8 : undefined }}
                  >
                    <img src={Clock} alt="Clock"></img>
                    <Box ml="10px" position={"relative"} maxHeight={isMobileScreen ? '24px' : undefined}>
                      <Box
                        component="span"
                        className={classes.date}
                        onClick={handleClick}
                        display={isMobileScreen ? 'inline-block' : undefined}
                        width={isMobileScreen ? '100% !important' : undefined}

                      >
                        {moment(startDate).format(isMobileScreen ? 'dddd, MMMM DD' : "ddd, MMM DD")}
                      </Box>
                      {isOpen && (
                        <Controller
                          name="date"
                          control={control}
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <CalendarComponent
                              calendarWrapperClassName={classes.calendar}
                              error={error}
                              errorLabel={true}
                              {...field}
                              onChange={(date) => {
                                setValue("date", date);
                                setValue("start", date);
                                setValue("end", date);
                                setStartTime(date);
                                setEndTime(date);
                                handleChange(date);
                              }}
                            />
                          )}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    width={isMobileScreen ? '100%' : undefined}
                    style={{ background: isMobileScreen ? '#F3F5F7' : undefined, marginTop: isMobileScreen ? 16 : undefined }}
                    alignItems="center"
                  >
                    {isMobileScreen ?
                      <Box className={classes.timePicker}>
                        <Box className={classes.item}>
                          <Box>Starts</Box>
                          <Box>Ends</Box>
                        </Box>
                        <Divider style={{
                          width: '90%',
                          margin: '8px auto 0 auto'
                        }} />
                        <Box className={classes.item}>
                          <Box>
                            <Controller
                              name="start"
                              control={control}
                              rules={{ required: true }}
                              render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                  error={error}
                                  errorLabel={true}
                                  {...field}
                                  className={classes.datepicker}
                                  calendarClassName={classes.datePickerCalendarClass}
                                  wrapperClassName={classes.datePickerWrapper}
                                  selected={startTime}
                                  onChange={(date) => {
                                    setValue("start", date);
                                    setStartTime(date);
                                  }}
                                  showTimeSelect={true}
                                  showTimeSelectOnly={true}
                                  timeIntervals={15}
                                  dateFormat="h:mm aa"
                                  clearIcon={null}
                                />
                              )}
                            />
                          </Box>
                          <Box>
                            <Controller
                              name="end"
                              control={control}
                              rules={{ required: true }}
                              render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                  error={error}
                                  errorLabel={true}
                                  {...field}
                                  className={classes.datepicker}
                                  calendarClassName={classes.datePickerCalendarClass}
                                  wrapperClassName={classes.datePickerWrapper}
                                  selected={endTime}
                                  onChange={(date) => {
                                    setValue("end", date);
                                    setEndTime(date);
                                  }}
                                  showTimeSelect={true}
                                  showTimeSelectOnly={true}
                                  timeIntervals={15}
                                  dateFormat="h:mm aa"
                                  clearIcon={null}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </Box>
                      : <>
                        <Controller
                          name="start"
                          control={control}
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <DatePicker
                              error={error}
                              errorLabel={true}
                              {...field}
                              className={classes.datepicker}
                              calendarClassName={classes.datePickerCalendarClass}
                              wrapperClassName={classes.datePickerWrapper}
                              selected={startTime}
                              onChange={(date) => {
                                setValue("start", date);
                                setStartTime(date);
                              }}
                              showTimeSelect={true}
                              showTimeSelectOnly={true}
                              timeIntervals={15}
                              dateFormat="h:mm aa"
                              clearIcon={null}
                            />
                          )}
                        />
                        <Box component="span" mx="2px">
                          -
                        </Box>
                        <Controller
                          name="end"
                          control={control}
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <DatePicker
                              error={error}
                              errorLabel={true}
                              {...field}
                              className={classes.datepicker}
                              calendarClassName={classes.datePickerCalendarClass}
                              wrapperClassName={classes.datePickerWrapper}
                              selected={endTime}
                              onChange={(date) => {
                                setValue("end", date);
                                setEndTime(date);
                              }}
                              showTimeSelect={true}
                              showTimeSelectOnly={true}
                              timeIntervals={15}
                              dateFormat="h:mm aa"
                              clearIcon={null}
                            />
                          )}
                        />
                      </>}
                  </Box>
                </Box>

                <Controller
                  name="title"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <KenInput
                      name="title"
                      error={error}
                      errorLabel={true}
                      {...field}
                      placeholder="Add Title"
                    />
                  )}
                />

                <Controller
                  name="description"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <KenInput
                      name="description"
                      multiline
                      rows={5}
                      className={classes.description}
                      placeholder="Task Description (Optional)"
                      labelClassName={classes.inputFieldLabel}
                      errorLabel={true}
                      error={error}
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box className={classes.footer}>
              <KenButton
                buttonClass={classes.cancel}
                onClick={onCancel}
                disabled={saving}
              >
                Cancel
              </KenButton>
              <KenButton
                variant="primary"
                buttonClass={classes.add}
                type="submit"
                disabled={saving}
              >
                Add
              </KenButton>
            </Box>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
}
