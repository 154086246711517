// get current step sections

import _ from "lodash";
import { defaultJson } from "./defaultTemplate";

// get current step sections data in the payload format


// Filters only steps and sections to be displayed 
export const getVisibleSteps = (metaData) => {

    // const steps = JSON.parse(metaData) || defaultJson;
    const steps = defaultJson;

    return steps.filter(
        (step) => step.stepVisible
    ).map(step => {
        step.sections = step.sections.filter(section => section.sectionVisible);
        return step;
    });
}


export const getCurrentStepPayload = function (formValues, applicationData, steps, activeStep) {

    // const sectionIds = getUniqueSections(steps[activeStep]);


    //get sections data from from form
    // console.log(sectionIds);

    // overide applicationData with form to have respective ids of objects
    // get sections data from formvalues and applicationData
    // Object(formValues).keys.forEach(id => {
    //     result[id] = { ...applicationData[id], ...formValues[id] }
    // })
    // if any array property override 
    // const arrayObj = _(formValues)
    //     .pickBy(_.isArray)
    //     .mapValues(o => {
    //         return o
    //     }).value()

    // console.log('45>>:', arrayObj);

    const getUpdatedValue = function (key, formValue, allData) {

        return { ...allData[key], ...formValue }
    }
    const result = {};
    _.keys(formValues).forEach(key => {
        if (_.isArray()) {
            result[key] = formValues[key].map(el => getUpdatedValue(key, el, applicationData))
        } else {
            result[key] = getUpdatedValue(key, formValues[key], applicationData);
        }
    })

    console.log(result);

    return result;

    // return _.merge(_.pick(applicationData, _.keys(formValues)), formValues);


}


export function getDirtyValues(dirtyFields, allValues) {

    return _.pick(allValues, _.keys(dirtyFields));

}

// Map RHF's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
export function dirtyValues(dirtyFields, allValues) {
    // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
    // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields))
        return allValues;
    // Here, we have an object
    return Object.fromEntries(Object.keys(dirtyFields).map(key => [key, dirtyValues(dirtyFields[key], allValues[key])]));
}



function removeFalseKeys(obj) {
    const t = _(obj).pickBy(_.isBoolean).omitBy(o => {
        return !o
    }
    ).value()

    const t1 = _(obj).omitBy(_.isBoolean).value();
    return { ...t, ...t1 }
}

function removeEmptyObjects(obj) {

    const oo = removeFalseKeys(obj);

    return _(oo)
        .pickBy(_.isObject)
        // .omitBy(_.isArray)
        .mapValues(removeEmptyObjects)
        .omitBy(_.isEmpty)
        .omitBy((o) => {
            const isValid =
                Object.keys(o).filter((key) => {
                    return o[key];
                }).length === 0;

            return isValid;
        })
        .assign(_.omitBy(oo, _.isObject))
        .value();
}


export function addExtrasFieldsToObject(obj, extraFields) {
    const objectVals = addObjectExtras(obj, extraFields);
    // const arrayVals = addArrayExtras(obj, extraFields);
    // console.log(arrayVals);

    return objectVals;
}

function addObjectExtras(obj, extraFields) {
    return _(obj)
        .pickBy(_.isObject)
        .mapValues((e) => {
            console.log("==>: ", e, _.isArray(e), _.isObject(e));
            if (_.isArray(e)) {
                return _.map(e, (a) => addObjectExtras(_.extend({}, a, extraFields), extraFields));
            }
            if (_.isObject(e)) {

                return addObjectExtras(_.extend({}, e, extraFields), extraFields);
            }
            return _.extend({}, e, extraFields);
        })
        .assign(_.omitBy(obj, _.isObject))
        .value();


}

function addArrayExtras(obj, extraFields) {
    console.log("Array obj: ", obj);

    return _(obj)
        .pickBy(_.isArray)
        .mapValues((e) => {
            if (_.isObject(e)) {
                return addObjectExtras(e, extraFields);
            }
            return addArrayExtras(e, extraFields);
        })
        .mapValues(_.values)
        .assign(_.omitBy(obj, _.isArray))
        .value();
}


export function removeArrayItem(obj, name, index) {
    _.unset(obj, `${name}[${index}]`);
    _.set(obj, name, _.get(obj, name)?.filter(e => e));
    return obj;
}


export function filterDirtyFields(dirtyFields) {

    const onlyObjs = removeEmptyObjects(dirtyFields);
    // const arraysRemoved = removeEmptyArrays(dirtyFields);
    // console.log("Onlyobjs: ", arraysRemoved);


    return { ...onlyObjs };


}

export function applicationAddCurrentStep(payloadApplication, { application = {} }, activeStep) {

    let stepVal = null;
    console.log();
    
    if ((parseInt(application.currentStep || 0) <= activeStep)||(0=== activeStep)) {
        stepVal = { currentStep: activeStep + 1 }
    }
    return stepVal ? { application: { ...(payloadApplication || application), ...stepVal } } : {};
}





