import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import FeeRadioGroup from './feeRadioGroup';
import { TABLET_BREAKPOINT } from '../../../../../../../Constants/constant';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      border: '1px solid #DDDDDD',
      padding: theme.spacing(2),
      'border-radius': '12px',
    },
  },

  label: {
    fontSize: '14px',
    color: '#061938',
    fontWeight: 400,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '16px',
      'line-height': '100%',
      color: '#061938',
    },
  },
}));

export default function PaymentMode(props) {
  const [paymentOptions, setPaymentOptions] = useState();
  const {
    value,
    handleOnChange,
    success,
    error,
    onPartialAmountChange,
    partialAmount,
    partialAmountFieldError,
    dueFee,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (dueFee?.unplannedPaymentId !== null) {
      setPaymentOptions([
        {
          label: 'Partial Payment',
          value: 'Partial Payment',
          subTitle: 'Pay Partial',
        },
      ]);
    } else {
      setPaymentOptions([
        {
          label: 'Full Payment',
          value: 'Full Payment',
          subTitle: 'Pay Full Amount at once',
        },
        {
          label: 'Partial Payment',
          value: 'Partial Payment',
          subTitle: 'Pay Partial',
        },
      ]);
    }
  }, []);

  return (
    <Box className={classes.container}>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} textAlign="left">
            {/* <KenRadioGroup */}
            <FeeRadioGroup
              row={false}
              label="Select your payment mode"
              value={value}
              options={paymentOptions}
              condition="Partial Payment"
              onChange={handleOnChange}
              onPartialAmountChange={onPartialAmountChange}
              partialAmount={partialAmount}
              success={success}
              error={error}
              partialAmountFieldError={partialAmountFieldError}
              labelClassName={classes.label}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
