import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  title: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "100%",
    color: theme.palette.KenColors.neutral900
  },

  subTitle: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.KenColors.neutral400
  },
  link: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "100%",
    color: theme.palette.KenColors.gradeSectionHeaderLabel,
    cursor: "pointer"
  },
  actionLink: {
    fontWeight: "600",
    cursor: "pointer",
    marginLeft: "5px",
    color: theme.palette.KenColors.gradeSectionHeaderLabel
  }
}));

export function Title({ text, className }) {
  const classes = useStyles();

  return (
    <Typography className={`${classes.title} ${className ? className : ""}`}>
      {text}
    </Typography>
  );
}

export function SubTitle({ text, className }) {
  const classes = useStyles();

  return (
    <Typography className={`${classes.subTitle} ${className ? className : ""}`}>
      {text}
    </Typography>
  );
}

export function AccountLink({ text, actionText, onAction }) {
  const classes = useStyles();

  return (
    <Box display={"flex"} alignItems="center">
      <Typography className={classes.link}>{text}</Typography>
      <Typography className={classes.actionLink} onClick={onAction}>
        {actionText}
      </Typography>
    </Box>
  );
}
