import React, { useReducer } from "react";

export const initialState = {
	formData: {},
};

export const reducer = (state, action) => {
	switch (action.type) {
		case "update":
			return {
				formData: { ...state.formData, ...action.payload },
			};

		case "new":
			return {
				formData: { ...action.payload },
			};

		case "reset":
			return {
				formData: {},
			};
		default:
			return state;
	}
};

export const FormContext = React.createContext();

export const FormContextProvider = (props) => {
	const [formState, formDispatch] = useReducer(reducer, initialState);

	return (
		<FormContext.Provider
			value={{
				formState,
				formDispatch,
			}}
		>
			{props.children}
		</FormContext.Provider>
	);
};
