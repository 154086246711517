import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { TABLET_BREAKPOINT } from '../../../../../../Constants/constant';
import CardContent from './Components/cardContent';
import CardHeader from './Components/cardHeader';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '2px solid #ECECEC',
    borderRadius: '12px',
    padding: 0,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      border: 0,
      borderRadius: 0,
    },
  },
}));

export default function DiscountCard(props) {
  const {
    handleChangePaymentMode,
    getErrorInAmount,
    getDiscountAmount,
    getPartialAmount,
    basicFee,
  } = props;
  const classes = useStyles();
  return (
    <Box pt={4} className={classes.container}>
      <CardHeader {...props} />
      <CardContent
        handleChangePaymentMode={handleChangePaymentMode}
        getDiscountAmount={getDiscountAmount}
        getPartialAmount={getPartialAmount}
        basicFee={basicFee}
        getErrorInAmount={getErrorInAmount}
        {...props}
      />
    </Box>
  );
}
