import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import {
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import KenSectionContent from './Components/KenSectionContent';
import KenStepperFooter from '../KenStepperFooter';
import KenPaymentStep from '../../../KenPaymentStep';
import KenLoader from '../../../KenLoader';
import { createUpdateApplication } from '../../../../Utils/apiServices';
import { FormContext } from '../../../../Context/formContext';
import KenSummaryStep from '../../../KenSummaryPage';
import { getNameById } from '../../../../Utils/Helpers/stringHelper';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';
import { Country, State, City } from 'country-state-city';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: 'calc(100% - 16px)',
    paddingTop: theme.spacing(7.125),
    overflow: 'auto',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingTop: theme.spacing(3),
    }
  },
  sectionLabel: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  sectionSubLabel: {
    color: '#7A869A',
    fontWeight: 500,
  },
  footerContainer: {
    position: 'absolute',
    bottom: '0px',
    padding: '16px',
    paddingRight: '223px',
    // height: "100px",
    left: '0px',
    right: '0px',
    overflow: 'hidden',
    background: '#FFFFFF',
    boxShadow:
      '10px 0px 20px rgba(0, 0, 0, 0.04), 2px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    // backgroundColor: "blue",
    minHeight: 76,
  },
  container: {
    position: 'absolute',
    top: '270px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  paymentContainer: {
    padding: '16px 223px',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '0px',
      margin: '16px',
      paddingBottom: '60px',
    },
  },
  summaryForm: {
    paddingTop: theme.spacing(7.125),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingTop: theme.spacing(3),
      margin: '16px',
      marginBottom: '60px',
    },
  },
  accordionDetail: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: 0,
    },
  },
  required: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '12px',
    'line-height': '100%',
    'color': '#393939',

    '& > .required': {
      color: '#FC2C32',
    }
  }
}));

function KenStepperContent(props) {
  const {
    currentStepDetails,
    completedSteps,
    handleComplete,
    handleBack,
    handleSave,
    saveFlag,
    handleNext,
    activeStep,
    formJSON,
    completed,
    allStepsCompleted,
    handleStepsSubmit,
    totalSteps,
    setCsc,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  // console.log("activeStep", activeStep);
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  // const { data, formJSON, activeStep, currentStepDetails } = props;
  const { formState, formDispatch } = useContext(FormContext);
  const [sections, setSections] = useState(currentStepDetails?.sections);
  const [values, setValues] = useState();
  const [formValues, setFormValues] = useState();
  const [appId, setAppId] = useState();
  const [blurCounter, setBlurCounter] = useState(0);
  const [schema, setSchema] = useState({});
  const [formValid, setFormValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);

  const { enqueueSnackbar } = useSnackbar();

  //payment state & methods -------------------------------------------
  const [feeAfterVoucher, setFeeAfterVoucher] = useState();
  const [fee, setFee] = useState('1000.00');
  const [finalFee, setFinalFee] = useState('1000.00');
  const handleApplyVoucher = () => {
    const discount = '250.00';
    const total = parseInt(fee) - parseInt(discount);
    setFeeAfterVoucher(total);
    setFinalFee(total);
  };
  const handleRemoveVoucher = () => {
    setFeeAfterVoucher(null);
    setFinalFee(fee);
  };
  const handlePayNow = () => {
    console.log('pay now');
  };

  //-------------------------------------------------------------------

  //form state
  // const { formState, formDispatch } = useContext(FormContext);
  const fieldsInStep = sections?.map((item) => item.fields);
  // const singeArrayOfFields = fieldsInStep?.reduce(
  //   (previousValue, currentValue) => previousValue?.concat(currentValue),
  //   []
  // );
  // // console.log("singeArrayOfFields", singeArrayOfFields);
  // const yepSchema = singeArrayOfFields?.reduce(createYupSchema, {});
  // const validateSchema = yup.object().shape(yepSchema);
  // console.log("formState in sections", formState);
  const methods = useForm({
    defaultValues: {
      ...formState.formData,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    // resolver: yupResolver(validateSchema),
  });

  const control = methods.control;

  const watchFields = useWatch({
    control,
    name: [
      'addresses[0][mailingCountry]',
      'addresses[0][mailingState]',
      'addresses[0][mailingDistrict]',
    ],
  });

  const watchFieldsGradeType12 = useWatch({
    control,
    name: 'ssc[degreeCGPADGPA]',
  });

  const watchFieldsGradeType10 = useWatch({
    control,
    name: 'hsc[degreeCGPADGPA]',
  });

  const watchFieldsGradeType10Country = useWatch({
    control,
    name: 'ssc[country]',
  });

  const watchFieldsGradeType10State = useWatch({
    control,
    name: 'ssc[state]',
  });

  const watchFieldsGradeType12Country = useWatch({
    control,
    name: 'hsc[country]',
  });

  const watchFieldsGradeType12State = useWatch({
    control,
    name: 'hsc[state]',
  });

  const getStates = (key, country) => {
    const copyFields = [...sections];
    const selectedCountryCode = Country.getAllCountries().find(
      (el) => el.name === country
    )?.isoCode;
    const states = [
      ...State.getStatesOfCountry(selectedCountryCode).map((el) => {
        return { label: el?.name, value: el?.name };
      }),
    ];
    const state = copyFields
      ?.find((el) => el.sectionId === key)
      .fields.find((el) => el.label === 'State');
    state.options = [...states];
    // console.log(state);
    return [...copyFields];
  };

  const getCities = (key, country, state) => {
    const copyFields = [...sections];
    const selectedCountryCode = Country.getAllCountries().find(
      (el) => el.name === country
    )?.isoCode;
    const selectedStateCode = State.getAllStates().find(
      (el) => el.name === state
    )?.isoCode;
    const cities = [
      ...City.getCitiesOfState(selectedCountryCode, selectedStateCode).map(
        (el) => {
          return { label: el?.name, value: el?.name };
        }
      ),
    ];
    const city = copyFields
      ?.find((el) => el.sectionId === key)
      .fields.find((el) => el.label === 'City');
    city.options = [...cities];
    // console.log(city);
    return [...copyFields];
  };

  useEffect(() => {
    if (watchFieldsGradeType12Country !== undefined && sections) {
      const copyFields = getStates('hsc', watchFieldsGradeType12Country);
      setSections([...copyFields]);
    }
  }, [watchFieldsGradeType12Country]);

  useEffect(() => {
    if (watchFieldsGradeType12State !== undefined && sections) {
      const copyFields = getCities(
        'hsc',
        watchFieldsGradeType12Country,
        watchFieldsGradeType12State
      );
      setSections([...copyFields]);
    }
  }, [watchFieldsGradeType12State]);

  useEffect(() => {
    if (watchFieldsGradeType10Country !== undefined && sections) {
      const copyFields = getStates('ssc', watchFieldsGradeType10Country);
      setSections([...copyFields]);
    }
  }, [watchFieldsGradeType10Country]);

  useEffect(() => {
    if (watchFieldsGradeType10State !== undefined && sections) {
      const copyFields = getCities(
        'ssc',
        watchFieldsGradeType10Country,
        watchFieldsGradeType10State
      );
      setSections([...copyFields]);
    }
  }, [watchFieldsGradeType10State]);

  // const getData = (watchingField, key) => {
  //   const copyFields = [...sections];
  //   const section = copyFields?.find((el) => el.sectionId === key);
  //   const field = section?.fields?.find((el) => el.label === 'Final Grade');
  //   console.log(section, field);
  //   // const data = section.find((elem) => {
  //   //   elem.label === 'Final Grade';
  //   // });
  //   // console.log(data);
  //   if (field?.validations) {
  //     field.validations = {
  //       required: 'This field is required',
  //       validate: () => {
  //         if (watchingField === 'GPA') return 'This is the error';
  //       },
  //     };
  //   }
  //   console.log(copyFields);
  //   setSections([...copyFields]);
  // };

  // useEffect(() => {
  //   if (watchFieldsGradeType10 !== null && sections) {
  //     getData(watchFieldsGradeType10, 'ssc');
  //   }
  // }, [watchFieldsGradeType10]);

  useEffect(() => {
    setCsc(watchFields);
  }, [watchFields]);

  useEffect(() => {
    setSections(currentStepDetails?.sections);
  }, [currentStepDetails]);

  // const getSpecializationName = (id) => {
  // 	const specArray =
  // 		formState?.formData?.programDetails?.specializations || [];
  // 	const obj = specArray?.find((item) => item?.recordId === id);
  // 	if (!obj) return "";
  // 	return obj?.specializationName;
  // };

  // const getCampu sName = (id) => {
  // 	const campusArray = formState?.formData?.programDetails?.campuses || [];
  // 	const obj = campusArray?.find((item) => item?.recordId === id);
  // 	if (!obj) return "";
  // 	return obj?.campusName;
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    // console.log("saveFlag changed", saveFlag);
    if (saveFlag !== null) {
      methods.handleSubmit(onSectionSubmit)();
    }
  }, [saveFlag]);

  useEffect(() => {
    if (appId) {
      const applicationId = appId;
      if (!query.has('applicationId')) {
        navigate({
          pathname: '/form',
          search:
            location?.search +
            '&' +
            new URLSearchParams({
              applicationId: applicationId,
              new: true,
            }).toString(),
        });
      }
    }
  }, [appId]);

  const onError = () => {
    return enqueueSnackbar('Please fill in the mandatory fields ', {
      variant: 'warning',
    });
  };

  const onSectionSubmit = (formValues) => {
    // console.log('onSectionSubmit called', formValues);
    if (!formValues) return;
    if (blurCounter === 0 || query.get('applicationId')) {
      //condition to prevent from  creating multiple applications
      const data = { ...formValues };
      const user = JSON.parse(localStorage.getItem('user'));
      data.applicationId = query.get('applicationId');
      data.application = {
        ...data.application,
        recordId: query.get('applicationId'),
        applicantId: formState?.formData?.applicantId,
        programId: formState?.formData?.programDetails?.programId,
        applicationForm: query.get('formId'),
        currentStep: activeStep,
      };
      // if (data.workExperiences) {
      // 	data.workExperiences[0] = {
      // 		...data.workExperiences[0],
      // 		applicantId: formState?.formData?.applicantId,
      // 		applicationId: query.get("applicationId"),
      // 	};
      // 	data.workExperiences[1] = {
      // 		...data?.workExperiences[1],
      // 		applicantId: formState?.formData?.applicantId,
      // 		applicationId: query.get("applicationId"),
      // 	};
      // }
      if (data.workExperiences) {
        const updatedData = data?.workExperiences?.map((item) => {
          return {
            ...item,
            applicationId: query.get('applicationId'),
            applicantId: user?.applicantId || formState?.formData?.applicantId,
          };
        });

        data.workExperiences = updatedData;
      }

      data.applicant = {
        ...data.applicant,
        recordId: user?.applicantId,
      };
      if (data.applicantRelations) {
        const updatedData = data?.applicantRelations?.map((item) => {
          return {
            ...item,
            applicationId: query.get('applicationId'),
            applicantId: user?.applicantId || formState?.formData?.applicantId,
          };
        });

        data.applicantRelations = updatedData;
      }
      if (data.addresses) {
        data.addresses[0] = {
          ...data.addresses[0],
          addressType: 'Home',
          parentId: user?.applicantId || formState?.formData?.applicantId,
          isPermanentAddressSame: true,
        };
        data.addresses[1] = {
          ...data.addresses[0],
          ...data.addresses[1],
          parentId: user?.applicantId || formState?.formData?.applicantId,
          addressType: 'Work',
          isPermanentAddressSame: false,
        };
      }
      formDispatch({
        type: 'update',
        payload: {
          ...data,
          application: {
            ...formState.formData.application,
            ...data.application,
            specializationId_select: getNameById(
              data?.application?.specializationId,
              formState?.formData?.programDetails?.specializations || [],
              'recordId',
              'specializationName'
            ), //need same in summary page
            campusId_select: getNameById(
              data?.application?.campusId,
              formState?.formData?.programDetails?.campuses || [],
              'recordId',
              'campusName'
            ),
            campusName: getNameById(
              data?.application?.campusId,
              formState?.formData?.programDetails?.campuses || [],
              'recordId',
              'campusName'
            ),
            specializationName: getNameById(
              data?.application?.specializationId,
              formState?.formData?.programDetails?.specializations || [],
              'recordId',
              'specializationName'
            ),
          },
        },
      });

      if (formValid) {
        const dataOnlyEdu = [];
        let postGrad = data['postGraduation']
          ? {
            ...data['postGraduation'],
            educationHistoryType: 'Post Graduation',
          }
          : {};
        let underGrad = data['graduation']
          ? { ...data['graduation'], educationHistoryType: 'Under Graduation' }
          : {};
        let hsc = data['hsc']
          ? { ...data['hsc'], educationHistoryType: '12th' }
          : {};
        let ssc = data['ssc']
          ? { ...data['ssc'], educationHistoryType: '10th' }
          : {};
        const gradMarks = data['graduationMarks'];
        if (gradMarks?.semesteryearwiseMarks === 'Semester') {
          let obj = {};
          gradMarks?.semesterwiseMarks?.map((el) => {
            obj[el.semesterNumber] = el['semesterMarks'];
          });
          underGrad = { ...underGrad, ...obj, semesterwiseMarks: true };
        } else if (gradMarks?.semesteryearwiseMarks === 'Year') {
          let obj = {};
          gradMarks?.yearwiseMarks?.map((el) => {
            obj[el.yearNumber] = el['yearMarks'];
          });
          underGrad = { ...underGrad, ...obj, semesterwiseMarks: false };
        }
        const eduHistory = [];
        if (
          postGrad &&
          Object.keys(postGrad).length !== 0 &&
          Object.getPrototypeOf(postGrad) === Object.prototype
        ) {
          eduHistory.push(postGrad);
        }
        if (
          underGrad &&
          Object.keys(underGrad).length !== 0 &&
          Object.getPrototypeOf(underGrad) === Object.prototype
        ) {
          eduHistory.push(underGrad);
        }
        if (
          hsc &&
          Object.keys(hsc).length !== 0 &&
          Object.getPrototypeOf(hsc) === Object.prototype
        ) {
          eduHistory.push(hsc);
        }
        if (
          ssc &&
          Object.keys(ssc).length !== 0 &&
          Object.getPrototypeOf(ssc) === Object.prototype
        ) {
          eduHistory.push(ssc);
        }
        if (eduHistory?.length > 0) {
          data['educationHistories'] = [...eduHistory];
        }

        data['educationHistories']?.forEach((item) => {
          if (item !== null) {
            dataOnlyEdu.push({
              ...item,
              applicationId: query.get('applicationId'),
              applicantId:
                user?.applicantId || formState?.formData?.applicantId,
            });
          }
        });

        const dataOnlyApplicant = data['applicant'];
        const dataOnlyApplication = data['application'];

        const dataOnlyWorkExperience = [];
        data['workExperiences']?.forEach((el) => {
          //   if (el?.doYouHaveAnyWorkExperience === 'Yes') {
          dataOnlyWorkExperience.push(el);
          //   }
        });

        const dataOnlyLanguages = [];
        data['languages']?.length > 0 &&
          data['languages']?.forEach((item) => {
            if (item !== null) {
              dataOnlyLanguages.push({
                ...item,
                applicationId: query.get('applicationId'),
              });
            }
          });
        // console.log(dataOnlyWorkExperience);
        const dataOnlyApplicantRelations = data['applicantRelations']?.map(
          (item) => {
            return {
              ...item,
              applicationId: query.get('applicationId'),
              applicantId:
                user?.applicantId || formState?.formData?.applicantId,
            };
          }
        );
        const dataOnlyAddresses = data['addresses']?.map((item) => {
          return {
            ...item,
            applicationId: query.get('applicationId'),
            parentId: user?.applicantId || formState?.formData?.applicantId,
          };
        });

        const dataOnlyEntrance = data['entranceExams']?.map((item) => {
          return {
            ...item,
            applicationId: query.get('applicationId'),
            applicantId: user?.applicantId || formState?.formData?.applicantId,
          };
        });
        const updatedDataOnlyEntrance = {
          entranceExams: dataOnlyEntrance,
          applicationId: query.get('applicationId'),
        };
        const updatedDataOnlyEdu = {
          educationHistories: dataOnlyEdu,
          applicationId: query.get('applicationId'),
        };
        const updatedDataOnlyAddresses = {
          addresses: dataOnlyAddresses,
          applicationId: query.get('applicationId'),
        };
        const updatedDataOnlyApplicant = {
          applicant: dataOnlyApplicant,
          applicationId: query.get('applicationId'),
        };
        const updatedDataOnlyApplication = {
          application: dataOnlyApplication,
          applicationId: query.get('applicationId'),
        };
        const updatedDataOnlyApplicantRelations = {
          applicantRelations: dataOnlyApplicantRelations,
          applicationId: query.get('applicationId'),
        };
        const updatedDataOnlyWorkExperience = {
          workExperiences: dataOnlyWorkExperience,
          applicationId: query.get('applicationId'),
        };
        const updatedDataOnlyLanguages = {
          languages: dataOnlyLanguages,
          applicationId: query.get('applicationId'),
        };
        // delete data["educationHistories"];
        if (activeStep === 1) {
          updatedDataOnlyApplicantRelations &&
            updatedDataOnlyApplicantRelations.applicantRelations?.length > 0 &&
            saveApplicationData(updatedDataOnlyApplicantRelations);
          updatedDataOnlyWorkExperience &&
            updatedDataOnlyWorkExperience.workExperiences?.length > 0 &&
            saveApplicationData(updatedDataOnlyWorkExperience);
        }
        updatedDataOnlyEdu &&
          updatedDataOnlyEdu.educationHistories?.length > 0 &&
          saveApplicationData(updatedDataOnlyEdu);
        updatedDataOnlyAddresses &&
          updatedDataOnlyAddresses.addresses?.length > 0 &&
          saveApplicationData(updatedDataOnlyAddresses);
        updatedDataOnlyApplication &&
          Object.keys(updatedDataOnlyApplication.application).length > 0 &&
          saveApplicationData(updatedDataOnlyApplication);

        updatedDataOnlyApplicant &&
          Object.keys(updatedDataOnlyApplicant.applicant).length > 0 &&
          saveApplicationData(updatedDataOnlyApplicant);
        updatedDataOnlyLanguages &&
          updatedDataOnlyLanguages?.languages?.length > 0 &&
          saveApplicationData(updatedDataOnlyLanguages);
        updatedDataOnlyEntrance &&
          updatedDataOnlyEntrance?.entranceExams?.length > 0 &&
          saveApplicationData(updatedDataOnlyEntrance);

        // 		createUpdateApplication(updatedDataOnlyEdu)
        // 			.then((res) => {
        // 				setLoading(false);
        // 				// console.log(
        // 				// 	"res of create/update",
        // 				// 	data?.application?.specializationId,
        // 				// 	formState?.formData?.programDetails?.specializations
        // 				// );
        // 				formDispatch({
        // 					type: "update",
        // 					payload: {
        // 						...data,
        // 						...res.data,
        // 						application: {
        // 							...formState?.formData?.application,
        // 							...data?.application,
        // 							...res?.data?.application,
        // 							specializationId_select: getNameById(
        // 								data?.application?.specializationId,
        // 								formState?.formData?.programDetails?.specializations || [],
        // 								"recordId",
        // 								"specializationName"
        // 							), //need same in summary page
        // 							campusId_select: getNameById(
        // 								data?.application?.campusId,
        // 								formState?.formData?.programDetails?.campuses || [],
        // 								"recordId",
        // 								"campusName"
        // 							),
        // 							campusName: getNameById(
        // 								data?.application?.campusId,
        // 								formState?.formData?.programDetails?.campuses || [],
        // 								"recordId",
        // 								"campusName"
        // 							),
        // 							specializationName: getNameById(
        // 								data?.application?.specializationId,
        // 								formState?.formData?.programDetails?.specializations || [],
        // 								"recordId",
        // 								"specializationName"
        // 							),
        // 							recordId: res?.applicationId,
        // 						},
        // 					},
        // 				});
        // 				if (!query.has("applicationId")) {
        // 					navigate({
        // 						pathname: "/form",
        // 						search:
        // 							location?.search +
        // 							"&" +
        // 							new URLSearchParams({
        // 								applicationId: res?.applicationId,
        // 								new: true,
        // 							}).toString(),
        // 					});
        // 				}
        // 			})
        // 			.catch((err) => {
        // 				setLoading(false);
        // 				console.log("error while creating/updating application", err);
        // 			});
      } else {
        console.log('can not submit', formValues);
      }
    }

    setBlurCounter(blurCounter + 1);
    setValues(formValues);
  };

  const isFormValid = (isValid) => {
    setFormValid(isValid);
  };

  const onFormBlur = (allValues) => {
    onSectionSubmit(allValues);
  };
  const handleChangeStatus = (allFormValues) => {
    // console.log("handleChangeStatus called");
    const data = allFormValues;
    data['application']['applicationStatus'] = 'Application form Submitted';
    onSectionSubmit(data);
    handleStepsSubmit();
  };
  const handleContinue = (allFormValues) => {
    onSectionSubmit(allFormValues);
    handleNext();
  };

  const onFormSubmit = (allFormValues) => {
    // console.log("onFormSubmit called");
    // console.log("activeStep", activeStep, "------", totalSteps() - 1);
    activeStep === totalSteps() - 1
      ? handleChangeStatus(allFormValues)
      : handleContinue(allFormValues);
  };

  const saveApplicationData = (data) => {
    setLoading(true);
    createUpdateApplication(data)
      .then((res) => {
        console.log('res.appid', res?.applicationId);
        if (res?.applicationId) {
          console.log('inside if', res?.applicationId);
          setAppId(res.applicationId);
        }
        // console.log(
        // 	"res of create/update",
        // 	data?.application?.specializationId,
        // 	formState?.formData?.programDetails?.specializations
        // );
        formDispatch({
          type: 'update',
          payload: {
            ...data,
            ...res?.data,
            application: {
              ...formState?.formData?.application,
              ...data?.application,
              ...res?.data?.application,
              specializationId_select: getNameById(
                data?.application?.specializationId,
                formState?.formData?.programDetails?.specializations || [],
                'recordId',
                'specializationName'
              ), //need same in summary page
              campusId_select: getNameById(
                data?.application?.campusId,
                formState?.formData?.programDetails?.campuses || [],
                'recordId',
                'campusName'
              ),
              campusName: getNameById(
                data?.application?.campusId,
                formState?.formData?.programDetails?.campuses || [],
                'recordId',
                'campusName'
              ),
              specializationName: getNameById(
                data?.application?.specializationId,
                formState?.formData?.programDetails?.specializations || [],
                'recordId',
                'specializationName'
              ),
              recordId: res.applicationId,
            },
          },
        });
        // if (!query.has('applicationId')) {
        //   const applicationId = appId || res.applicationId;
        //   navigate({
        //     pathname: '/form',
        //     search:
        //       location?.search +
        //       '&' +
        //       new URLSearchParams({
        //         applicationId: applicationId,
        //         new: true,
        //       }).toString(),
        //   });
        // }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log('error while creating/updating application', err);
      });
  };

  const getStepContentByType = (type) => {
    switch (type) {
      case 'payment':
        return (
          <>
            <Box className={classes.paymentContainer}>
              <KenPaymentStep
                {...currentStepDetails}
                voucher={currentStepDetails?.voucher}
                currencyCode="₹"
                fee={fee}
                finalFee={finalFee}
                feeAfterVoucher={feeAfterVoucher}
                handleRemoveVoucher={handleRemoveVoucher}
                feeType="Application Fee"
                instituteName="Application for SP Jain, Dubai"
                description="Your Application for Mr.Aditya Nair for Postgraduation Program, MBA at SP Jain,Dubai has been saved successfully!"
                secondaryFeeLabel="Application Fee After Discount"
                voucherLabel="Do you have a discount code?"
                handlePayNow={handlePayNow}
                handleApplyVoucher={handleApplyVoucher}
              />
            </Box>
            <KenStepperFooter
              completedSteps={completedSteps}
              handleComplete={handleComplete}
              handleBack={handleBack}
              handleSave={handleSave}
              handleNext={handleNext}
              activeStep={activeStep}
              steps={formJSON}
              stepsCount={formJSON.length}
              completed={completed}
              allStepsCompleted={allStepsCompleted}
              handleStepsSubmit={handleStepsSubmit}
              formValid={formValid}
              hideSave={true}
            />
          </>
        );

      case 'summary':
        return (
          <>
            <form
              id={`form-step${activeStep}`}
              onSubmit={methods.handleSubmit(onFormSubmit)}
            >
              <Box margin="0px 200px" className={classes.summaryForm}>
                <KenSummaryStep
                  formJson={formJSON}
                  data={formState?.formData}
                />
              </Box>
              <Box>
                <KenStepperFooter
                  completedSteps={completedSteps}
                  handleComplete={handleComplete}
                  handleBack={handleBack}
                  handleSave={handleSave}
                  handleNext={handleNext}
                  activeStep={activeStep}
                  steps={formJSON}
                  stepsCount={formJSON.length}
                  completed={completed}
                  allStepsCompleted={allStepsCompleted}
                  handleStepsSubmit={handleStepsSubmit}
                  formValid={formValid}
                  hideSave={true}
                />
              </Box>
            </form>
          </>
        );

      default:
        let updatedSectionForMobile = Array.isArray(sections)
          ? JSON.parse(JSON.stringify(sections))
          : [];
        if (
          isMobileScreen &&
          currentStepDetails.stepId === 'educationDetails'
        ) {
          // find index of visible element
          const firstVisibleSection = updatedSectionForMobile?.find(
            (s) => s.sectionVisible
          );
          firstVisibleSection.sectionName = 'Education Details';
          firstVisibleSection.mergedSubSection = [];
          updatedSectionForMobile.forEach((s) => {
            // Push all next sections which needs to be merged into previous
            if (
              s.sectionId !== firstVisibleSection.sectionId &&
              s.mergePrevMobile &&
              s.sectionVisible
            ) {
              firstVisibleSection.mergedSubSection.push(s);
              s.sectionVisible = false;
            }
          });
        }

        return (
          <Box className={classes.formContainer}>
            {currentStepDetails?.showStepName && (
              <p style={{ textAlign: 'left' }}>
                {currentStepDetails?.stepName}
              </p>
            )}
            <form
              id={`form-step${activeStep}`}
              onSubmit={methods.handleSubmit(onFormSubmit, onError)}
              style={{ paddingBottom: '140px' }}
            // onBlur={methods.handleSubmit(onFormBlur)}
            >
              <FormProvider {...methods}>
                {(isMobileScreen ? updatedSectionForMobile : sections)?.map(
                  (section, index) => {
                    return (
                      <>
                        {section.sectionVisible === true &&
                          (isMobileScreen &&
                            currentStepDetails.showAccordionInMobile ? (
                            <Box
                              margin={isMobileScreen ? '0px 16px' : '0px 318px'}
                              minHeight={
                                sections.length === 1
                                  ? '-webkit-calc(100% - 68px)'
                                  : 'none'
                              }
                            >
                              <Accordion
                                square
                                defaultExpanded
                                sx={{
                                  border: 0,
                                  'box-shadow': 'none',
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  sx={{ padding: 0 }}
                                >
                                  {section?.sectionName && (
                                    <Typography
                                      align="left"
                                      className={classes.sectionLabel}
                                      fontSize="20px"
                                    >
                                      {section?.sectionName}
                                    </Typography>
                                  )}
                                </AccordionSummary>
                                <AccordionDetails
                                  className={classes.accordionDetail}
                                >
                                  {section?.subSectionName && (
                                    <Typography
                                      align="left"
                                      marginBottom={theme.spacing(4)}
                                      className={classes.sectionSubLabel}
                                      variant="body2"
                                    >
                                      {section?.subSectionName}
                                    </Typography>
                                  )}
                                  <Box key={section.sectionId}>
                                    {/* <Grid container spacing={6} rowSpacing={4}> */}
                                    <KenSectionContent
                                      {...props}
                                      fields={section?.fields}
                                      onFormSubmit={onSectionSubmit}
                                      schema={schema}
                                      setSchema={setSchema}
                                      isFormValid={isFormValid}
                                    ></KenSectionContent>
                                  </Box>

                                  {section?.mergedSubSection?.map(
                                    (subSection) => {
                                      return (
                                        <Box>
                                          <Divider
                                            style={{
                                              margin: `${theme.spacing(
                                                3
                                              )} auto`,
                                            }}
                                          ></Divider>
                                          {subSection?.subSectionName && (
                                            <Typography
                                              align="left"
                                              marginBottom={theme.spacing(4)}
                                              className={
                                                classes.sectionSubLabel
                                              }
                                              variant="body2"
                                            >
                                              {subSection?.subSectionName}
                                            </Typography>
                                          )}
                                          <Box key={subSection.sectionId}>
                                            {/* <Grid container spacing={6} rowSpacing={4}> */}
                                            <KenSectionContent
                                              {...props}
                                              fields={subSection?.fields}
                                              onFormSubmit={onSectionSubmit}
                                              schema={schema}
                                              setSchema={setSchema}
                                              isFormValid={isFormValid}
                                            ></KenSectionContent>
                                          </Box>
                                        </Box>
                                      );
                                    }
                                  )}
                                </AccordionDetails>
                              </Accordion>
                              <Divider />
                            </Box>
                          ) : (
                            <Box
                              margin={isMobileScreen ? '0px 16px' : '0px 318px'}
                              minHeight={
                                sections.length === 1
                                  ? '-webkit-calc(100% - 68px)'
                                  : 'none'
                              }
                            >
                              {section?.sectionName && (
                                <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom={theme.spacing(4)}>
                                  <Typography
                                    align="left"

                                    className={classes.sectionLabel}
                                    fontSize="20px"
                                  >
                                    {section?.sectionName}
                                  </Typography>
                                  {section?.requiredHint && !isMobileScreen && <Typography className={classes.required}>Fields marked with <span className='required'>*</span> are required</Typography>}
                                </Box>
                              )}
                              {section?.subSectionName && (
                                <Typography
                                  align="left"
                                  marginBottom={theme.spacing(4)}
                                  className={classes.sectionSubLabel}
                                  variant="body2"
                                >
                                  {section?.subSectionName}
                                </Typography>
                              )}
                              <Box key={section.sectionId}>
                                {/* <Grid container spacing={6} rowSpacing={4}> */}
                                <KenSectionContent
                                  {...props}
                                  fields={section?.fields}
                                  onFormSubmit={onSectionSubmit}
                                  schema={schema}
                                  setSchema={setSchema}
                                  isFormValid={isFormValid}
                                ></KenSectionContent>
                                {/* </Grid> */}
                                {sections?.length - 1 !== index && (
                                  <Box mt={3} mb={6}>
                                    <Divider />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          ))}
                      </>
                    );
                  }
                )}
              </FormProvider>
            </form>
            <KenStepperFooter
              completedSteps={completedSteps}
              handleComplete={handleComplete}
              handleBack={handleBack}
              handleSave={handleSave}
              handleNext={handleNext}
              activeStep={activeStep}
              steps={formJSON}
              stepsCount={formJSON.length}
              completed={completed}
              allStepsCompleted={allStepsCompleted}
              handleStepsSubmit={handleStepsSubmit}
              formValid={formValid}
            />
            {/* <Button type='submit'>Submit</Button> */}
          </Box>
        );
    }
  };

  return (
    <>
      <div id="content">
        {loading && <KenLoader />}
        {getStepContentByType(currentStepDetails?.stepType)}
      </div>
    </>
  );
}
export default KenStepperContent;
