import React, { useEffect } from 'react';

import KenApplicationForm from '../../Components/KenApplicationForm';
import KenApplication from '../../Components/KenApplication';
import FormHeader from './Components/FormHeader';
import { ApplicationContextProvider } from '../../Context/applicationContext';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

function ApplicationForm() {
  return (
    <ApplicationContextProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormHeader />
        <KenApplicationForm />
      </LocalizationProvider>
    </ApplicationContextProvider>
  );
}

export default ApplicationForm;
