import './App.css';
import React from 'react';
import MainLayout from './Containers/MainLayout';
import { AuthContextProvider } from './Context/authContext';
import ThemeProvider from './Utils/themeProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from './Utils/i18next/i18n.js';
import 'react-calendar/dist/Calendar.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { AppContextProvider } from './Context/appContext';
import { FormContextProvider } from './Context/formContext';
import { BrowserRouter } from 'react-router-dom';
// import { Worker } from '@react-pdf-viewer/core';

function App() {
  return (
    <BrowserRouter>
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js"> */}
        <AppContextProvider>
          <FormContextProvider>
            <AuthContextProvider>
              <ThemeProvider>
                <I18nextProvider i18n={i18n}>
                  <div className="App">
                    <MainLayout />
                  </div>
                </I18nextProvider>
              </ThemeProvider>
            </AuthContextProvider>
          </FormContextProvider>
        </AppContextProvider>
      {/* </Worker> */}
    </BrowserRouter>
  );
}

export default App;
