import { Divider, Grid } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import KenInput from '../../../../../KenInput';
import KenSelect, { CustomIcon } from '../../../../../KenSelect';
import KenPhoneInput from '../../../../../KenPhoneInput';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import KenRadioGroup from '../../../../../KenRadioGroup';
import KenDocumentUpload from '../../../../../KenDocumentUpload';
import { UploadDocumentContext } from '../../../../../../Context/uploadDocumentContext';
import { makeStyles } from '@mui/styles';
import KenFieldArray from '../../../../../KenFieldArray';
import { City, Country, State } from 'country-state-city';
import FieldArray from '../../../../../KenApplication/FieldArray';
import Calendar from '../../../../../../Assets/Svg/datepicker-calendar.svg';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import getValidation from '../../../../../../Utils/Helpers/validationHelper';
import KenLoader from '../../../../../KenLoader';
import {
  getState,
  getDistrict,
  getTaluk,
  getVillage
} from '../../../../../../Utils/apiServices';
const useStyles = makeStyles((theme) => ({
  phoneContainerClass: {
    '& .phone-input': {
      height: '57px !important',
      width: '100%',
    },
  },
  inputFieldLabelClass: {
    color: '#505F79',
    'font-size': '12px',
    'line-height': '16px',
    'margin-bottom': '6px',
    'text-transform': 'capitalize',
    fontWeight: 500,
  },
  formFieldLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    color: '#505F79',
  },
  datePickerClass: {
    '&  button.MuiIconButton-edgeEnd': {
      marginLeft: 0,
      marginRight: theme.spacing(1),
    },
  },
}));

const CustomCalendar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
        stroke="#73706E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 2V6"
        stroke="#73706E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 2V6"
        stroke="#73706E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 10H21"
        stroke="#73706E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const KenWidget = (props) => {
  const {
    ui, //TODO: ui.divider added only for select and input. Add it for all widgets.
    type,
    name,
    // label,
    description,
    fieldId,
    lastItem,
    // isFormValid,
    // options,
    validations = {},
    fieldsArray,
    validationType,

    // getFormValues,
  } = props;
  // TODO: Create a new component for upload and Move this context
  const { state, dispatch } = useContext(UploadDocumentContext);
  const classes = useStyles();
  const methods = useFormContext();
  const { register, watch } = useFormContext();
  const [objectName, setObjectName] = useState();
  const [fieldName, setFieldName] = useState();
  const errors = methods.formState.errors;
  //   console.log("errors in form", methods.formState.errors);
  const [size, setSize] = useState({
    xs: ui?.grid?.xs || 12,
    sm: ui?.grid?.sm || 6,
    md: ui?.grid?.md || 6,
    lg: ui?.grid?.lg || 6,
  });

  const age = useWatch(`${name}`);
  const doBirth = age?.applicant?.dateOfBirth;
  const { setValue } = useFormContext();


  useEffect(() => {
    if (doBirth) {
      const birthDateObj = new Date(doBirth);
      const currentDateObj = new Date();
      let ageDiff = currentDateObj.getFullYear() - birthDateObj.getFullYear();
      const currentMonth = currentDateObj.getMonth() + 1;
      const birthMonth = birthDateObj.getMonth() + 1;
      if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDateObj.getDate() < birthDateObj.getDate())) {
        ageDiff--;
      }
      setValue('applicant[age]', `${ageDiff}`);
    } else {
      // If doBirth is null, set the age to 0
      setValue('applicant[age]', '');
    }
  }, [doBirth]);

  useEffect(() => {
    switch (ui?.gridType) {
      case 'fullRow':
        setSize({ xs: 12, sm: 12, md: 12, lg: 12 });

        break;

      case 'halfRow':
        setSize({ xs: 12, sm: 6, md: 6, lg: 6 });
        break;

      default:
        break;
    }
  }, [ui?.gridType]);

  const getSplicedName = (combinedName) => {
    const replaceBrackets = combinedName.replace(/[[\]']+/g, ' ');
    const splitStr = replaceBrackets?.split(' ');
    if (Array.isArray(splitStr) && splitStr.length > 0) {
      setObjectName(splitStr[0]);
      setFieldName(splitStr[1]);
    }
  };

  useEffect(() => {
    getSplicedName(name);
  }, [name]);

  useEffect(() => {
    // console.log("errors", errors);
    for (const [key, value] of Object.entries(errors)) {
      console.log(`${key}: ${value}`);
    }

    const isEmpty = Object.keys(errors).length === 0;
    if (isEmpty) {
      // isFormValid(true);
    } else {
      // isFormValid(false);
    }
  }, [errors]);

  const handleFileChange = (file, index) => {
    // console.log("file", file);
    dispatch({
      type: 'upload',
      payload: {
        id: fieldId,
        name: name,
        label: props.label,
        description: description,
        previewImage: file,
      },
    });
  };

  const getValidations = (validate) => {
    const validateCopy = { ...validate };
    if (
      validateCopy?.pattern &&
      typeof validateCopy?.pattern?.value !== 'object'
    ) {
      validateCopy.pattern.value = getValidation(validationType);
    }
    console.log(validateCopy);
    return validateCopy;
  };

  const handleFileDelete = (name) => {
    const index = state.newlyAddedFiles?.findIndex(
      (item) => item.name === name
    );
    dispatch({
      type: 'delete',
      payload: index,
    });
  };

  const getElementError = (data, name) => {
    const allData = Object.values(data);
    const index = allData?.findIndex((elem) => elem?.ref?.name === name);
    if (index > -1) {
      return allData[index]?.message;
    } else {
      return data?.message;
    }
  };

  // useEffect(() => {
  // 	if (type === "upload") {
  // 		dispatch({
  // 			type: "uploaded",
  // 			payload: {
  // 				id: fieldId,
  // 				name: name,
  // 				label: label,
  // 				description: description,
  // 				//  previewImage:file
  // 			},
  // 		});
  // 	}
  // }, []);

  const getPreviewImage = () => {
    const newFiles = state.newlyAddedFiles;
    const file = newFiles.find((item) => item.name === name);
    return file?.previewImage || false;
  };

  // const noOfFamilyMembers = watch('applicantRelations[0].noOfFamilyMembers');
  // const noOfFamilyMembersAdults = watch('applicantRelations[0].noOfFamilyMembersAdults');
  // const calculateExpectedChildren = () => {
  //   const totalFamilyMembers = parseInt(noOfFamilyMembers) || 0;
  //   const adultFamilyMembers = parseInt(noOfFamilyMembersAdults) || 0;
  //   const expectedChildren = totalFamilyMembers - adultFamilyMembers;
  //   setValue('applicantRelations.0.noOfFamilyMembersChildren', expectedChildren, { shouldValidate: true });
  // };

  // useEffect(() => {
  //   calculateExpectedChildren();
  // }, [noOfFamilyMembers, noOfFamilyMembersAdults]);

  const noOfFamilyMembers = watch('applicantRelations[0].noOfFamilyMembers');
  const noOfFamilyMembersAdults = watch('applicantRelations[0].noOfFamilyMembersAdults');

  const calculateExpectedChildren = () => {
    const totalFamilyMembers = parseInt(noOfFamilyMembers) || 0;
    const adultFamilyMembers = parseInt(noOfFamilyMembersAdults) || 0;

    if (adultFamilyMembers === 0) {
      setValue('applicantRelations.0.noOfFamilyMembersChildren', '');
    } else {
      let expectedChildren = totalFamilyMembers - adultFamilyMembers;
      expectedChildren = expectedChildren < 0 ? 0 : expectedChildren;
      setValue('applicantRelations.0.noOfFamilyMembersChildren', expectedChildren);
    }
  };



  useEffect(() => {
    calculateExpectedChildren();
  }, [noOfFamilyMembers, noOfFamilyMembersAdults]);


  // const family = watch("name1")
  // const adult = watch("name2")

  // useEffect(() => {
  //   methods.setValue("name3", "")

  // },[family, adult])


  const getField = (type) => {
    switch (type) {
      case 'select':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <KenSelect
                textFieldProps={{
                  SelectProps: {
                    IconComponent: CustomIcon,
                  },
                }}

                {...props}
                labelClassName={classes.formFieldLabel}
                onChange={onChange}
                value={value}
                error={error?.message}
              />
            )}
            {...methods.register(name, validations)}
          />
        );
      // case 'children':
      // return (
      //   <Controller
      //     name="noOfFamilyMembersChildren"
      //     control={methods.control}
      //     render={({ field }) => (
      //       <KenInput
      //         {...field}
      //     type="number"
      //     // placeholder="No. of Family Members: Children"
      //     value={field.value || ''}  
      //     onChange={(e) => field.onChange(e.target.value)}  
      //       />
      //     )}
      //   />
      // );

      case 'family':
        return (
          <Controller
            name="noOfFamilyMembersChildren[expectedChildren]"
            control={methods.control}
            render={({ field }) => (
              <KenInput
                {...field}
                type="number"
                readOnly
                value={field.value}
              />
            )}
          />

        );
      case 'input':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput {...props} {...field} error={error?.message} />
              </>
            )}
            {...methods.register(name, getValidations(validations))}
          />
        );

      case 'textArea':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput
                  {...props}
                  {...field}
                  labelClassName={classes.formFieldLabel}
                  multiline={true}
                  minRows={3}
                  error={error?.message}
                />
              </>
            )}
            {...methods.register(name, validations)}
          />
        );

      case 'phone':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <KenPhoneInput
                  phoneContainerClass={classes.phoneContainerClass}
                  inputFieldLabelClass={classes.formFieldLabel}
                  {...props}
                  onChange={onChange}
                  value={value}
                  error={error?.message}
                />
              );
            }}
            {...methods.register(name, validations)}
          />
        );
      case 'radio':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <KenRadioGroup
                {...props}
                labelClassName={classes.formFieldLabel}
                onChange={onChange}
                value={value}
                error={error?.message}
              />
            )}
            {...methods.register(name, validations)}
          />
        );
      case 'upload':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <>
                <KenDocumentUpload
                  {...props}
                  onChange={(files) => {
                    handleFileChange(files[0], name);
                  }}
                  key={props?.fieldId}
                  previewImage={getPreviewImage()}
                  removeFile={() => {
                    handleFileDelete(name);
                  }}
                  // onChange={onChange}
                  value={value}
                />
                {(!lastItem || ui?.divider) && <Divider></Divider>}
              </>
            )}
            {...methods.register(name, validations)}
          />
        );

      case 'addMultiple': {
        return (
          <KenFieldArray
            {...props}
            // onChange={onChange}
            // value={value}
            bunchOfFields={fieldsArray}
            fieldArrayName={`${name}`}
            defaultObject={fieldsArray && fieldsArray[0]}
            error={
              methods.formState.errors &&
              methods.formState.errors[objectName] &&
              methods.formState.errors[objectName][fieldName] &&
              getElementError(
                methods.formState.errors[objectName][fieldName],
                name
              )
            }
            {...methods.register(name, validations)}
          />
          // <Controller
          // 	name={`${name}`}
          // 	control={methods.control}
          // 	render={({ field: { onChange, value } }) => (
          // 		<KenFieldArray
          // 			{...props}
          // 			onChange={onChange}
          // 			value={value}
          // 			bunchOfFields={fieldsArray}
          // 			fieldArrayName={`${name}`}
          // 			defaultObject={fieldsArray && fieldsArray[0]}
          // 		/>
          // 	)}
          // />
        );
      }

      case 'array':
        return <FieldArray {...props} />;

      case 'address':
        return <Address {...props} />;

      case 'date':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput
                  {...props}
                  {...field}
                  // disableKeyDown={true}
                  error={error?.message}
                />
              </>
            )}
            {...methods.register(name, validations)}
          />
        );

      default:
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput {...props} {...field} error={error?.message} />
              </>
            )}
            {...methods.register(name, validations)}
          />
        );
    }
  };

  return type !== 'addMultiple' && type !== 'address' && type !== 'array' ? (
    <Grid
      item
      xs={size.xs}
      sm={size.sm}
      md={size.md}
      lg={size.lg}
      textAlign="left"
    >
      {props && getField(type)}
    </Grid>
  ) : (
    <>{props && getField(type)}</>
  );
};
export default KenWidget;
const Address = function (props) {
  const { options: fields = [], name } = props;
  const { getValues, setValue } = useFormContext();

  const {
    mailingCountry,
    mailingState,
    mailingDistrict,
    city: Citys,
    tehsil,
    country: addressCountry,
    state: addressState,
    City: addressCity
  } = getValues(`${name}`) || {};


  const [state, setState] = useState([]);
  const [dist, setDist] = useState([]);
  const [city, setCity] = useState([]);
  const [village, setVillage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const { register, watch } = useFormContext();
  const isPermanentAddressSame = watch('addresses[0][isPermanentAddressSame]');
  const isMailingState = watch('addresses[1][mailingState]');
  const isCity = watch('addresses[1][city]');
  const isTehsil = watch('addresses[1][tehsil]');

  useState(() => {
    setValue("addresses.1.mailingCountry", "INDIA")
  }, [isPermanentAddressSame])

  useEffect(() => {
    setValue("addresses.1.city", null)
    setValue("addresses.1.tehsil", null)
    setValue("addresses.1.gramPanchayat", null)
    setCity([])
    setVillage([])
  }, [isMailingState])

  useEffect(() => {
    setValue("addresses.1.tehsil", null)
    setValue("addresses.1.gramPanchayat", null)
    setVillage([])
  }, [isCity])

  useEffect(() => {
    setValue("addresses.1.gramPanchayat", null)
  }, [isTehsil])

  useEffect(async () => {
    setLoading(true)
    const data = await getState() 
    if (data.length > 0) {
      setLoading(false)
      setState(data.map((el) => {
        return { label: el, value: el }
      }))
    } else {
      setLoading(false)
      return []
    }
  }, [])

  useEffect(async () => {
    setLoading(true)
    if (mailingState) {
      const data = await getDistrict(mailingState)
      if (data.length > 0) {
        setLoading(false)
        setDist(data.map((el) => {
          return { label: el, value: el }
        }))

      } else {
        setLoading(false)
        return []
      }
    } else {
      setLoading(false)
    }
  }, [mailingState])

  useEffect(async () => {
    setLoading(true)
    if (mailingState && Citys) {
      const data = await getTaluk(mailingState, Citys)
      if (data.length > 0) {
        setLoading(false)
        setCity(data.map((el) => {
          return { label: el, value: el }
        }))
      } else {
        setLoading(false)
        return []
      }
    } else {
      setLoading(false)
    }
  }, [mailingState, Citys])

  useEffect(async () => {
    setLoading(true)
    if (mailingState && Citys && tehsil) {
      const data = await getVillage(mailingState, Citys, tehsil)
      if (data.length > 0) {
        setLoading(false)
        setVillage(data.map((el) => {
          return { label: el, value: el }
        }))
      } else {
        setLoading(false)
        return []
      }
    }else{
      setLoading(false)
    }
  }, [mailingState, Citys, tehsil])




  const getOptions = ({ fieldId }) => {
    switch (fieldId) {
      case 'mailingCountry':
      case 'country':
        return Country.getAllCountries().map(({ name }) => ({
          label: name.toUpperCase(),
          value: name.toUpperCase(),
        }));
      case 'mailingState':
      case 'state':
        return state

      case 'mailingDistrict':
      case 'city':
        return dist;

      case 'tehsil':
        return city

      case "gramPanchayat":
        return village

      default:
        return [];
    }
  };
  return (
    <>
      {fields.map((field) => (
        <>
          {loading && <KenLoader />}
          {getOptions(field).length > 0 && (
            <KenWidget
              {...field}
              name={`${name}[${field?.name}]`}
              options={getOptions(field)}
            />
          )}
        </>
      ))}
    </>
  );
};
