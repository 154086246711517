import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.KenColors.primary,
    borderRadius: "32px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    padding: "12px 0px",
    color: theme.palette.KenColors.kenWhite,
    fontSize: 14,
  },
}));

export default function GeneralButton(props) {
  const { onClick, title } = props;
  const classes = useStyles();
  return (
    <Box className={classes.container} onClick={onClick}>
      <Typography className={classes.text}>{title}</Typography>
    </Box>
  );
}
