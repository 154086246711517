import {
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import KenSelect, { CustomIcon } from '../../Components/KenSelect';
import CoursesListView from './Components/CoursesListView/index.jsx';
import {
  getAllPrograms,
  getApplicationsByApplicantId,
} from '../../Utils/apiServices';
/* Icons */
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import CoursesCardView from './Components/CoursesCardView/index.jsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import KenLoader from '../../Components/KenLoader';
import ApplicationCard from '../../Cards/ApplicationCard';
import { FormContext } from '../../Context/formContext';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { AuthContext } from '../../Context/authContext';
import KenDialog from '../../Components/KenDialog';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { KenBreadcrumbs } from '../../Components/KenBreadcrumbs';
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme) => ({
  subInfo: {
    textAlign: 'left',
    fontFamily: "'Poppins'",
    'font-size': '16px',
  },
  bckBtn: {
    color: '#fff',
    backgroundColor: '#092682',
    borderRadius: '32px',
    '&:hover': {
      cursor: 'pointer',
      background: '#DFE8FF',
      color: theme.palette.labels.medium,
    },
    padding: '12px',
    'min-width': '180px',
    'min-height': '40px',
    'box-shadow':
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    'border-radius': '32px',
  },
  headingMain: {
    textAlign: 'left',
    fontSize: 24,
    color: theme.palette.KenColors.dark,
    lineHeight: 1,
    fontWeight: 500,
  },
  filterIcon: {
    marginTop: 16,
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginTop: 0,
    },
  },
  exploreHeading: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      background: '#F7F8FB',
      width: '100%',
      padding: '27px !important',
    },
  },
  filterHeader: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: '16px',
      'font-weight': '500',
      'font-size': '16px',
      'line-height': '100%',
      'letter-spacing': '-0.02em',
      'text-transform': 'capitalize',
      color: theme.palette.KenColors.neutral900,
    },
  },
  courseCount: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.labels.light,
    textAlign: 'left',
    color: theme.palette.KenColors.grey3,
  },
  select: {
    background: '#F3F5F7',
    'border-radius': '4px',
  },
  selectText: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '16px',
    color: '#7A869A',
  },
}));

export default function Courses(props) {
  const { t } = useTranslation();

  const classes = useStyles();

  const [cardView, setCardView] = useState(true);
  const [listView, setListView] = useState(false);

  /* options for filter */
  const [coursesData, setCoursesData] = useState([]);
  const [data, setData] = useState();
  const [campusData, setCampusData] = useState(null);
  const [courseTypeData, setCourseTypeData] = useState(null);
  const [academicProgram, setAcademicProgram] = useState(null);
  const [course, setCourse] = useState();
  const [courseType, setCourseType] = useState();
  const [courseLocation, setCourseLocation] = useState();
  const [courseValue, setCourseValue] = useState();
  const [courseTypeValue, setCourseTypeValue] = useState();
  const [courseLocationValue, setCourseLocationValue] = useState();
  const [loader, setLoader] = useState();
  const query = new URLSearchParams(window.location.search);
  const search = query.get('search');
  const [active, setActive] = useState();
  const { formDispatch } = useContext(FormContext);
  const [dialog, setDialog] = useState(false);
  const [sameApplicationData, setSameApplicationData] = useState();
  const {
    state: { user },
  } = useContext(AuthContext);

  const goToSameApplication = () => {
    formDispatch({
      type: 'new',
      payload: {
        programDetails: sameApplicationData,
        applicationId: sameApplicationData.recordId,
        formId: sameApplicationData.applicationForm,
      },
    });

    navigate({
      pathname: '/application',
      search: `?applicationId=${sameApplicationData.recordId}&formId=${sameApplicationData.applicationForm}`,
    });
  };

  const goToApplicationForm = (item) => {
    setLoader(true);
    getApplicationsByApplicantId(user?.applicantId).then((res) => {
      const { data } = res;
      const sameApplicationFlag = data?.some(
        (el) =>
          el.courseBatch === item.courseBatch &&
          el.courseDuration === item.courseDuration &&
          el.programName === item.programName
      );
      const index = data?.findIndex(
        (el) =>
          el.courseBatch === item.courseBatch &&
          el.courseDuration === item.courseDuration &&
          el.programName === item.programName
      );
      

      setSameApplicationData(data[index]);
      if (sameApplicationFlag === true) {
        setLoader(false);
        setDialog(true);
      } else {
        setLoader(false);
        formDispatch({
          type: 'new',
          payload: {
            programDetails: { ...item, formId: item.recordId },
          },
        });
        navigate({
          pathname: '/application',
          search: `?formId=${item?.recordId}&type=${item?.departmentName}`,
        });
      }
    });
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/home');
  };

  const pushObjectToFront = (id, arr) => {
    let arrCopy = [...arr];
    const index = arrCopy.findIndex((el) => el?.programId === id);
    if (index > -1) {
      const obj = arrCopy[index];
      arrCopy.splice(index, 1);
      arrCopy.unshift(obj);
    }
    return arrCopy;
  };

  React.useEffect(() => {
    setLoader(true);
    getAllPrograms(user?.applicantId)
      .then((res) => {
        if (res?.data) {
          let data = res?.data?.forms?.map((el) => {
            const isBetween = moment(el?.endDate).diff(moment(), 'day');
            console.log('isBetween',isBetween);
            if (isBetween >= 0) {
              return {
                course: el?.programName,
                duration: `${
                  isNaN(moment(el?.endDate).diff(moment(el?.startDate), 'year'))
                    ? 0
                    : moment(el?.endDate).diff(moment(el?.startDate), 'year')
                } yrs`,
                lastdate: el?.endDate,
                id: el?.recordId,
                ...el,
              };
            }
          });
          console.log(data);
          // if (
          //   Object.values(res?.data?.preferences).every((el) => el !== null)
          // ) {
          //   if (res?.data?.preferences?.preference3) {
          //     data = pushObjectToFront(
          //       res?.data?.preferences?.preference3,
          //       data
          //     );
          //   } else if (res?.data?.preferences?.preference2) {
          //     data = pushObjectToFront(
          //       res?.data?.preferences?.preference2,
          //       data
          //     );
          //   } else if (res?.data?.preferences?.preference1) {
          //     data = pushObjectToFront(
          //       res?.data?.preferences?.preference1,
          //       data
          //     );
          //   }
          // }
          setCoursesData(data);
          setData(data);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []);

  React.useEffect(() => {
    const location = [...new Set(coursesData.map((item) => item?.location))];
    const city = [{ label: 'All Campus', value: 'all' }];
    location.forEach((el, index) => {
      city.push({ label: el, value: el });
    });
    setCourseLocation(city);
    setCourseLocationValue(city[0]?.value);

    const name = [
      ...new Set(
        coursesData.map((item) => {
          return { programName: item?.programName, programId: item?.programId };
        })
      ),
    ];
    const courseName = [{ label: 'All Programs', value: 'all' }];
    name.forEach((el, index) => {
      if (!courseName.some((e) => e?.value === el?.programId)) {
        courseName.push({ label: el?.programName, value: el?.programId });
      }
    });
    setCourse(courseName);
    setCourseValue(courseName[0]?.value);

    const type = [
      ...new Set(
        coursesData.map((item) => {
          return {
            departmentName: item?.departmentName,
            departmentId: item?.departmentId,
            ...item,
          };
        })
      ),
    ];
    const courseType = [{ label: 'All Programs', value: 'all'}];
    // type.forEach((el, index) => {
    //   if (!courseType.some((e) => e.value === el.departmentId)) {
    //     courseType.push({ label: el?.departmentName, value: el?.departmentId });
       
    //   }
    // });
    
    setCourseType(courseType);
    setCourseTypeValue(courseType[0]?.value);
  }, [coursesData]);

  useEffect(() => {
    if (coursesData) {
      const dataCopy = [...coursesData];
      const searchedData = [];
      dataCopy.forEach((el) => {
        if (
          el?.programName?.toLowerCase()?.includes(search?.toLowerCase()) ===
          true
        ) {
          searchedData.push(el);
        }
      });
      if (searchedData.length !== 0) {
        setData(searchedData);
      } else {
        setData(coursesData);
      }
    }
  }, [search, coursesData]);

  // const handleCourseLocation = (value) => {
  //   const dataCopy = [];
  //   if (value === "all") {
  //     setData(coursesData);
  //     setCampusData(coursesData);
  //     // academic program
  //     const name = [...new Set(coursesData?.map((item) => item.course))];
  //     const courseName = [{ label: "All Programs", value: "all" }];
  //     name.map((el, index) => {
  //       courseName.push({ label: el, value: el });
  //     });
  //     setCourse(courseName);
  //     setCourseValue(courseName[0].value);

  //     // program type
  //     const type = [...new Set(coursesData.map((item) => item.courseType))];
  //     const courseType = [];
  //     type.map((el, index) => {
  //       courseType.push({ label: el, value: el });
  //     });
  //     setCourseType(courseType);
  //     setCourseTypeValue(courseType[0].value);
  //   } else {
  //     const locations = coursesData.filter((el) => el.location === value);
  //     locations.map((item) => {
  //       if (value === item.location) {
  //         dataCopy.push(item);
  //       }
  //     });
  //     // academic program
  //     const name = [...new Set(dataCopy?.map((item) => item.course))];
  //     const courseName = [{ label: "All Programs", value: "all" }];
  //     name.map((el, index) => {
  //       courseName.push({ label: el, value: el });
  //     });
  //     setCourse(courseName);
  //     setCourseValue(courseName[0].value);

  //     // program type
  //     const type = [...new Set(dataCopy?.map((item) => item.courseType))];
  //     const courseType = [];
  //     type.map((el, index) => {
  //       courseType.push({ label: el, value: el });
  //     });
  //     setCourseType(courseType);
  //     setCourseTypeValue(courseType[0].value);

  //     //  data to display
  //     setCampusData(dataCopy);
  //     setData(dataCopy);
  //   }
  // };
  const handleDepartment = (value) => {
    // console.log(value);
    // console.log(academicProgram);
    // const dataCopy = [];
    // if (campusData) {
    //   campusData.map((item) => {
    //     if (value === item.departmentId) {
    //       dataCopy.push(item);
    //     }
    //   });
    // } else {
    //   if (academicProgram) {
    //     academicProgram.map((item) => {
    //       console.log("inside here");
    //       if (value === item.departmentId) {
    //         dataCopy.push(item);
    //       }
    //     });
    //   } else {
    //     if (value === "all") {
    //       console.log(coursesData);
    //       dataCopy.push(...coursesData);
    //       console.log(dataCopy);
    //     } else {
    //       coursesData.map((item) => {
    //         console.log("inside");
    //         if (value === item.departmentId) {
    //           dataCopy.push(item);
    //         }
    //       });
    //     }
    //   }
    // }
    // console.log(dataCopy);
    // console.log(coursesData);
    // //  data to display
    // setCourseTypeData(dataCopy);
    // setData(dataCopy);
    // // academic program
    // const name = [
    //   ...new Set(
    //     dataCopy.map((item) => {
    //       return {
    //         departmentName: item?.departmentName,
    //         departmentId: item?.departmentId,
    //         ...item,
    //       };
    //     })
    //   ),
    // ];
    // const courseName = [{ label: "All Programs", value: "all" }];
    // name.map((el, index) => {
    //   if (!courseName.some((e) => e.value === el.programId)) {
    //     courseName.push({ label: el?.programName, value: el?.programId });
    //   }
    // });
    // setCourse(courseName);
    // setCourseValue(courseName[0].value);

    const dataCopy = [];
    if (value === 'all') {
      dataCopy.push(...coursesData);
    } else {
      coursesData.forEach((item) => {
        if (value === item.departmentId) {
          dataCopy.push(item);
        }
      });
    }

    setData(dataCopy);
    setCourseTypeData(dataCopy);

    const name = [
      ...new Set(
        dataCopy.map((item) => {
          return {
            departmentName: item?.departmentName,
            departmentId: item?.departmentId,
            ...item,
          };
        })
      ),
    ];

    const courseName = [{ label: 'All Programs', value: 'all' }];
    name.forEach((el) => {
      if (!courseName.some((e) => e.value === el.programId)) {
        courseName.push({ label: el?.programName, value: el?.programId });
      }
    });
    setCourse(courseName);
    setCourseValue(courseName[0].value);
  };

  const handleProgram = (value) => {
    // const dataCopy = [];
    // if (courseTypeData) {
    //   if (value === "all") {
    //     //  data to display
    //     setAcademicProgram(courseTypeData);
    //     setData(courseTypeData);
    //   } else {
    //     courseTypeData.map((item) => {
    //       if (value === item.programId) {
    //         dataCopy.push(item);
    //       }
    //     });
    //     //  data to display
    //     setAcademicProgram(dataCopy);
    //     setData(dataCopy);
    //   }
    // } else {
    //   if (campusData) {
    //     if (value === "all") {
    //       //  data to display
    //       setAcademicProgram(campusData);
    //       setData(campusData);
    //     } else {
    //       campusData.map((item) => {
    //         if (value === item.programId) {
    //           dataCopy.push(item);
    //         }
    //       });
    //       //  data to display
    //       setAcademicProgram(dataCopy);
    //       setData(dataCopy);
    //     }
    //   } else {
    //     if (value === "all") {
    //       //  data to display
    //       setAcademicProgram(coursesData);
    //       setData(coursesData);
    //     } else {
    //       coursesData.map((item) => {
    //         if (value === item.programId) {
    //           dataCopy.push(item);
    //         }
    //       });
    //       //  data to display
    //       setAcademicProgram(dataCopy);
    //       setData(dataCopy);
    //     }
    //   }
    // }

    const dataCopy = [];
    if (value === 'all') {
      dataCopy.push(...coursesData);
    } else {
      coursesData.forEach((el) => {
        if (value === el.programId) {
          dataCopy.push(el);
        }
      });
    }
    setData(dataCopy);
  };

  const handleListView = () => {
    setListView(true);
    setCardView(false);
  };
  const handleCardView = () => {
    setListView(false);
    setCardView(true);
  };

  const handleReset = () => {
    // const location = [...new Set(coursesData.map((item) => item.location))];
    // const city = [{ label: "All Campus", value: "all" }];
    // location.map((el, index) => {
    //   city.push({ label: el, value: el });
    // });
    // setCourseLocation(city);
    // setCourseLocationValue(city[0].value);

    const name = [
      ...new Set(
        coursesData.map((item) => {
          return { programName: item?.programName, programId: item?.programId };
        })
      ),
    ];
    const courseName = [{ label: 'All Programs', value: 'all' }];
    name.forEach((el, index) => {
      if (!courseName.some((e) => e.value === el.programId)) {
        courseName.push({ label: el?.programName, value: el?.programId });
      }
    });
    setCourse(courseName);
    setCourseValue(courseName[0].value);

    const type = [
      ...new Set(
        coursesData.map((item) => {
          return {
            departmentName: item?.departmentName,
            departmentId: item?.departmentId,
            ...item,
          };
        })
      ),
    ];
    const courseType = [{ label: 'All Departments', value: 'all' }];
    type.forEach((el, index) => {
      if (!courseType.some((e) => e.value === el.departmentId)) {
        courseType.push({ label: el?.departmentName, value: el?.departmentId });
      }
    });
    setCourseType(courseType);
    setCourseTypeValue(courseType[0]?.value);
    setData(coursesData);
  };

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const handleBreadCrumbEvent = () => {};

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/home">
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      onClick={handleBreadCrumbEvent}
    >
      Our Courses
    </Link>,
  ];

  return (
    <>
      {loader && <KenLoader />}
      <Grid container spacing={2}>
        {!isMobileScreen && (
          <Grid
            item
            sx={{
              paddingTop: `${theme.spacing(4.5)} !important`,
              paddingBottom: theme.spacing(1.5),
            }}
          >
            <KenBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
        )}
        <Grid item md={12} className={classes.exploreHeading}>
          <Grid container spacing={2}>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingTop: `${theme.spacing(2.5)} !important` }}
            >
              <Typography className={classes.headingMain}>
                {t('headings:Explore_All_Courses')}
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              sm={8}
              xs={12}
              sx={{ paddingTop: `${theme.spacing(1.5)} !important` }}
            >
              <Typography className={classes.subInfo}>
                {t('messages:Courses_info')}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              sm={4}
              xs={12}
              sx={{
                paddingRight: isMobileScreen ? '0px' : '79px',
                paddingTop: '0px !important',
              }}
            >
              <Box
                display="flex"
                justifyContent={'flex-end'}
                sx={{
                  paddingTop: isMobileScreen
                    ? `${theme.spacing(2)} !important`
                    : undefined,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  className={classes.bckBtn}
                  onClick={handleBack}
                >
                  {t('labels:Back_To_Dashboard')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} justifyContent="space-between">
          <Grid item md={8} tablet={8} xs={12}>
            {!search && (
              <Grid
                container
                spacing={2}
                margin={isMobileScreen ? '0' : undefined}
                width={isMobileScreen ? '100%' : undefined}
              >
                <Grid
                  item
                  md={12}
                  padding={isMobileScreen ? '16px 0 16px 16px' : undefined}
                  width={isMobileScreen ? '100%' : undefined}
                >
                  <Grid
                    container
                    spacing={2}
                    width={isMobileScreen ? '100%' : undefined}
                    flexDirection={isMobileScreen ? 'column' : undefined}
                    marginRight={isMobileScreen ? '0' : undefined}
                  >
                    {/* <Grid item md={5}>
                <KenSelect
                  options={courseLocation}
                  value={courseLocationValue}
                  handleChange={(e) => {
                    setCourseLocationValue(e.target.value);
                    handleCourseLocation(e.target.value);
                  }}
                />
              </Grid> */}
                    {isMobileScreen && (
                      <Box className={classes.filterHeader}>
                        <Typography>Filter</Typography>
                        <Box>
                          {!search && (
                            <FilterAltOffIcon
                              onClick={handleReset}
                              className={classes.filterIcon}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                    <Grid item md={5}>
                      {courseTypeValue && courseValue && (
                        <KenSelect
                          options={courseType}
                          value={courseTypeValue}
                  
                          handleChange={(e) => {
                            setCourseTypeValue(e.target.value);
                            handleDepartment(e.target.value);
                            
                          }}
                          inputProps={{
                            className: classes.selectText,
                          }}
                          textFieldProps={{
                            className: classes.select,
                            SelectProps: {
                              IconComponent: CustomIcon,
                            },
                           
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item md={5}>
                      {courseValue && (
                        <KenSelect
                          options={course}
                          value={courseValue}
                          handleChange={(e) => {
                            setCourseValue(e.target.value);
                            handleProgram(e.target.value);
                          }}
                          inputProps={{
                            className: classes.selectText,
                          }}
                          textFieldProps={{
                            className: classes.select,
                            SelectProps: {
                              IconComponent: CustomIcon,
                            },
                          }}
                        />
                      )}
                    </Grid>
                    {!isMobileScreen && (
                      <Grid item md={1}>
                        {!search && (
                          <FilterAltOffIcon
                            onClick={handleReset}
                            className={classes.filterIcon}
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            xs={12}
            sx={{
              display: 'flex',
              'justify-content': 'flex-end',
              'padding-right': '20px',
            }}
          >
            {!search && (
              <Box sx={{ display: { xs: 'none', tablet: 'block' } }}>
                <IconButton
                  sx={{ color: cardView === true ? '#092682' : '' }}
                  aria-label="card View"
                  size="large"
                  onClick={handleCardView}
                >
                  <GridViewOutlinedIcon />
                </IconButton>
                <IconButton
                  sx={{ color: listView === true ? '#092682' : '' }}
                  aria-label="list View"
                  size="large"
                  onClick={handleListView}
                >
                  <ListOutlinedIcon fontSize="large" />
                </IconButton>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Divider variant="fullWidth" sx={{ marginBottom: '12px' }} />
        </Grid>
        {isMobileScreen && (
          <Grid width="100%" marginLeft={theme.spacing(2)}>
            <Box className={classes.filterHeader}>
              <Typography>Our Courses({data?.length})</Typography>
            </Box>
          </Grid>
        )}
        {cardView && cardView === true ? (
          <>
            {!isMobileScreen && (
              <Grid
                item
                md={12}
                sx={{
                  paddingTop: `${theme.spacing(1.5)} !important`,
                  paddingBottom: `${theme.spacing(2)} !important`,
                }}
              >
                <Typography className={classes.courseCount}>
                  {data?.length} {t('labels:Courses')}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              container
              md={12}
              spacing={isMobileScreen ? 5 : 3}
              width={isMobileScreen ? '100%' : undefined}
              marginLeft={isMobileScreen ? '-20px' : undefined}
              paddingBottom={theme.spacing(4)}
              paddingTop={
                isMobileScreen ? `${theme.spacing(4)} !important` : undefined
              }
            >
              {data &&
                data.map((el) => {
                  return (
                    <Grid
                      container
                      item
                      paddingTop={
                        isMobileScreen
                          ? `${theme.spacing(2)} !important`
                          : undefined
                      }
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                    >
                      <ApplicationCard
                        item={el}
                        active={active}
                        setActive={setActive}
                        colValue={3}
                        goToApplicationForm={goToApplicationForm}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </>
        ) : (
          <Grid item md={12}>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <CoursesListView data={data} goToApplicationForm={(data) => {
                goToApplicationForm(data)
              }}/>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <CoursesCardView data={data} colValue={3} />
            </Box>
          </Grid>
        )}
      </Grid>
      <KenDialog
        open={dialog}
        handleClose={() => {
          setDialog(false);
        }}
        dialogActions={true}
        positiveButtonProps={{
          style: {
            color: '#fff',
            backgroundColor: '#092682',
            borderRadius: '32px',
            '&:hover': {
              cursor: 'pointer',
              background: '#DFE8FF',
              color: '#505F79',
            },
            padding: '13px 52px',
            fontSize: 14,
            marginBottom: 16,
          },
        }}
        positiveButtonText={'Go To Your Application'}
        positiveButtonLabelProps={{
          style: {
            color: '#fff',
          },
        }}
        handleClickOpen={() => {
          setDialog(false);
        }}
        onClose={() => {
          setDialog(false);
        }}
        hideNegativeButton={true}
        positiveButtonClick={() => {
          sameApplicationData && goToSameApplication();
        }}
      >
        <Grid container p={2}>
          <Grid item>
            <Typography variant="body2">
              You have already applied for this Course.
            </Typography>
          </Grid>
        </Grid>
      </KenDialog>
    </>
  );
}
