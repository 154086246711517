import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        'font-style': 'normal',
        'font-weight': '500',
        'font-size': '12px',
        'line-height': '100%',
        'color': theme.palette.KenColors.grey3,

        '& > .MuiBreadcrumbs-separator': {
            marginBottom: 0,
            marginTop: 0,
        },
    },
}))

export function KenBreadcrumbs(props) {
    const classes = useStyles();

    return <Stack spacing={2}>
        <Breadcrumbs
            className={classes.breadcrumbs}
            separator={<>&gt;</>}
            aria-label="breadcrumb"
        >
            {props.breadcrumbs}
        </Breadcrumbs>
    </Stack>
}