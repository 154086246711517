import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import KenUploadButton from './Components/KenUploadButton';
import FilePreview from './Components/KenFilePreview';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 16,
  },
  cardContainer: {
    padding: 8,
  },
  rowWrap: {
    marginBottom: 30,
     minHeight: '140px',//
    // borderBottom: `1px solid ${theme.palette.KenColors.neutral60}`,
    // paddingBottom: 14,
  },
  rowWrapConditional: {
    marginBottom: "100px",
    // borderBottom: 0,
  },
  rowLabel: {
    // color: theme.palette.KenColors.neutral900,
    // fontSize: 16,
    fontWeight: 500,
    color: '#091E42',
  },
  rowDesc: {
    // color: theme.palette.KenColors.neutral100,
    // fontSize: 14,
    maxWidth: '400px',
    
    color: '#7A869A',
  },
  mainContent: {
    position: 'relative',
  },
}));

export default function KenDocumentUpload(props) {
  const { t } = useTranslation();
  const [imgFile, setImgFile] = useState();
  const [imgSrc, setImgSrc] = useState(null);
  const [showImgCapture, setShowImgCapture] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const {
    value = [],
    label,
    description,
    onChange,
    length,
    index,
    uploading,
    name,
    removeFile,
    required,
    previewImage,
    preview = true,
    recordId,
    handleDownload,
    fileType,
    listofdoc,
  } = props;

  console.log('value', props, required);

  const onCapture = (imageData) => {
    // read as webP
    setImgSrc(imageData.webP);
    // read as file
    setImgFile(imageData.file);

    const captureImageData = new File(
      [imageData.blob],
      new Date().getTime() + '.png',
      {
        type: 'image/png',
        // path: imageData.webP,
      }
    );

    onChange([
      ...value,
      captureImageData,

      // {
      //   path: imageData.file.name,
      //   lastModified: imageData.file.lastModified,
      //   lastModifiedDate: imageData.file.lastModifiedDate,
      //   name: imageData.file.name,
      //   size: imageData.file.size,
      //   type: 'image/jpeg',
      //   webkitRelativePath: '',
      //   length: 1,
      // },
    ]);
    // Unmount component to stop the video track and release camera
    setShowImgCapture(false);
  };
  const onError = useCallback((error) => {
    console.log({ error });
  }, []);

  const config = useMemo(() => ({ video: true }), []);

  // const { getRootProps, getInputProps, open } = useDropzone({
  //   noClick: true,
  //   multiple: false,
  //   noKeyboard: true,
  //   maxSize: 2000000,
  //   onDrop: (acceptedFiles, fileRejections) => {
  //     console.log('test', acceptedFiles, fileRejections);    
  //     if (fileRejections.length < 0) {
  //       enqueueSnackbar(
  //         fileRejections[0]?.errors[0]?.message.replace('200000 bytes', '2 MB'),
  //         {
  //           variant: 'error',
  //         }
  //       );
  //     } else {
  //       // if (
  //       //   acceptedFiles[0]?.type === 'image/jpeg' ||
  //       //   acceptedFiles[0]?.type === 'image/png' ||
  //       //   acceptedFiles[0]?.type === 'image/jpg' 
  //       // ) {
  //       //   onChange([...value, ...acceptedFiles]);
  //       // } else {
  //       //   enqueueSnackbar(
  //       //     'Pdf file is not allowed',
  //       //     {
  //       //       variant: 'error',
  //       //     }
  //       //   );
  //       // }
  //       const fileType = acceptedFiles[0]?.type;
  //     const fileName = acceptedFiles[0]?.name;

  //       const isImage = ['image/jpeg', 'image/png', 'image/jpg'].includes(fileType);
  //       const isPdf = fileType === 'application/pdf';

  //       if (listofdoc?.title === 'Passport Size Photo') {
  //         // If it's "Passport Size Photo", only allow images
  //         if (!isImage) {
  //           enqueueSnackbar(
  //             'Only jpeg, jpg, and png formats are allowed for Passport Size Photo.',
  //             { variant: 'error' }
  //           );
  //         } else {
  //           // If valid image, proceed with the change
  //           onChange([...value, ...acceptedFiles]);
  //         }
  //       }else {
          
  //         if (!isImage && !isPdf) {
  //           enqueueSnackbar(
  //             'Allowed file types are: jpeg, jpg, png, and pdf.',
  //             { variant: 'error' }
  //           );
  //         } 
  //         else {
  //           // If valid, proceed with the change
  //           onChange([...value, ...acceptedFiles]);
  //         }
  //       }
  //     }

  //   },
  //   //   onChange([...value, ...acceptedFiles]);
  //   // },
  // });
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    noKeyboard: true,
    maxSize: 2000000, // 2MB limit
    onDrop: (acceptedFiles, fileRejections) => {
      console.log('Accepted Files:', acceptedFiles);
      console.log('File Rejections:', fileRejections);

      if (fileRejections.length > 0) {
        const errorMessage = fileRejections[0]?.errors[0]?.message.replace('2000000 bytes', '2 MB');
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        const fileType = acceptedFiles[0]?.type;
        const isImage = ['image/jpeg', 'image/png', 'image/jpg'].includes(fileType);
        const isPdf = fileType === 'application/pdf';

        if (listofdoc?.title === 'Passport Size Photo') {
          if (!isImage) {
            enqueueSnackbar('Only jpeg, jpg, and png formats are allowed for Passport Size Photo.', { variant: 'error' });
          } else {
            onChange([...value, ...acceptedFiles]);
          }
        } else {
          acceptedFiles.forEach((file) => {
            const maxFileSize = 2 * 1024 * 1024; // 2MB
            const fileSize = file.size;

            if (!isImage && !isPdf) {
              enqueueSnackbar('Allowed file types are: jpeg, jpg, png, and pdf.', { variant: 'error' });
            } else if (isPdf && fileSize > maxFileSize) {
              enqueueSnackbar('Only files up to 2 MB are allowed.', { variant: 'error' });
            } else {
              onChange([...value, ...acceptedFiles]);
            }
          });
        }
      }
    },
  });

  const classes = useStyles();

  return (
    <div
    className={clsx(classes.rowWrap, {
      // [classes.rowWrapConditional]: length === index + 1,
    })}
    style={{ marginBottom: length === index + 1 ? "100px" : "0px" }}
  >
      <Grid container {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Grid xs={12} md={8}>
          <Typography align="left" className={classes.rowLabel} variant="h5">
            {label}
            {required === true && <span style={{ color: '#E75547' }}>*</span>}
          </Typography>
          <Typography
            align="left"
            className={classes.rowDesc}
            variant="subtitle2"
          >
            {description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <KenUploadButton
            open={open}
            recordId={recordId}
            handleDownload={handleDownload}
            fileType={fileType}
            fileName={props?.fileName}
            onCapture={onCapture}
            onError={onError}
            config={config}
            listofdoc={listofdoc}
            showImgCapture={showImgCapture}
            setShowImgCapture={setShowImgCapture}
          />
        </Grid>
      </Grid>
    </div>
  );
}
