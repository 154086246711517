import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function KenCircularProgressWithLabel(props) {
  const { size, value, progressLabelClassName } = props;
  const [colorCode, setColorCode] = React.useState();

  useEffect(() => {
    if (value <= 49) {
      setColorCode("#FE7E6D"); //red
    }
    if (value === 100) {
      setColorCode("#1BBE75");
    }
    if (value >= 50 && value <= 99) {
      setColorCode("#FED56D");
    }
  }, [value]);

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#E3E3E3",
        }}
        value={100}
        size={size && size ? size : 40}
        thickness={4}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          /* color: (theme) =>
            theme.palette.mode === "light" ? "#FE7E6D" : "#308fe8", */
          color: colorCode,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size && size ? size : 40}
        thickness={4}
        value={value}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          className={progressLabelClassName}
          style={{
            fontSize: size && size ? `${Math.floor(size / 3)}px` : "12px",
          }}
          component="div"
          color="#505F79"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

KenCircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
