import { useTheme } from '@emotion/react';
import { Grid, Typography, Box, Divider, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect } from 'react';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { FormContext } from '../../Context/formContext';
import { getSplitName } from '../../Utils/Helpers/stringHelper';
import PrintIcon from '@mui/icons-material/Print';
import useReactToPrint from 'react-to-print';
import { useRef } from 'react';
import React from 'react';
import ReactToPrint from 'react-to-print/lib';
import { CheckBox } from '@mui/icons-material';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: 'calc(100% - 16px)',
    paddingTop: theme.spacing(7.125),
    overflow: 'auto',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingTop: theme.spacing(3),
    },
  },
  stepLabel: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    overflow: 'visible',
  },
  sectionLabel: {
    fontWeight: 400,
    overflow: 'visible',
  },
  sectionSubLabel: {
    color: '#7A869A',
    fontWeight: 400,
  },
  footerContainer: {
    position: 'absolute',
    bottom: '0px',
    // padding: '16px',
    // paddingRight: '223px',
    // minHeight: 76,
    // height: "100px",
    left: '0px',
    right: '0px',
    overflow: 'hidden',
    background: '#FFFFFF',
    boxShadow:
      '10px 0px 20px rgba(0, 0, 0, 0.04), 2px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    // backgroundColor: "blue",
  },
  container: {
    position: 'absolute',
    top: '270px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  paymentContainer: {
    padding: '16px 223px',
  },
  fieldNameValue: {
    wordBreak: 'break-word',
    fontWeight: 400,
    overflow: 'visible',
  },
  fieldName: {
    color: theme.palette.primary.main,
    overflow: 'visible',
  },
  fieldValue: {
    color: '#061938',
    overflow: 'visible',
  },
  fieldContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexDirection: 'column',
      'align-items': 'flex-start',
      'justify-content': 'flex-start',
      'max-width': '100%',
      display: 'flex',
      width: '100%',
      'padding-top': '0 !important',
      overflow: 'visible',
    },
    overflow: 'visible',
  },
  fieldKeyContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '40%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      //   'line-height': '150%',
      'text-transform': 'capitalize',
      color: '#7A869A',
      overflow: 'visible',
    },
    overflow: 'visible',
  },
  fieldValueContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '60%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      //   'line-height': '150%',
      color: '#061938',
      overflow: 'visible',
    },
    overflow: 'visible',
  },
  divider: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px auto 16px 0',
    },
  },
  dividerContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px',
    },
  },
  dividerWrapper: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '0px',
      paddingTop: '0 !important',
    },
  },

  imgProfile: {
    borderRadius: '4px',
    padding: '5px',
    width: '150px',
  },
  imgProfile1: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '5px',
    width: '150px',
  },
}));

const SummaryToPrint = React.forwardRef((props, ref) => {
  const { formJson, data, applicationState, url } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { formState, formDispatch } = useContext(FormContext);
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(props);

  const getNestedFieldValue = (fieldName, index, nestedFieldName) => {
    if (data) {
      if (data[fieldName]) {
        if (data[fieldName][index]) {
          if (data[fieldName][index][nestedFieldName]) {
            return data[fieldName][index][nestedFieldName];
          }
        }
      }
    }
  };

  const checkFormOptions = (value) => {
    let combinedValues = [];
    Object.values(applicationState.formOptions).map((el) => {
      if (Array.isArray(el)) {
        combinedValues.push(...el);
      }
    });
    if (combinedValues.some((el) => el.value === value)) {
      return combinedValues.find((el) => el.value === value).label;
    } else if (value === 'true' || value === 'false') {
      return value === 'true' ? 'Yes' : 'No';
    } else {
      return value;
    }
  };
  const getFieldValue = (fieldName) => {
    try {
      //convert names to executable to get value
      const name = 'data[' + fieldName + ']';
      const keysArray = getSplitName(name, '', true) || [];
      let str = '';
      keysArray.map((item, index) => {
        if (index === 0) {
          str = item;
        } else {
          if (isNaN(item)) {
            str = `${str}["${item}"] && ${str}["${item}_select"] || ${str}["${item}"] && ${str}["${item}"]`;
          } else {
            str = `${str}[${item}] && ${str}[${item}]`;
          }
        }
      });
      //   console.log('final str', str);
      // console.log("type::::", field.type, field);
      // if (field.type === "addMultiple") {
      //    return data['newApplicantRelations1'][0]['firstName']
      // }
      return checkFormOptions(eval(str)); //covert string to executable
    } catch {
      console.log('error in eval');
    }
  };

  const getNestedData = (name, structure) => {
    console.log(name, structure);
    const flatStructureData = structure.map((a) => {
      return { name: a?.name, label: a?.label };
    });
    let dataByName;
    if (/\d/.test(name)) {
      dataByName = getFieldValue(name);
    } else {
      dataByName = data[name] || [];
    }
    const allData = dataByName?.map((el) => {
      let obj = {};
      flatStructureData?.forEach((item) => {
        console.log(item.name);
        obj[item.label] =
          item.name.includes('semesterNumber') ||
          item.name.includes('yearNumber')
            ? el[item?.name]?.replace(/\D/g, '')
            : el[item?.name];
      });
      return obj;
    });
    return allData;
  };

  let today = new Date().toLocaleDateString();
  return (
    <div ref={ref}>
      <Box style={{ padding: '24px', margin: '24px' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <img
              // src={`https://inazstgpfs3001.blob.core.windows.net/assets/ANTTC-Log.png`}
              src={`https://inazstgpfs3001.blob.core.windows.net/assets/Images/LNT/Images/ltpct-2.png`}
              className={classes.imgProfile}
            />
          </Grid>
          <Grid item xs={4}>
            {' '}
            <Typography
              align="Center"
              marginBottom={isMobileScreen ? 0 : theme.spacing(4)}
              className={classes.stepLabel}
              fontSize="20px"
            >
              Application Form
            </Typography>
            <Typography
              align="Center"
              marginBottom={isMobileScreen ? 0 : theme.spacing(4)}
              className={classes.stepLabel}
              fontSize="20px"
              fontWeight="bold"
            >
              {props.data.application.programName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <img src={url} className={classes.imgProfile1} />
            </Box>
          </Grid>
        </Grid>

        <Box pt={1}>
          {formJson?.map((step) => {
            return (
              <>
                <Grid container>
                  {step?.sections?.map((section, index) => {
                    return (
                      <>
                        {section.sectionVisible === true && (
                          <>
                            <Grid item xs={12}>
                              <Box>
                                {section?.sectionName && (
                                  <Typography
                                    align="left"
                                    marginBottom={
                                      isMobileScreen
                                        ? theme.spacing(1)
                                        : theme.spacing(1)
                                    }
                                    className={classes.sectionLabel}
                                    fontSize="18px"
                                  >
                                    {section?.sectionName}
                                  </Typography>
                                )}
                                {section?.subSectionName && (
                                  <Typography
                                    align="left"
                                    marginBottom={
                                      isMobileScreen
                                        ? theme.spacing(1)
                                        : theme.spacing(4)
                                    }
                                    className={classes.sectionSubLabel}
                                    // variant="body2"
                                    fontSize="12px"
                                  >
                                    {section?.subSectionName}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>

                            {/* <Box key={section.sectionId}> */}
                            {/* <Grid container spacing={6} rowSpacing={4}> */}
                            {section.fields.map((field, fieldIndex) => {
                              return field?.metaData?.addMultiple === true ? (
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  md={12}
                                  spacing={1}
                                >
                                  <>
                                    {getNestedData(
                                      field.name,
                                      field.fieldsArray
                                    )?.map((nestedField, nestedFieldIndex) => {
                                      return (
                                        nestedField &&
                                        Object.keys(nestedField).map(
                                          (keyName, keyIndex) => {
                                            console.log(
                                              keyName,
                                              nestedField[keyName],
                                              nestedField
                                            );
                                            if (
                                              nestedField[keyName] !== null &&
                                              keyName !== 'undefined'
                                            )
                                              return (
                                                <>
                                                  <Grid
                                                    item
                                                    container
                                                    xs={6}
                                                    md={6}
                                                    spacing={1}
                                                    className={
                                                      classes.fieldContainer
                                                    }
                                                  >
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={6}
                                                      textAlign="left"
                                                      className={
                                                        classes.fieldKeyContainer
                                                      }
                                                    >
                                                      <Typography
                                                        className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                                        variant="h5"
                                                        fontSize="12px"
                                                      >
                                                        {keyName}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={6}
                                                      textAlign="left"
                                                      className={
                                                        classes.fieldValueContainer
                                                      }
                                                    >
                                                      <Typography
                                                        className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                                        variant="h5"
                                                        fontSize="12px"
                                                      >
                                                        {nestedField[keyName]}
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                  {Object.keys(nestedField)
                                                    ?.length -
                                                    1 ===
                                                    keyIndex &&
                                                    getNestedData(
                                                      field.name,
                                                      field.fieldsArray
                                                    )?.length -
                                                      1 !==
                                                      nestedFieldIndex && (
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                          classes.dividerContainer
                                                        }
                                                      >
                                                        <Divider
                                                          className={
                                                            classes.divider
                                                          }
                                                        />
                                                      </Grid>
                                                    )}
                                                </>
                                              );
                                          }
                                        )
                                      );
                                    })}
                                  </>
                                  {/* </Typography> */}
                                  {/* </Grid> */}
                                </Grid>
                              ) : field?.fieldId ? (
                                <>
                                  {getFieldValue(field.name) && (
                                    <Grid
                                      item
                                      container
                                      xs={6}
                                      md={6}
                                      spacing={1}
                                      className={classes.fieldContainer}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        textAlign="left"
                                        className={classes.fieldKeyContainer}
                                      >
                                        <Typography
                                          className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                          variant="h5"
                                          fontSize="12px"
                                        >
                                          {field?.label}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        textAlign="left"
                                        className={classes.fieldValueContainer}
                                      >
                                        <Typography
                                          className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                          variant="h5"
                                          fontSize="12px"
                                        >
                                          {field && getFieldValue(field.name)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {field?.ui?.divider && (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      className={classes.dividerContainer}
                                    >
                                      <Divider className={classes.divider} />
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <>
                                  {field?.options.map((el) => {
                                    return (
                                      <>
                                        {getFieldValue(
                                          `${field.name}${el.fieldId}`
                                        ) && (
                                          <Grid
                                            item
                                            container
                                            xs={12}
                                            md={6}
                                            spacing={1}
                                            className={classes.fieldContainer}
                                          >
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={6}
                                              textAlign="left"
                                              className={
                                                classes.fieldKeyContainer
                                              }
                                            >
                                              <Typography
                                                className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                                variant="h5"
                                                fontSize="12px"
                                              >
                                                {el?.label}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={6}
                                              textAlign="left"
                                              className={
                                                classes.fieldValueContainer
                                              }
                                            >
                                              <Typography
                                                className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                                variant="h5"
                                                fontSize="12px"
                                              >
                                                {el &&
                                                  getFieldValue(
                                                    `${field.name}${el.fieldId}`
                                                  )}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        )}
                                        {field?.ui?.divider && (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            className={classes.dividerContainer}
                                          >
                                            <Divider
                                              className={classes.divider}
                                            />
                                          </Grid>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                            {/* </Grid> */}
                            {/* {step?.sections?.length - 1 !== index && ( */}
                            <Grid
                              item
                              xs={12}
                              className={classes.dividerWrapper}
                            >
                              <Box
                                mt={2.5}
                                mb={2.5}
                                className={classes.dividerContainer}
                              >
                                <Divider className={classes.divider} />
                              </Box>
                            </Grid>
                            {/* )} */}
                            {/* </Box> */}
                          </>
                        )}
                      </>
                    );
                  })}
                </Grid>
              </>
            );
          })}
        </Box>
        {applicationState?.applicationData?.attachments && (
          <div>
            <Typography
              align="left"
              marginBottom={
                isMobileScreen ? theme.spacing(1) : theme.spacing(2)
              }
              className={classes.sectionLabel}
              fontSize="18px"
            >
              DOCUMENT UPLOADED
            </Typography>
            <Grid
              container
              xs={12}
              md={12}
              spacing={1}
              className={classes.fieldContainer}
            >
              {applicationState?.applicationData?.attachments?.map((el) => {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    spacing={1}
                    className={classes.fieldContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={1}
                      textAlign="left"
                      className={classes.fieldValueContainer}
                    >
                      <CheckBox />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={11}
                      textAlign="left"
                      className={classes.fieldKeyContainer}
                    >
                      <Typography
                        className={`${classes.fieldName} ${classes.fieldNameValue}`}
                        variant="h5"
                        fontSize="18px"
                      >
                        {el?.title}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
      </Box>
      <Grid item md={12}>
        <Typography
          marginBottom={isMobileScreen ? 0 : theme.spacing(2)}
          className={classes.stepLabel}
          fontSize="18px"
          marginLeft="45px"
        >
          Declaration
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          <Box
            style={{
              marginBottom: '100px',
              marginTop: '17px',
              marginLeft: '45px',
              marginRight: '45px',
            }}
          >
            <Grid item md={12} mb={3}>
            <Typography align="left" sx={{ marginLeft: '0px' }}>
                The student hereby undertakes that they have read and understood
                the full requirements, eligibility criteria, terms and
                conditions, and other important information in the Prospectus,
                and confirms that the information provided in this registration
                form is true to the best of their knowledge, understanding that
                any false or misleading statements may result in the
                cancellation of their admission or expulsion from the course at
                any stage.
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
});

export default SummaryToPrint;
