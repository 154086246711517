import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { fi } from 'date-fns/locale';
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KenAccordion from '../../Components/KenAccordion';
import KenLoader from '../../Components/KenLoader';
import { AuthContext } from '../../Context/authContext';
import { getDocuments, createDocuments } from '../../Utils/apiServices';
import DocumentList from './Components/DocumentList';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  title: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '18px',
    'line-height': '100%',
    'letter-spacing': '-0.02em',
    'text-transform': 'uppercase',
    color: theme.palette.KenColors.dark,
    display: 'flex',
    position: 'relative',

    '& > .underline': {
      position: 'absolute',
      width: '55px',
      height: '4px',
      top: '24px',
      background: theme.palette.KenColors.statusYellow,
    },
  },
  description: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      background: '#F7F8FB',
      width: '100%',
      padding: '27px 27px 10px 27px !important',
    },
  },
  documentTitle: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '22px',
      'line-height': '100%',
      color: theme.palette.KenColors.neutral900,
    },
  },
  documentSubTitle: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '14px',
      'line-height': '150%',
      color: theme.palette.KenColors.neutral900,
    },
  },
}));

export default function DocumentPage() {
  const [uploadData, setUploadData] = useState();
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const {
    state: { user },
  } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  useEffect(() => {
    setLoader(true);
    getDocuments(user?.applicantId)
      .then((res) => {
        const data = [];
        if (res?.data) {
          res?.data?.forEach((el) => {
            const checkList = el?.applicationCheckList?.split(';');
            const documents = [];
            const applicationStatus =
              el?.applicationStatus === 'Shortlisted for Interview' ||
              el?.applicationStatus === 'Interview score obtained' ||
              el?.applicationStatus === 'Selection Status' ||
              el?.applicationStatus === 'Document Verification' ||
              el?.applicationStatus === 'Main Offer Letter' ||
              el?.applicationStatus === 'Registration Fee Status' ||
              el?.applicationStatus === 'Application Evaluation in process';
            if (el?.documents?.length > 0 && Array.isArray(checkList)) {
              checkList?.map((elem) => {
                const index = el?.documents?.findIndex(
                  (data) =>
                    data.title === elem &&
                    (data.status === 'Accepted' ||
                      data.status === null ||
                      data.status === 'Rejected')
                );
                if (index >= 0) {
                  if (el?.documents?.body !== null) {
                    documents.push({
                      title: el?.documents[index].title,
                      fileName: el?.documents[index].fileName,
                      previewImage: `data:${el?.documents[index]?.fileType};base64,${el?.documents[index].body}`,
                      applicationId: el?.applicationId,
                      status:
                        el?.documents[index]?.status === null ||
                        el?.documents[index]?.status === 'Replaced'
                          ? 'Pending Verification'
                          : el?.documents[index]?.status,
                      applicationName: el?.applicationName,
                      applicationProgramName: el?.applicationProgramName,
                    });
                  }
                } else {
                  documents.push({
                    title: elem,
                    fileName: null,
                    previewImage: null,
                    applicationId: el?.applicationId,
                    applicationName: el?.applicationName,
                    status: null,
                    applicationProgramName: el?.applicationProgramName,
                  });
                }
              });
            } else if (Array.isArray(checkList)) {
              checkList?.forEach((elem) => {
                documents.push({
                  title: elem,
                  fileName: null,
                  previewImage: null,
                  applicationId: el?.applicationId,
                  applicationName: el?.applicationName,
                  status: null,
                  applicationProgramName: el?.applicationProgramName,
                });
              });
            }
            applicationStatus &&
              data.push({
                label: el?.applicationProgramName,
                applicationId: el?.applicationId,
                documents: [...documents],
                applicationName: el?.applicationName,
              });
          });
        }
        setLoader(false);
        setUploadData(data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []);

  const handleFileChange = (file, elem) => {
    const uploadDataCopy = [...uploadData];
    const index = uploadDataCopy.findIndex(
      (data) => data.applicationId === elem.applicationId
    );
    const docIndex = uploadDataCopy[index].documents.findIndex(
      (el) => el.title === elem.title
    );
    uploadDataCopy[index].documents[docIndex].previewImage = file;
    uploadDataCopy[index].documents[docIndex].fileName = file.name;

    const reader = new FileReader();
    let base64;
    reader.onloadend = () => {
      base64 = reader.result.replace('data:', '').replace(/^.+,/, '');
      const myArray = file.name.split('.');
      const body = {
        fileName: file.name,
        title: elem.title,
        formattedDocumentName: `${elem?.applicationProgramName}_${
          elem?.applicationName
        }_${user?.applicantName}.${myArray[myArray.length - 1]}`,
        expiryDate: null,
        body: base64,
        applicationId: elem.applicationId,
        fileType: file.type,
      };
      createDocuments({ attachments: [body] })
        .then((res) => {
          if (res?.success === true) {
            return enqueueSnackbar('Document Uploaded Successfully', {
              variant: 'success',
            });
          }
        })
        .catch((err) => {
          return enqueueSnackbar(
            'Error while uploading. Please try again later.',
            {
              variant: 'error',
            }
          );
        });
    };
    reader.readAsDataURL(file);
    setUploadData([...uploadDataCopy]);
  };

  return (
    <>
      {loader && <KenLoader />}
      <Box
        sx={{
          m: isMobileScreen ? 0 : 2,
          width: isMobileScreen ? '100%' : '80%',
        }}
      >
        <Grid container direction="column" className={classes.description}>
          <Grid item mb={2}>
            <Typography
              variant="body8"
              sx={{ float: 'left' }}
              className={classes.title}
            >
              {t('headings:My_Documents')}
              <span className="underline"></span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{ textAlign: 'left', mb: 2 }}
              className={classes.documentSubTitle}
            >
              {t('messages:My_document_description')}
            </Typography>
          </Grid>
        </Grid>
        {uploadData &&
          uploadData.map((el) => {
            return (
              <KenAccordion label={`${el?.label} Documents`}>
                {el?.documents.length > 0 ? (
                  el?.documents.map((elem, index) => {
                    return (
                      <Box mb={2}>
                        <DocumentList
                          onChange={(files) => {
                            handleFileChange(files[0], elem);
                          }}
                          title={`${index + 1}. ${elem?.title}`}
                          fileName={elem?.fileName}
                          previewImage={elem?.previewImage}
                          status={elem?.status}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Typography>No Documents Required</Typography>
                )}
              </KenAccordion>
            );
          })}
      </Box>
    </>
  );
}
