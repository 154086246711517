import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GeneralButton from '../../../../Components/Button';
import Carousel from 'nuka-carousel';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/ChevronRight';
import { getNotifications } from '../../../../Utils/apiServices';
import { AuthContext } from '../../../../Context/authContext';
import { useWindowWidth } from '../../../../Utils/Helpers/widthHelper';
import { useTheme } from '@emotion/react';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';
import { activeTheme } from '../../../../Utils/Themes/themeHelper';

const useStyles = makeStyles((theme) => ({
  carousel: {
    borderRadius: '16px',
    'box-shadow':
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      borderRadius: 0,
      boxShadow: 'unset',
    },
  },
  container: {
    padding: theme.spacing(3),
    background: '#F8F8FB',
    borderRadius: '16px',
    'box-shadow':
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      borderRadius: 0,
      minHeight: '210px',
      boxShadow: 'unset',
    },
  },
  heading: {
    fontSize: 28,
    color: theme.palette.KenColors.dark,
    fontWeight: 500,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '22px',
      'line-height': '100%',
      marginBottom: theme.spacing(1),
    },
  },
  programName: {
    fontSize: 12,
    color: theme.palette.KenColors.dark,
    fontWeight: 500,
  },
  bckBtn: {
    color: '#fff',
    backgroundColor: activeTheme.buttonPrimary.btnColor,
    borderRadius: '32px',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    width: 180,
    height: 40,
    '&:hover': {
      cursor: 'pointer',
      background: '#DFE8FF',
      color: '#505F79',
    },
    padding: theme.spacing(1.5),
    fontSize: 14,
  },
  helperText: {
    'font-family': 'Poppins',
    'font-style': 'normal',
    'line-height': '150%',
    color: theme.palette.KenColors.dark,
  },
  nullProgramName: {
    height: 16,
  },
}));

export default function DescriptionBox(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const {
    state: { user },
  } = useContext(AuthContext);
  const theme = useTheme();
  const screenSizeSm = useMediaQuery(theme.breakpoints.only('sm'));
  const screenSizeSx = useMediaQuery(theme.breakpoints.only('xs'));
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const handleApply = (redirection) => {
    navigate(redirection);
  };
  const width = useWindowWidth();
  const [style, setStyle] = useState();

  useEffect(() => {
    if (screenSizeSm) {
      setStyle({
        width: width - 180,
      });
    } else if (screenSizeSx) {
      setStyle({
        width: width,
      });
    }
  }, [width]);

  useEffect(() => {
    getNotifications(user?.applicantId).then((res) => {
      if (res?.data) {
        const data = [];
        res?.data?.map((el) => {
          if (el?.applicationId !== null) {
            data.push(el);
          } else if (
            el?.applicationId === null &&
            el?.operation === 'Default'
          ) {
            data.push(el);
          }
        });
        setData([...data]);
      }
    });
  }, []);

  return (
    <Grid item md={12} sx={style} width={isMobileScreen ? '100%' : undefined}>
      <Carousel
        autoplay={data?.length <= 1 ? false : true}
        wrapAround={true}
        renderCenterRightControls={({ nextSlide }) => ''}
        renderCenterLeftControls={({ previousSlide }) => ''}
        withoutControls={data?.length <= 1 ? true : false}
        className={classes.carousel}
      >
        {data?.map((el) => {
          return (
            <div>
              <Grid
                container
                className={classes.container}
                direction="column"
                alignItems="self-start"
              >
                <Grid item md={12}>
                  <Typography
                    className={
                      el?.applicationProgramName !== null
                        ? classes.programName
                        : classes.nullProgramName
                    }
                  >
                    {el?.applicationProgramName || ' '}
                  </Typography>
                </Grid>
                <Grid item md={12} sx={{ marginBottom: theme.spacing(1) }}>
                  <Typography className={classes.heading}>
                    {el?.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                  spacing={2}
                  md={12}
                >
                  <Grid item>
                    <Typography variant="body5" className={classes.helperText}>
                      {el.message}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.bckBtn}
                      onClick={() => {
                        handleApply(el?.redirectionIdentifier);
                      }}
                    >
                      {el?.buttonLabel || 'Apply Now'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          );
        })}
      </Carousel>
    </Grid>
  );
}
