import { Autocomplete, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.palette.KenColors.kenWhite,
    justifyContent: "center",
    alignItems: "center",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 12,
    border: `1px solid ${theme.palette.KenColors.innerBorder}`,
    outline: `1px solid ${theme.palette.KenColors.innerBorder}`,
  },
  input: {
    border: "none",
    fontSize: 14,
    width: "100%",
    "&:focus": {
      border: "none",
      outline: "none",
    },
    backgroundColor: theme.palette.KenColors.kenWhite,

    '&::placeholder': {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': theme.spacing(2),
      'line-height': '100%',
      color: theme.palette.KenColors.grey4,
    }
  },
  iconWrapper: {
    display: 'flex'
  },
  icon: {
    color: theme.palette.KenColors.grey4,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function KenAutoComplete(props) {
  const classes = useStyles();
  const {
    options,
    placeholder,
    setData,
    searchValue = "",
    setSearchValue,
    handleSearch,
  } = props;


  return (
    <Autocomplete
      id='combo-box-demo'
      options={options}
      open={false} // search options will not open
      getOptionLabel={(option) => option.label || option.ContactName}
      onChange={(event, value) => {
        setData(value);
      }}
      clearOnBlur={true}
      onClose={() => {
        setTimeout(() => {
          setSearchValue("");
        }, [1000]);
      }}
      disableClearable={true}
      onInputChange={(e, value) => {
        setData();
        setSearchValue(value);
      }}
      renderInput={(params) => (
        <Box ref={params.InputProps.ref} className={classes.root}>
          <span className={classes.iconWrapper}>
            <SearchIcon
              {...params.inputProps}
              className={classes.icon}
              onClick={handleSearch}
            />
          </span>
          <input
            type='text'
            {...params.inputProps}
            className={classes.input}
            placeholder={placeholder}
            value={searchValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
                setData();
                setSearchValue("");
              }
            }}
          />

        </Box>
      )}
    />
  );
}
