import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { TABLET_BREAKPOINT } from '../../../../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFEBA1',
    borderRadius: '12px 12px 0px 0px',
    height: '16px',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      background: '#F6C445',
    }
  },
}));

export default function CardHeader() {
  const classes = useStyles();
  return <Box className={classes.container}></Box>;
}
