export const KEY_ACCESS_TOKEN = 'Access_Token';
export const KEY_CONFIG_URL = 'https://config-api.ken42.com/v1/configs/url';
export const REGEX = {
  // NAME: /^[a-zA-Z ]*$/,
   NAME: /^[A-Z\s]+$/,
   NAME_WITH_SPECIAL_CHAR: /^[A-Z\s\W0-9]+$/,
  MOBILE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  PINCODE: /^[0-9]{6,6}$/,
  NUMBER: /[^0-9]/,
  // aadharRegex : /^\d{12}$/,
  ONLYNUMBER: /^[0-9]*$/,
  NUMBERWITHDECIMAL: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
  DIGITS_ONLY_UPTO_100:
    /^\-?(([0-9]\d?|0\d{1,2})((\.)\d{0,2})?|100((\.)0{1,2})?)$/,
  DIGITS_ONLY_UPTO_10: /^\-?((0\d|\d)?((\.)\d{0,2})?|10((\.)0{1,2})?)$/,
  DIGITS_ONLY_UPTO_4:
    /^\-?((0[0-3]|[0-3])?((\.)\d{0,2})?|(4|04)((\.)0{1,2})?)$/,
  PERCENTAGE: /^100$/,
  CGPA: /^10$/,
  GRADE: /^[a-fA-FOoPp]$/,
  // MIXED: /^[a-zA-Z0-9-./, ]*$/,
  MIXED: /^[A-Z0-9-./,  ]*$/,
  PARENT_DOC: /^aadhaarParent/,
  PHONE_NUMBER: /^[0-9]*$/,
  AADHAAR: '^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$',
  IFSC : /^[A-Z]{4}[0][A-Z0-9]{6}$/,
  PINCODE : /^[1-9][0-9]{5}$/,


  EMAIL:
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  //   EMAIL:
  //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const TABLET_BREAKPOINT = 'tablet';
