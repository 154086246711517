import { axiosInstance } from '../../Utils/apiServices';

/**
 * Send/Resend OTP to email or mobile
 * @param {{id: string}} payload will have either mobile or email
 */
export const requestForOTP = async (payload) => {
  const path = `/salesforce?endpoint=/services/apexrest/otp/send&contact=91${payload.id}`;

  return axiosInstance
    .request({ url: `${encodeURI(path)}`, method: 'post' })
    .then((res) => res.data);
};

/**
 * Verify OTP for user login or signup
 * @param {{id: string, otp: string}} payload will verify otp for email or mobile
 */
export const verifyOTP = async (payload) => {
  const path = `/salesforce?endpoint=/services/apexrest/otp/verify&contact=91${payload.id}&otpentered=${payload.otp}`;

  return axiosInstance
    .request({ url: `${path}`, method: 'get' })
    .then((res) => res.data);
};

/**
 * User Signup Request
 * @param {*} payload
 */
export const signUp = async (payload) => {
  const path = `/salesforce?endpoint=/services/apexrest/portal/save`;

  return axiosInstance
    .request({
      url: `${path}`,
      method: 'post',
      data: {
        applicant: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          middleName: payload.middleName,
          email: payload.email,
          phone: payload.phone,
          accountID: '0015j00000hZClLAAW',
          // date: payload.date,
          // program: payload.program,
        },
      },
    })
    .then((res) => res.data);
};
