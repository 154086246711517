import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import KenColors from "./Themes/kenColors";

const theme = createTheme({
  //v5.0.0
  palette: {
    KenColors: { ...KenColors },
    primary: {
      light: "#757ce8",
      main: "#092682",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    error: {
      main: "#E75547",
    },
    success: {
      main: "#1BBE75",
    },
    card: {
      backgroundColor: "#F7F8FB",
      hover: "#EDF2FF",
    },
    labels: {
      main: "#061938",
      light: "#7A869A",
      medium: "#505F79",
    },
  },
  typography: {
    fontFamily: `"Poppins", "sans-serif"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    body1: {
      fontSize: [16, "!important"],
    },
    body2: {
      fontSize: 18,
      fontWeight: 500,
    },
    body3: {
      fontSize: 14,
      fontWeight: 600,
    },
    body4: {
      fontSize: 14,
    },
    body5: {
      fontSize: 16,
      fontWeight: 600,
    },
    body6: {
      fontSize: 16,
    },
    body7: {
      fontSize: 18,
    },
    body8: {
      fontSize: 18,
      fontWeight: 600,
    },
    body9: {
      fontSize: 24,
      fontWeight: 600,
    },

    caption: {
      fontSize: [14, "!important"],
    },
    caption1: {
      fontSize: [12, "!important"],
    },

    // ------------------------------
    subtitle1: {
      fontSize: "12px",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "14px",
    },
    subtitle3: {
      fontSize: "20px",
    },
    subtitle4: {
      fontSize: "28px",
    },
    h1: {
      fontSize: "48px",
    },
    h2: {
      fontSize: "40px",
    },
    h3: {
      fontSize: "32px",
    },
    h4: {
      fontSize: "24px",
    },
    h5: {
      fontSize: "16px",
    },
    h6: {
      fontSize: "8px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tablet: 769,
    },
  },
});

export default function GlobalThemeProvider(props) {
  const { children } = props;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
