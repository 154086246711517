import { Box, Divider, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useEffect, useCallback, useMemo } from 'react';
import KenDocumentUpload from '../KenDocumentUpload';
import KenStepperFooter from '../KenApplicationForm/Components/KenStepperFooter/index';
import { makeStyles } from '@mui/styles';
import {
  createDocuments,
  createUpdateApplication,
  getApplicationByApplicationId,
  getDocumentByDocId,
} from '../../Utils/apiServices';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import KenLoader from '../KenLoader';
import KenUploadButton from '../KenDocumentUpload/Components/KenUploadButton';
import FilePreview from '../KenDocumentUpload/Components/KenFilePreview';
import KenDialog from '../../Components/KenDialog';

const useStyles = makeStyles(() => ({
  title: {
    color: '#092682',
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: 32,
    fontSize: 20,
  },
}));

export default function DocumentUpload(props) {
  const styles = useStyles();
  const { applicationState, applicationDispatch } = props;
  const [appState, setAppState] = useState([applicationState]);
  // const [recordId, setRecordId] = useState([applicationState?.applicationData?.attachments?.recordId]);
  const [pageLoader, setPageLoader] = useState(false);
  const [url, setUrl] = useState();
  const [uploadData, setUploadData] = useState();
  const [filePreview, setFilePreview] = useState(false);
  const [file, setFile] = useState();
  // const {
  //   state: { user },
  // } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [checkList, setCheckList] = useState([]);

  console.log('applicationState', applicationState);
  const convertBase64ToFile = (base64String, fileName, fileType) => {
    // let arr = base64String.split(',');
    let mime = fileType;
    let bstr = atob(base64String);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: fileType });
    return file;
  };

  useEffect(() => {
    const documents = [];
    const data = [];

    if (applicationState?.form?.DocumentChecklist) {
      const appStates = applicationState?.form?.DocumentChecklist?.map(
        (elem) => {
          let required = false;
          console.log('eele', elem);
          if (
            elem?.documentType === 'Passport Size Photo' ||
            elem?.documentType === 'Aadhar Card' ||
            elem?.documentType === 'Ration Card' ||
            elem?.documentType === 'Caste Certificate' ||
            elem?.documentType === 'Income Certificate' ||
            elem?.documentType === 'School leaving Certificate' ||
            elem?.documentType === 'Bank passbook' ||
            elem?.documentType === 'Mark Sheet' ||
            elem?.documentType === 'Any other Document'
          ) {
            required = true;
          }
          
          if (['Any other Document'].includes(elem?.documentType)) {
            required = false;
          }
          if(['Passport Size Photo','Aadhar Card','Ration Card','Caste Certificate','School leaving Certificate','Mark Sheet','Bank passbook','Any other Document']){

          }
          // console.log(
          //   'test',
          //   elem?.documentType === 'Passport Size Photo' ||
          //     elem?.documentType === 'Ration Card' ||
          //     elem?.documentType === 'Aadhar Card' ||
          //     elem?.documentType === 'Electricity Bill' ||
          //     elem?.documentType === 'Bank passbook' ||
          //     elem?.documentType === 'School leaving Certificate' ||
          //     elem?.documentType === 'Any other Document' ||
          //     elem?.documentType === 'Marriage Certificate'
          // );
          return {
            title: elem?.documentType,
            previewImage: null,
            contentType: elem?.documentType === 'Passport Size Photo' ||
            elem?.documentType === 'Aadhar Card' ||
            elem?.documentType === 'Ration Card' ||
            elem?.documentType === 'Electricity Bill' ||
            elem?.documentType === 'Marriage Certificate' ||
            elem?.documentType === 'Income Certificate' ||
            elem?.documentType === 'Caste Certificate' ||
            elem?.documentType === 'School leaving Certificate' ||
            elem?.documentType === 'Bank Passbook' ||
            elem?.documentType === 'Mark Sheet' ||
            elem?.documentType === 'Any other Document'|| [
                'image/png',
                'image/jpeg',
                'image/jpg',
                'application/pdf'
              ],

            // description:
            //   // elem?.documentType === 'Passport Size Photo' ||
            //   // elem?.documentType === 'Ration Card' ||
            //   // elem?.documentType === 'Aadhar Card' ||
            //   // elem?.documentType === 'Electricity Bill' ||
            //   // elem?.documentType === 'Bank passbook' ||
            //   // elem?.documentType === 'School leaving Certificate' ||
            //   // elem?.documentType === 'Any other Document' ||
            //   // elem?.documentType === 'Marriage Certificate'
            //   'JPG, JPEG, PNG , PDF Maximum file size: 2 MB for each document.',

            // required: required,
            description: elem?.documentType === 'Passport Size Photo' 

            ? <>Supported file types : JPG, JPEG & PNG<br/>Maximum file size: 2 MB </> 
            : <>Supported file types : JPG, JPEG ,PNG &PDF<br/>Maximum file size: 2 MB </>,
             required: required

          };
        }
      );
      // const sortedAppStates = appStates.sort((a, b) => {
      //   if (a.title === 'Any other Document') return 1;
      //   if (b.title === 'Any other Document') return -1;
      //   return 0;
      // });
      const documentOrder = [
        'Passport Size Photo',
        'Aadhar Card',
        'Ration Card',
        'Caste Certificate',
        'Income Certificate',
        'School leaving Certificate',
        'Mark Sheet',
        'Bank passbook',
        'Any other Document'
      ];

      // const sortedAppStates = appStates.sort((a, b) => {
      //   if (a.title === 'Any other Document') return 1;
      //   if (b.title === 'Any other Document') return -1;
      //   return 0;
      // });

      
      // sortedAppStates for arranging document orderwise
      const sortedAppStates = appStates.sort((a, b) => {
        const indexA = documentOrder.indexOf(a.title);
        const indexB = documentOrder.indexOf(b.title);
        return indexA - indexB;
      });
      

      setCheckList([...appStates]);
      console.log('appStates', appStates);
      setUploadData(data);
      getData(appStates);
    }
  }, [applicationState?.form]);

  useEffect(() => {
    if (!(file instanceof File)) {
      setUrl(file);
    }
  }, [file]);

  // useEffect(() => {
  //   getData();
  // }, []);

  const getData = (appStates) => {
    // console.log("*****",checkList);
    const uploadDataCopy = appStates || checkList;

    if (uploadDataCopy.length > 0) {
      setPageLoader(true);
      getApplicationByApplicationId(
        // applicationState?.applicationData?.application?.recordId
        applicationState?.applicationId
      )
        .then((resp) => {
          setPageLoader(true);
          if (resp?.success && resp?.data) {
            resp?.data?.attachments?.forEach((el) => {
              let index = uploadDataCopy.findIndex(
                (elem) => elem.title === el.title
              );
              if (index > -1) {
                uploadDataCopy[index] = { ...uploadDataCopy[index], ...el };
              }
            });
            setCheckList([...uploadDataCopy]);

            setPageLoader(false);
            // console.log("uploadDataCopy:",uploadDataCopy);
            // console.log("*******:checkList",checkList);
          }
        })
        .catch((err) => {
          console.log(err);
          setPageLoader(false);
        });
    }
  };

  const onError = (err) => console.log('Error:', err);

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };
  const handleNext = () => {
    console.log('handleChk', checkList);
    let flag = [];
    checkList.forEach((element) => {
      if (element?.required === true && element?.recordId) {
        flag.push(true);
      } else if (element?.required === true && !element?.recordId) {
        flag.push(false);
      }
    });
    if (flag.every((element) => element === true)) {
      let data = { ...applicationState?.applicationData?.application };
      // data.applicationStatus = 'Application form Submitted';
      // data.applicationStatus = 'Application Form Complete';
      // // data.enableDocumentUpload = true;
      // data.currentStep = 11;

      createUpdateApplication({
        application: {
          ...applicationState?.applicationData?.application,
          currentStep: applicationState?.activeStep + 1,
        },
      })
        .then((res) => {
          applicationDispatch({
            type: 'update',
            payload: { applicationData: res.data },
          });
        })
        .catch((err) => {
          console.log(err);
        });
      applicationDispatch({ type: 'nextStep' });
    } else {
      return enqueueSnackbar('Upload mandatory documents', {
        variant: 'info',
      });
    }
  };

  const handleFileDelete = (el) => {
    console.log('eless', el);
    // const index = state.newlyAddedFiles?.findIndex(
    //   (item) => item.name === name
    // );
    // dispatch({
    //   type: 'delete',
    //   payload: index,
    // });
  };

  // console.log('docprops', applicationState);

  // const handleUploaded = (id, file , fileType) => {
  //   setPageLoader(true);

  //   getDocumentByDocId(id)
  //     .then((res) => {
  //       debugger
  //       console.log("responss", res);
  //       if (res?.success && res?.data) {
  //         setFilePreview(true);
  //         setFile(`data:${fileType};base64,${res?.data}`);
  //         setPageLoader(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setPageLoader(false);
  //     });

  // };

  const handleDownload = (id, fileType, fileName) => {
    setPageLoader(true);
    getDocumentByDocId(id)
      .then((res) => {
        if (res?.success && res?.data) {
          // setFilePreview(true);
          // setFile(`data:${fileType};base64,${res?.data}`);
          setPageLoader(false);
          let file = convertBase64ToFile(res?.data, fileName, fileType);
          saveAs(file, fileName);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoader(false);
      });
  };
  const ALLOWED_EXTENSIONS = ['jpeg', 'jpg', 'png','pdf'];
 
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

  const uploadDoc = (file, el) => {
    setPageLoader(true);
  
    if (file.size > MAX_FILE_SIZE) {
      console.log('File size exceeds the limit');
      enqueueSnackbar('File size must be less than 2MB.', {
        variant: 'error',
      });
      setPageLoader(false);
      return;
    }
    
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (
      !ALLOWED_EXTENSIONS.includes(fileExtension) &&
      fileExtension !== 'pdf'
    ) {
      enqueueSnackbar('Allowed file types are: jpeg, jpg, png, pdf.', {
        variant: 'error',
      });
      setPageLoader(false);
      return;
    }
    const isDuplicate = checkList.some(
      (element) => element.fileName === file.name
    );
    if (isDuplicate) {
      enqueueSnackbar(
        'This file has already been uploaded under another field.',
        {
          variant: 'error',
        }
      );
      setPageLoader(false);
      return;
    }

    const reader = new FileReader();
    let base64;
    reader.onloadend = () => {
      base64 = reader.result.replace('data:', '').replace(/^.+,/, '');

      const body = {
        fileName: file?.name,
        title: el.title,
        expiryDate: null,
        body: base64,
        applicationId: applicationState?.applicationId,
        fileType: file.type,
      };

      // Determine if the document is valid for upload
      let flag = false;

      // Check for allowed titles and corresponding file types
      if (
        (body?.title === 'Passport Size Photo' ||
          body?.title === 'Aadhar Card' ||
          body?.title === 'Ration Card' ||
          body?.title === 'Electricity Bill' ||
          body?.title === 'Marriage Certificate' ||
          body?.title === 'Caste Certificate' ||
          body?.title === 'Income Certificate' ||
          body?.title === 'School leaving Certificate' ||
          body?.title === 'Bank passbook' ||
          body?.title === 'Mark Sheet' ||
         // body?.title === 'Marriage Certificate' ||
          body?.title === 'Any other Document') &&
        ALLOWED_EXTENSIONS.includes(fileExtension)
      ) {
        flag = true;
      } else if (
        body?.title !== 'Passport Size Photo' &&
        body?.title !== 'Aadhar Card' &&
        body?.title !== 'Ration Card' &&
        body?.title !== 'Marriage Certificate' &&
        body?.title !== 'Electricity Bill' &&
        body?.title !== 'Caste Certificate' &&
        body?.title !== 'Income Certificate' &&
        body?.title !== 'School leaving Certificate' &&
        body?.title !== 'Bank passbook' &&
        body?.title !== 'Mark Sheet' &&
       // body?.title !== 'Marriage Certificate' &&
        body?.title !== 'Any other Document' &&
        fileExtension === 'pdf'
      ) {
        flag = true;
      }

      // If flag is still false, show appropriate error message
      if (!flag) {
        setPageLoader(false);
        
        enqueueSnackbar(
          'Only specific file types are allowed based on the document title.',
          {
            variant: 'error',
          }
        );
        return;
      }

      // Proceed to call the API if the document is valid
      createDocuments({ attachments: [body] })
        .then((res) => {
          setPageLoader(false);
          if (res?.success === true) {
            getData();
            return enqueueSnackbar('Document Uploaded Successfully', {
              variant: 'success',
            });
          }
        })
        .catch((err) => {
          setPageLoader(false);
          console.log('error', err);
          return enqueueSnackbar(
            'Error while uploading. Please try again later.',
            {
              variant: 'error',
            }
          );
        });
    };
    reader.readAsDataURL(file);
    // setUploadData([...uploadDataCopy]);
  };

  // const uploadDoc = (file, el) => {
  //   setPageLoader(true);
  //   const uploadDataCopy = [...checkList];

  //   const index = uploadDataCopy?.findIndex(
  //     (elem) => elem.title === el.title
  //   );
  //   uploadDataCopy[index].previewImage = file;

  //   const applicationProgramName = applicationState?.form?.programName;
  //   const appFormName = applicationState?.form?.applicationFormName;
  //   const reader = new FileReader();
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   let base64;
  //   reader.onloadend = () => {
  //     base64 = reader.result.replace('data:', '').replace(/^.+,/, '');
  //     const myArray = file.name.split('.');
  //     const body = {
  //       fileName: file?.name,
  //       title: el.title,
  //       formattedDocumentName: `${applicationProgramName}_${appFormName}_${user?.applicantName}.${myArray[myArray.length - 1]}`,
  //       expiryDate: null,
  //       body: base64,
  //       applicationId: applicationState?.applicationId,
  //       fileType: file.type,
  //     };
  //     createDocuments({ attachments: [body] })
  //       .then((res) => {
  //         setPageLoader(false);
  //         if (res?.success === true) {
  //           return enqueueSnackbar('Document Uploaded Successfully', {
  //             variant: 'success',
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setPageLoader(false);
  //         console.log('error', err);
  //         return enqueueSnackbar(
  //           'Error while uploading. Please try again later.',
  //           {
  //             variant: 'error',
  //           }
  //         );
  //       });
  //   };
  //   reader.readAsDataURL(file);
  //   setUploadData([...uploadDataCopy]);
  // };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: '50vw',
          margin: '0 auto',
          height: '180vh',
          '@media screen and (max-width: 768px)': {
            height: '210vh',
          },
        }}
      >
        <Typography className={styles.title}>Document Upload</Typography>
        {checkList?.map((el, index) => {
          return (
            <>
              {pageLoader && <KenLoader />}
              <KenDocumentUpload
                label={el.title}
                handleDownload={handleDownload}
                onChange={(file) => uploadDoc(file[0], el)}
                {...el}
                length={checkList.length}
                index={index}
                accept="image/jpeg, image/jpg, image/png "
                listofdoc={el}
              />

              {/* <KenDocumentUpload
                label={el.title}
                previewImage={el.previewImage}
                handleUploaded={(file)=>handleUploaded(file[0])}
                // removeFile={(el)=>handleFileDelete(el)}
                onChange={(file) => uploadDoc(file[0], el)}

              /> */}

              <Divider
                variant="fullWidth"
                style={{ background: '#E2E2E2', marginBottom: '30px' }}
              />
            </>
          );
        })}
      </Box>
      <KenDialog
        open={filePreview}
        handleClose={() => {
          setFilePreview(false);
        }}
      >
        <FilePreview
          type={'file'}
          file={file instanceof File && file}
          onError={onError}
          width={'210px'}
          height={'150px'}
          // removeFile={removeFile}
          url={url}
        />
      </KenDialog>
      <KenStepperFooter
        totalSteps={applicationState.totalSteps}
        activeStep={applicationState.activeStep}
        onPrev={handlePrev}
        onNext={handleNext}
        applicationStatus={
          applicationState?.applicationData?.application?.applicationStatus
        }
      />
    </React.Fragment>
  );
}
