import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CardContent from './Components/cardContent';
import CardHeader from './Components/cardHeader';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFFFFF',
    boxShadow:
      ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '12px',
  },
}));

export default function PaymentSummary(props) {
  const {
    paymentModeTitle,
    feeTitle,
    partialAmount,
    discountTitle,
    discountAmount,
    basicFee,
    errorInAmount,
    paymentMode,
    handlePay
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <CardHeader />
      <CardContent
        paymentModeTitle={paymentModeTitle}
        feeTitle={feeTitle}
        partialAmount={partialAmount || basicFee}
        discountTitle={discountTitle}
        discountAmount={discountAmount}
        errorInAmount={errorInAmount}
        basicFee={basicFee}
        paymentMode={paymentMode}
        handlePay={handlePay}
        {...props}
      />
    </Box>
  );
}
