// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD5gwpTm-RWHeghY-_mj6O5t-SlwQheWIk',
  authDomain: 'spjain-addmission.firebaseapp.com',
  projectId: 'spjain-addmission',
  storageBucket: 'spjain-addmission.appspot.com',
  messagingSenderId: '862511638043',
  appId: '1:862511638043:web:0201a15e53ab1cf497fabe',
  measurementId: 'G-QG8Q7R7991',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export const signInWithGoogle = async () => {
  try {
    const googleProvider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, googleProvider);
    console.log('[signInWithGoogle] res: ', res);
    return res;
  } catch (error) {
    console.log('[signInWithGoogle] error: ', error);
    throw error;
  }
};

export const signInWithGithub = async () => {
  try {
    const githubProvider = new GithubAuthProvider();
    const res = await signInWithPopup(auth, githubProvider);
    console.log('[signInWithGithub] res: ', res);
    return res;
  } catch (error) {
    console.log('[signInWithGithub] error: ', error);
    throw error;
  }
};

export const signInWithTwitter = async () => {
  try {
    const twitterProvider = new TwitterAuthProvider();
    const res = await signInWithPopup(auth, twitterProvider);
    console.log('[signInWithTwitter] res: ', res);
    return res;
  } catch (error) {
    console.log('[signInWithTwitter] error: ', error);
    throw error;
  }
};

export const signInWithMicrosoft = async () => {
  try {
    const microsoftProvider = new OAuthProvider('microsoft.com');
    const res = await signInWithPopup(auth, microsoftProvider);
    console.log('[signInWithMicrosoft] res: ', res);
    return res;
  } catch (error) {
    console.log('[signInWithMicrosoft] error: ', error);
    throw error;
  }
};

export const signInWithFacebook = async () => {
  try {
    const facebookProvider = new FacebookAuthProvider();
    const res = await signInWithPopup(auth, facebookProvider);
    console.log('[signInWithFacebook] res: ', res);
    return res;
  } catch (error) {
    console.log('[signInWithFacebook] error: ', error);
    throw error;
  }
};
