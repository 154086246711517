import React from "react";

import KenApplication from "../../Components/KenApplication";
import FormHeader from "./Components/FormHeader";
import { ApplicationContextProvider } from "../../Context/applicationContext";

function ApplicationFormNew() {

    return (
        <ApplicationContextProvider>
            {/* <FormHeader /> */}

            <KenApplication />

        </ApplicationContextProvider>
    );
}

export default ApplicationFormNew;
