import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralButton from "../../../../Components/Button";

const useStyles = makeStyles((theme) => ({
  subInfo: {
    textAlign: "left",
    fontFamily: "'Poppins'",
  },
  bckBtn: {
    color: "#fff",
    backgroundColor: "#092682",
    borderRadius: "32px",
    "&:hover": {
      cursor: "pointer",
      background: "#DFE8FF",
      color: theme.palette.labels.medium,
    },
    minWidth: "10vw",
    padding: "12px 50px",
  },

  headingMain: {
    textAlign: "left",
    fontSize: 24,
    color: theme.palette.KenColors.dark,
    fontWeight: 600,
  },
}));

export default function DescriptionBox(props) {
  const classes = useStyles();
  const { title, message } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleApply = () => {
    navigate("/courses");
  };
  return (
    <Box
      style={{ padding: "24px", background: "#F7F8FB", borderRadius: "16px" }}
    >
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Typography className={classes.headingMain}>{title}</Typography>
        </Grid>
        <Grid item md={9} sm={8} xs={12}>
          <Typography className={classes.subInfo}>{message}</Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Box display="flex" justifyContent={"flex-end"}>
            <Button
              size="small"
              variant="contained"
              className={classes.bckBtn}
              onClick={handleApply}
            >
              {t("labels:Apply")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
