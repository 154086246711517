import { REGEX } from "../../Constants/constant";

export const defaultJson = [
  {
    stepId: 'basic Details',
    stepName: 'Basic Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'form',
    sections: [
      {
        sectionName: 'Basic Details',
        apiKeyId: 'applicant',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'applicant[firstName]',
            required: true,
            name: 'applicant[firstName]',
            label: 'First Name',
            type: 'input',
            disabled: true,
            validationType: 'alpha',
            validations: {
              required: 'This field is required',
              minLength: {
                value: 1,
                message: 'This field cannot be less than 1 characters',
              },
              maxLength: {
                value: 50,
                message: 'This field cannot be more than 50 characters',
              },
              pattern: {
                value: '',
                message: 'Please enter a uppercase characters only',
              },
            },
          },
          {
            fieldId: 'applicant[middleName]',
            required: false,
            name: 'applicant[middleName]',
            label: 'Middle Name',
            disabled: true,
            type: 'input',
            validationType: 'alpha',
            validations: { 
              // required: 'This field is required',
              minLength: {
                value: 1,
                message: 'This field cannot be less than 1 characters',
              },
              maxLength: {
                value: 50,
                message: 'This field cannot be more than 50 characters',
              },
              pattern: {
                value: '',
                message: 'Please enter a uppercase characters only',
              },
            },
          },
          {
            fieldId: 'applicant[lastName]',
            required: true,
            name: 'applicant[lastName]',
            label: 'Last Name',
            disabled: true,
            type: 'input',
            validationType: 'alpha',
            validations: {
              required: 'This field is required',
              minLength: {
                value: 1,
                message: 'This field cannot be less than 1 characters',
              },
              maxLength: {
                value: 50,
                message: 'This field cannot be more than 50 characters',
              },
              pattern: {
                value: '',
                message: 'Please enter a uppercase characters only',
              },
            },
          },
          {
            fieldId: 'applicant[fatherName]',
            required: true,
            name: 'applicant[fatherName]',
            label: "Father's Name",
            disabled: false,
            type: 'input',
            validationType: 'alpha',
            validations: {
              required: 'This field is required',
              minLength: {
                value: 1,
                message: 'This field cannot be less than 1 characters',
              },
              maxLength: {
                value: 50,
                message: 'Max 50 alphabets are allowed',
              },
              pattern: {
                value: '',
                message: 'Only alphabets are allowed',
              },
            },
          },
          {
            fieldId: 'applicant[mothersName]',
            required: true,
            name: 'applicant[mothersName]',
            label: "Mother's Name",
            type: 'input',
            validationType: 'alpha',
            validations: {
              required: 'This field is required',
              minLength: {
                value: 1,
                message: 'This field cannot be less than 1 characters',
              },
              maxLength: {
                value: 50,
                message: 'Max 50 alphabets are allowed',
              },
              pattern: {
                value: '',
                message: 'Only alphabets are allowed',
              },
            },
          },
          {
            fieldId: 'applicant[dateOfBirth]',
            required: true,
            name: 'applicant[dateOfBirth]',
            label: 'Date of Birth',
            type: 'date',
            disabled:true,
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'applicant[age]',
            required: false,
            name: 'applicant[age]',
            label: 'Age',
            type: 'input',
            validationType: 'number',
            disabled:true,
            validations: {
              // required: 'This field is required',
              minLength: {
                value: 1,
                message: 'This field cannot be less than 1 characters',
              },
              maxLength: {
                value: 2,
                message: 'This field cannot be more than 2 characters',
              },
              pattern: {
                value: '',
                message: 'Please Input a valid Age',
              },
            },
          },
          {
            fieldId: `application[applicantAadhar]`,
            required: true,
            name: `application[applicantAadhar]`,
            label: 'Aadhar Number',
            type: 'input',
            validationType: 'number',
            validations: {
              required: 'This field is required',
              minLength: {
                value: 12,
                message:'Please enter a valid 12-digits Aadhaar number.',
                // message: 'This field cannot be less than 12 numbers',
              },
              maxLength: {
                value: 12,
                message: 'This field cannot be more than 12 numbers',
              },
              pattern: {
                value: REGEX.AADHAAR,
                message: 'Please enter a number',
              },
            },
            
          },
          {
            fieldId: 'applicant[religion]',
            required: false,
            name: 'applicant[religion]',
            label: 'Religion',
            type: 'input',
            disabled:true,
            validationType: 'alpha',
            validations: {
              //   // required: 'This field is required',
              //   minLength: {
              //     value: 1,
              //     message: 'This field cannot be less than 1 characters',
              //   },
              //   maxLength: {
              //     value: 50,
              //     message: 'This field cannot be more than 50 characters',
              //   },
              pattern: {
                value: '',
                message: 'Please enter a uppercase characters only',
              },
            },
          },
          

          // {
          //   fieldId: 'applicant[guardiansName]',
          //   required: false,
          //   name: 'applicant[guardiansName]',
          //   label: "Guardian's Name",
          //   type: 'input',
          //   validationType: 'alpha',
          //   validations: {
          //     // required: 'This field is required',
          //     minLength: {
          //       value: 1,
          //       message: 'This field cannot be less than 1 characters',
          //     },
          //     maxLength: {
          //       value: 50,
          //       message: 'This field cannot be more than 50 characters',
          //     },
          //     pattern: {
          //       value: '',
          //       message: 'Please enter a uppercase characters only',
          //     },
          //   },
          //   // validations: {
          //   //   required: 'This field is required',
          //   // },
          // },
          {
            fieldId: 'applicant[hobbies]',
            required: false,
            name: 'applicant[hobbies]',
            label: 'Hobbies/Special Interest',
            type: 'input',
            validationType: 'alpha',
            validations: {
              // required: 'This field is required',
              minLength: {
                value: 1,
                message: 'This field cannot be less than 1 characters',
              },
              maxLength: {
                value: 50,
                message: 'Max 50 characters are allowed',
              },
              pattern: {
                value: /^[A-Za-z0-9\s,.'-]{1,100}$/,
                message: 'Only letters, numbers, and common punctuation are allowed',
              },
            },
            // validations: {
            //   required: 'This field is required',
            // },
          },
          {
            fieldId: 'applicant[bloodGroup]',
            required: false,
            name: 'applicant[bloodGroup]',
            label: 'Blood Group',
            type: 'select',
            apiKey: 'applicant[bloodGroup]',
            options: [
              {
                label: 'AB+',
                value: 'AB+',
              },
              {
                label: 'AB-',
                value: 'AB-',
              },
              {
                label: 'A+',
                value: 'A+',
              },
              {
                label: 'A-',
                value: 'A-',
              },
              {
                label: 'B+',
                value: 'B+',
              },
              {
                label: 'B-',
                value: 'B-',
              },
              {
                label: 'O+',
                value: 'O+',
              },
              {
                label: 'O-',
                value: 'O-',
              },
            ],
            // validationType: 'aplha',
            // validations: {
            //   required: 'This field is required',
            // },
            // ui: {
            //   gridType: 'Row',
            // },
          },
          {
            fieldId: 'applicant[phone]',
            required: true,
            name: 'applicant[phone]',
            label: 'Phone Number',
            type: 'phone',
            apiKey: 'applicant[phone]',
            disabled: true,
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'Please Input a valid Phone Number',
              },
            },
          },
          {
            fieldId: 'applicant[caste]',
            required: false,
            name: 'applicant[caste]',
            label: 'Caste',
            type: 'select',
            apiKey: 'applicant[caste]',
            disabled:true,
            options: [
              {
                label: 'GENERAL',
                value: 'GENERAL',
              },
              {
                label: 'BC',
                value: 'BC',
              },
              {
                label: 'OBC',
                value: 'OBC',
              },
              {
                label: 'OC',
                value: 'OC',
              },

              {
                label: 'SC',
                value: 'SC',
              },
              {
                label: 'ST',
                value: 'ST',
              },
              {
                label: 'DO NOT WISH TO SPECIFY',
                value: 'DO NOT WISH TO SPECIFY',
              },
              {
                label: 'NA',
                value: 'NA',
              }
            ],
            // validationType: 'alpha',
            // validations: {
            //   required: 'This field is required',
            // },
            // ui: {
            //   gridType: 'Row',
            // },
          },
          {
            fieldId: 'applicant[gender]',
            required: true,
            name: 'applicant[gender]',
            label: 'Gender',
            apiKey: 'applicant[gender]',
            disabled: true,
            type: 'radio',
            validations: {
              required: 'This field is required',
            },
            options: [
              {
                label: 'MALE',
                value: 'MALE',
              },
              {
                label: 'FEMALE',
                value: 'FEMALE',
              },
              {
                label: 'OTHER',
                value: 'OTHER',
              },
            ],
          },
          {
            fieldId: 'applicant[residence]',
            required: true,
            name: 'applicant[residence]',
            label: 'Residence',
            type: 'select',
            apiKey: 'applicant[residence]',
            validations: {
              required: 'This field is required',
            },
            options: [
              {
                label: 'ON CAMPUS',
                value: 'ON CAMPUS',
              },
              {
                label: 'OFF CAMPUS',
                value: 'OFF CAMPUS',
              },
            ],
            // validationType: 'alpha',
            // validations: {
            //   required: 'This field is required',
            // },
            // ui: {
            //   gridType: 'Row',
            // },
          },
          {
            fieldId: 'applicant[belowPovertyLine]',
            required: false,
            name: 'applicant[belowPovertyLine]',
            label: 'Below Poverty Line',
            type: 'select',
            apiKey: 'applicant[belowPovertyLine]',
            disabled:false,
            // validations: {
            //   required: 'This field is required',
            // },
            options: [
              {
                label: 'YES',
                value: 'YES',
              },
              {
                label: 'NO',
                value: 'NO',
              }
            ]
          },
        ],
      },

      {
        sectionName: 'Bank Details',
        subSectionName: '',
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'applicant[accountHoldername]',
            required: true,
            name: 'applicant[accountHoldername]',
            label: 'Name Of The Account Holder',
            type: 'input',
            validationType: 'alpha',
            validations: {
              required: 'This field is required',
              minLength: {
                value: 2,
                message: 'This field cannot be less than 2 characters',
              },
              maxLength: {
                value: 50,
                message: 'This field cannot be more than 50 characters',
              },
              pattern: {
                value: '^[A-Z ]+$',
                message: 'Only alphabets are allowed',
              },
            },
          },

          {
            fieldId: 'applicant[bankAccountNumber]',
            required: true,
            name: 'applicant[bankAccountNumber]',
            label: 'Bank Account Number',
            type: 'input',
            validationType: 'number',
            validations: {
              required: 'This field is required',
              pattern: {
                value: /^\d{9,18}$/,
                message:
                  'Bank account number must be between 9 and 18 digits and only numbers are allowed',
              },
            },
          },
          {
            fieldId: 'applicant[bankName]',
            required: true,
            name: 'applicant[bankName]',
            label: 'Bank Name',
            type: 'select',
            validationType: 'alpha',
            validations: {
              required: 'This field is required'
            },
            metaData:{
              optionsKey:"bankList"
            },
            options:[]
          },
          {
            fieldId: 'applicant[branchName]',
            required: true,
            name: 'applicant[branchName]',
            label: 'Branch Name',
            type: 'input',
            validationType: 'alpha',
            validations: {
              required: 'This field is required',
              minLength: {
                value: 2,
                message: 'This field cannot be less than 2 characters',
              },
              maxLength: {
                value: 50,
                message: 'This field cannot be more than 50 characters',
              },
              pattern: {
                value: /^[A-Za-z0-9 ]+$/,
                message:
                  'Please enter only alphabets, numerals, and spaces, without any special characters',
              },
            },
          },
          {
            fieldId: 'applicant[ifsc]',
            required: true,
            name: 'applicant[ifsc]',
            label: 'IFSC Code',
            type: 'input',
            validationType: 'alphaNumeric',
            validations: {
              required: 'This field is required',
              pattern: {
                value: /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
                message:
                  'The input must be exactly 11 characters long: 4 alphabets followed by 7 alphanumeric characters, with no spaces or special characters',
              },
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'familyInfo',
    stepName: 'Family Info',
    showStepName: false,
    stepVisible: true,
    sectionPosition: 2,
    stepType: 'form',
    sections: [
      {
        sectionId: 'familyInformation',
        sectionName: 'Family Details',
        subSectionName: '',
        sectionPosition: 2,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'applicantRelations',
            name: 'applicantRelations',
            label: 'Family Information',
            type: 'array',
            defaultItemValue: {
              relationshipType: '',
              firstName: '',
              lastName: '',
              phone: '',
              email: '',
              age: '',
              occupation: '',
              annualIncome: '',
              currencyType: '',
            },
            metaData: {
              addMultiple: true,
              minItems: 1,
            },
            fieldsArray: [
              {
                fieldId: 'firstName',
                required: true,
                name: 'firstName',
                label: 'First Name',
                type: 'input',
                validationType: 'alpha',
                validations: {
                  required: 'This field is required',
                  minLength: {
                    value: 1,
                    message: 'This field cannot be less than 1 characters',
                  },
                  maxLength: {
                    value: 30,
                    message: 'Max 30 alphabets are allowed',
                  },
                  pattern: {
                    value: '',
                    message: 'Only alphabets are allowed',
                  },
                },
              },
              {
                fieldId: 'lastName',
                required: true,
                name: 'lastName',
                label: 'Last Name',
                type: 'input',
                validationType: 'alpha',
                validations: {
                  required: 'This field is required',
                  minLength: {
                    value: 1,
                    message: 'This field cannot be less than 1 characters',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Max 50 alphabets are allowed',
                  },
                  pattern: {
                    value: '',
                    message: 'Only alphabets are allowed',
                  },
                },
              },

              {
                fieldId: 'noOfFamilyMembers',
                required: true,
                name: 'noOfFamilyMembers',
                label: 'No. of Family Members',
                type: 'input',
                validationType: 'number',
                validations: {
                  required: 'This field is required',
                  minLength: {
                    value: 1,
                    message: 'This field cannot be less than 1 characters',
                  },
                  maxLength: {
                    value: 2,
                    message: 'Max 2 numbers are allowed',
                  },
                  pattern: {
                    value: '',
                    message: 'Only numbers are allowed',
                  },
                },
              },
              {
                fieldId: 'noOfFamilyMembersAdults',
                required: true,
                name: 'noOfFamilyMembersAdults',
                label: 'No. of Family Members: Adults',
                type: 'input',
                // validationType: 'alphaNumeric',
                validationType: 'number',
                validations: {
                  required: 'This field is required',
                  minLength: {
                    value: 1,
                    message: 'This field cannot be less than 1 characters',
                  },
                  maxLength: {
                    value: 2,
                    message: 'Max 2 numbers are allowed',
                  },
                  pattern: {
                    value: '',
                    message: 'Max 2 numbers are allowed',
                  },
                  validate: (value, context) => {
                    const applicant = context?.applicantRelations?.[0] || {}; // Extract the applicant from context
                    const { noOfFamilyMembers } = applicant;
              
                    // Ensure that noOfFamilyMembersAdults does not exceed noOfFamilyMembers
                    if (parseInt(value) > parseInt(noOfFamilyMembers)) {
                      return 'Number of adults cannot exceed total family members';  // Return error message if validation fails
                    }
                    return true; // Validation passed
                  }
                },
              },
              {
                fieldId: 'noOfFamilyMembersChildren',
                required: true,
                name: 'noOfFamilyMembersChildren',
                label: 'No. of Family Members: Children',
                type: 'input',
                validationType: 'number',
                validations: {
                  required: 'This field is required',
                  minLength: {
                    value: 1,
                    message: 'This field cannot be less than 1 characters',
                  },
                  maxLength: {
                    value: 2,
                    message: 'Max 2 numbers are allowed',
                  },
                  pattern: {
                    value: '',
                    message: 'Only numbers are allowed',
                  },
                  
              // validate: (value, context) => {
              //       const applicant = context?.applicantRelations?.[0] || {}; // Extract the applicant data from context
              //       const { noOfFamilyMembers, noOfFamilyMembersAdults } = applicant;
              
              //       // Ensure that noOfFamilyMembersChildren does not exceed the remaining family members
              //       const remainingFamilyMembers = parseInt(noOfFamilyMembers) - parseInt(noOfFamilyMembersAdults);
                    
              //       if (parseInt(value) > remainingFamilyMembers) {
              //         return 'Number of children cannot exceed the remaining family members after accounting for adults';
              //       }
              //       return true; // Validation passed
              //     },
              validate: (value, context) => {
                const applicant = context?.applicantRelations?.[0] || {}; 
                const { noOfFamilyMembers, noOfFamilyMembersAdults } = applicant;
                const totalFamilyMembers = parseInt(noOfFamilyMembers);
                const adultFamilyMembers = parseInt(noOfFamilyMembersAdults);
                const childFamilyMembers = parseInt(value);
                const expectedChildren = totalFamilyMembers - adultFamilyMembers;
                if (childFamilyMembers < expectedChildren) {
                  return `The number of children should be exactly ${expectedChildren}.`;
                  // return 'The number of children should not lesser the remaining family members after accounting for adults'
                }
              
                if (childFamilyMembers > expectedChildren) {
                  return `The number of children should not exceed the remaining family members after accounting for adults${expectedChildren}.`;
                }
              
                return true; 
              }
                },
              },
              {
                fieldId: 'workingMembers',
                name: 'workingMembers',
                label: 'Working Members',

                required: true,
                type: 'input',
                validationType: 'number',
                validations: {
                  required: 'This field is required',
                  minLength: {
                    value: 1,
                    message: 'This field cannot be less than 1 characters',
                  },
                  maxLength: {
                    value: 2,
                    message: 'Max 2 numbers are allowed',
                  },
                  pattern: {
                    value: '',
                    message: 'Only numbers are allowed',
                  },
                  // validate: (value, context) => {
                  //   const applicant = context?.applicantRelations?.[0] || {}; // Extract the applicant from context
                  //   const { noOfFamilyMembers } = applicant;
              
                  //   // Ensure that noOfFamilyMembersAdults does not exceed noOfFamilyMembers
                  //   if (parseInt(value) > parseInt(noOfFamilyMembers)) {
                  //     return 'Number of working members cannot exceed total family members';  // Return error message if validation fails
                  //   }
                  //   return true; // Validation passed
                  // }
                  validate: (value, context) => {
                    const applicant = context?.applicantRelations?.[0] || {}; 
                    const { noOfFamilyMembers, noOfFamilyMembersAdults } = applicant;
                    if (parseInt(value) > parseInt(noOfFamilyMembers)) {
                      return 'Number of working members cannot exceed total family members';  
                    }
              
                    if (parseInt(value) > parseInt(noOfFamilyMembersAdults)) {
                      return 'Number of working members must not exceed the number of adults';  
                    }
              
                    return true; 
                  }
                },
              },
              // {
              //   fieldId: 'nonWorkingMembers',
              //   name: 'nonWorkingMembers',
              //   label: 'Non Working Members',

              //   required: true,
              //   type: 'input',
              //   validationType: 'number',
              //   validations: {
              //     required: 'This field is required',
              //     minLength: {
              //       value: 1,
              //       message: 'This field cannot be less than 1 characters',
              //     },
              //     maxLength: {
              //       value: 1,
              //       message: 'Please Input a valid number',
              //     },
              //     pattern: {
              //       value: '',
              //       message: 'Please Input a valid number',
              //     },
              //   },
              // },
              {
                fieldId: 'importantInformation',
                required: true,
                name: 'importantInformation',
                label: 'Any important information of the family if necessary',
                type: 'select',
                options: [
                  {
                    label: 'SINGLE PARENT',
                    value: 'SINGLE PARENT',
                  },
                  {
                    label: 'SPECIALLY ABLED FAMILY MEMBER INCLUDING SELF',
                    value: 'SPECIALLY ABLED FAMILY MEMBER INCLUDING SELF',
                  },
                  {
                    label: 'NONE',
                    value: 'NONE',
                  },
                ],
                validationType: 'aplha',
                validations: {
                  required: 'This field is required',
                },
              },
              // {
              //   fieldId: 'martialStatus',

              //   name: 'martialStatus',
              //   label: 'Marital Status',
              //   type: 'radio',
              //   required: true,
              //   validations: {
              //     required: 'This field is required',
              //   },
              //   options: [
              //     {
              //       label: 'Single ',
              //       value: 'Single',
              //     },
              //     {
              //       label: 'Married',
              //       value: 'Married',
              //     },
              //     {
              //       label: 'Other',
              //       value: 'Other',
              //     },
              //   ],
              // },
           ],
          },
          // {
          //   fieldId:'applicant[approxMonthlyIncome]',
          //   name: 'applicant[approxMonthlyIncome]',
          //   label: 'Approximate monthly income of the family',
          //   type: 'input',

          //   required: true,
          //   validationType: 'numberWithDecimal',
          //   validations: {
          //     required: 'This field is required',
          //     minLength: {
          //       value: 1,
          //       message: 'This field cannot be less than 1 characters',
          //     },
          //     maxLength: {
          //       value: 15,
          //       message: 'Max 15 numbers allowed',
          //     },
          //     pattern: {
          //       value: /^\d+(\.\d{1,2})?$/,
          //       message: 'Enter a valid number (up to two decimal places).',
          //     },
          //   },
          // },
          {
            fieldId:'applicant[approxMonthlyIncome]',
            name: 'applicant[approxMonthlyIncome]',
            label: 'Approximate monthly income of the family',
            type: 'input',
            required: true,
            validationType: 'numberWithDecimal',
            validations: {
              required: 'This field is required',
              pattern: {
                value: /^\d{1,15}(\.\d{1,2})?$/,
                message: 'Max 15 numbers allowed',
                // message: 'Enter a valid number with up to 15 digits before the decimal and up to 2 decimal places.',
              },
            },
          }
        ],
  },
]
  },
  {
    stepId: 'Contact Details',
    stepName: 'Address Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 3,
    stepType: 'form',
    sections: [
      {
        sectionName: 'Permanent Address',
        subSectionName: '',
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            name: 'addresses[0]',
            type: 'address',
            options: [
              {
                fieldId: 'mailingCountry',
                required: true,
                name: 'mailingCountry',
                isPartOfCollection: true,
                label: 'Country',
                type: 'select',
                disabled: true,
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'mailingState',
                required: true,
                name: 'mailingState',
                label: 'State',
                type: 'select',
                disabled: true,
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
              {
                fieldId: 'city',
                required: true,
                name: 'city',
                label: 'City/District',
                type: 'select',
                disabled: true,
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
            ],
          },
          {
            fieldId: 'addresses[0][mailingDistrictOthers]',
            required: true,
            name: 'addresses[0][mailingDistrictOthers]',
            label: 'City/District Others',
            type: 'input',
            validations: {
              required: 'This field is required',
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'addresses[0][city]',
                expectedValue: 'Others',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'applicant[tehsil]',
            required: true,
            name: 'applicant[tehsil]',
            label: 'Tehsil',
            type: 'input',
            disabled:true,
           // validationType: 'alpha',
            validations: {
              //   // required: 'This field is required',
              //   minLength: {
              //     value: 1,
              //     message: 'This field cannot be less than 1 characters',
              //   },
              //   maxLength: {
              //     value: 50,
              //     message: 'This field cannot be more than 50 characters',
              //   },
              pattern: {
                value: '',
                message: 'Only alphabets are allowed',
              },
            },
          },
          {
            fieldId: 'applicant[gramPanchayat]',
            required: true,
            name: 'applicant[gramPanchayat]',
            label: 'Village',
            type: 'input',
            disabled:true,
          //  validationType: 'alpha',
            validations: {
              //   // required: 'This field is required',
              //   minLength: {
              //     value: 1,
              //     message: 'This field cannot be less than 1 characters',
              //   },
              //   maxLength: {
              //     value: 50,
              //     message: 'This field cannot be more than 50 characters',
              //   },
              pattern: {
                value: '',
                message: 'Please enter a uppercase characters only',
              },
            },
          },
          {
            fieldId: 'addresses[0][hamlet]',
            required: false,
            name: 'addresses[0][hamlet]',
            label: 'Hamlet',
            type: 'input',
            disabled:false,
            validationType: 'alpha',
            validations: {
              //   // required: 'This field is required',
              //   minLength: {
              //     value: 1,
              //     message: 'This field cannot be less than 1 characters',
              //   },
                maxLength: {
                  value: 50,
                  message: 'Max 50 alphabets are allowed',
                },
              pattern: {
                value: /^[A-Z. ( ) - ]*$/,
                message: 'Please enter a uppercase characters only',
              },
            },
          },
          // {
          //   fieldId: 'addresses[0][mailingStreet]',
          //   required: true,
          //   name: 'addresses[0][mailingStreet]',
          //   label: 'Permanent Address',
          //   type: 'input',
          //   validationType: 'alphaAddress',
          //   validations: {
          //     required: 'This field is required',
          //     pattern: {
          //       value: '',
          //       message: 'Please input a correct value',
          //     },
          //     maxLength: {
          //       value: 200,
          //       message: 'This field cannot be more than 200 characters',
          //     },
          //   },
          // },
          // {
          //   fieldId: 'addresses[0][mailingStreet]',
          //   required: true,
          //   name: 'addresses[0][mailingStreet]',
          //   label: 'Address',
          //   type: 'input',
          //   validationType: 'alphaWithSpecialChar',
          //   validations: {
          //     required: 'This field is required',
          //     minLength: {
          //       value: 1,
          //       message: 'This field cannot be less than 6 characters',
          //     },
          //     maxLength: {
          //       value: 250,
          //       message: 'This field cannot be more than 250 characters',
          //     },
          //     pattern: {
          //       value: '',
          //       message:
          //         'Please enter an address with uppercase, special characters, or number',
          //     },
              
          //   },
          // },
          {
            fieldId: 'addresses[0][houseNumber]',
            required: false,
            name: 'addresses[0][houseNumber]',
            label: 'House Number',
            type: 'input',
            disabled:false,
            validationType: 'number',
            validations: {
              //   // required: 'This field is required',
              //   minLength: {
              //     value: 1,
              //     message: 'This field cannot be less than 1 characters',
              //   },
              //   maxLength: {
              //     value: 50,
              //     message: 'This field cannot be more than 50 characters',
              //   },
              pattern: {
                value: /^[a-zA-Z0-9#,\-./\s]*$/,
                message: 'Please enter a House Number only',
              },
            },
          },
          {
            fieldId: 'addresses[0][mailingPostalCode]',
            required: true,
            name: 'addresses[0][mailingPostalCode]',
            label: 'PIN / PO BOX/ ZIP',
            type: 'input',
            disabled: true,
            validationType: 'pincode',
            validations: {
             // required: 'This field is required',
              minLength: {
                value: 1,
                message: 'Please Input a valid PinCode',
              },
              maxLength: {
                value: 6,
                message: 'Please Input a valid PinCode',
              },
              pattern: {
                value: '',
                message: 'Please Input a valid PinCode',
              },
            },
          },
        ],
      },
      {
        sectionName: 'Current Address',
        subSectionName: '',
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'addresses[0][isPermanentAddressSame]',
            required: true,
            name: 'addresses[0][isPermanentAddressSame]',
            label: 'Is your current address same as permanent address?',
            type: 'select',
            options: [
              {
                label: 'Yes',
                value: 'true',
              },
              {
                label: 'No',
                value: 'false',
              },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              gridType: 'fullRow',
            },
          },
          {
            name: 'addresses[1]',
            type: 'address',
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'addresses[0][isPermanentAddressSame]',
                expectedValue: 'false',
                type: 'LEAF',
              },
            },
            options: [
              {
                fieldId: 'mailingCountry',
                required: true,
                name: 'mailingCountry',
                isPartOfCollection: true,
                label: 'Country',
                //default: "INDIA",
                disabled:true,
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'mailingState',
                required: true,
                name: 'mailingState',
                label: 'State',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
              {
                fieldId: 'city',
                required: true,
                name: 'city',
                label: 'City/Town',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
              {
                fieldId: 'tehsil',
                required: true,
                name: 'tehsil',
                label: 'Tehsil',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
              {
                fieldId: 'gramPanchayat',
                required: true,
                name: 'gramPanchayat',
                label: 'Village',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
            ],
          },
          {
            fieldId: 'addresses[1][hamlet]',
            required: false,
            name: 'addresses[1][hamlet]',
            label: 'Hamlet',
            type: 'input',
            validationType: 'alpha',
            validations: {
              //   // required: 'This field is required',
              //   minLength: {
              //     value: 1,
              //     message: 'This field cannot be less than 1 characters',
              //   },
                maxLength: {
                  value: 50,
                  message: 'Max 50 alphabets are allowed',
                },
              pattern: {
                value: '',
                message: 'Only alphabets are allowed',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'addresses[0][isPermanentAddressSame]',
                expectedValue: 'false',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'addresses[1][houseNumber]',
            required: false,
            name: 'addresses[1][houseNumber]',
            label: 'House Number',
            type: 'input',
            disabled:false,
            validationType: 'number',
            validations: {
              //   // required: 'This field is required',
              //   minLength: {
              //     value: 1,
              //     message: 'This field cannot be less than 1 characters',
              //   },
                maxLength: {
                  value: 10,
                  message: 'Must be 10 characters or less',
                },
              pattern: {
                  value: /^[a-zA-Z0-9#,\-./\s]*$/,
                message: 'Please enter a valid house number',

              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'addresses[0][isPermanentAddressSame]',
                expectedValue: 'false',
                type: 'LEAF',
              },
            },
          },

        
     
      {
        fieldId: 'addresses[1][mailingPostalCode]',
        required: true,
        name: 'addresses[1][mailingPostalCode]',
        label: 'PIN / PO BOX/ ZIP',
        type: 'input',
        
        validationType: 'pincode',
        validations: {
          required: 'This field is required',
          minLength: {
            value: 1,
            message: 'Please Input a valid PinCode',
          },
          maxLength: {
            value: 6,
            message: 'Please Input a valid PinCode',
          },
          pattern: {
            value: '',
            message: 'Please Input a valid PinCode',
          },
        },
        rule: {
          effect: 'SHOW',
          condition: {
            scope: 'addresses[0][isPermanentAddressSame]',
            expectedValue: 'false',
            type: 'LEAF',
          },
        },
      },
    ],
  },
//   {
//     fieldId: 'addresses[1][mailingPostalCode]',
//     required: true,
//     name: 'addresses[1][mailingPostalCode]',
//     label: 'PIN / PO BOX/ ZIP',
//     type: 'input',
//     validationType: 'pincode',
//     validations: {
//       required: 'This field is required',
//       minLength: {
//         value: 1,
//         message: 'Please Input a valid PinCode',
//       },
//       maxLength: {
//         value: 6,
//         message: 'Please Input a valid PinCode',
//       },
//       pattern: {
//         value: '',
//         message: 'Please Input a valid PinCode',
//       },
//     },
//     rule: {
//       effect: 'SHOW',
//       condition: {
//         scope: 'addresses[0][isPermanentAddressSame]',
//         expectedValue: 'false',
//         type: 'LEAF',
//       },
//     },
//   },
// ],
// },
      // {
      //   sectionName: 'PERMANENT ADDRESS',
      //   subSectionName: '',
      //   sectionVisible: true,
      //   sectionType: 'form',
      //   fields: [
      //     {
      //       fieldId: 'AddressasperAADHAR',
      //       required: true,
      //       name: 'AddressasperAADHAR',
      //       label: 'Address as per AADHAR',
      //       type: 'input',
      //       validations: {
      //         required: 'This field is required',
      //         maxLength: {
      //           value: 200,
      //           message: 'This field cannot be more than 200 characters',
      //         },
      //       },
      //     },
      //     {
      //       name: 'addresses[0]',
      //       type: 'address',
      //       options: [
      //         {
      //           fieldId: 'mailingCountry',
      //           required: true,
      //           name: 'mailingCountry',
      //           isPartOfCollection: true,
      //           label: 'Country',
      //           type: 'select',
      //           validations: {
      //             required: 'This field is required',
      //           },
      //         },
      //         {
      //           fieldId: 'mailingState',
      //           required: true,
      //           name: 'mailingState',
      //           label: 'State',
      //           type: 'select',
      //           validations: {
      //             required: 'This field is required',
      //           },
      //           options: [],
      //         },
      //         {
      //           fieldId: 'mailingDistrict',
      //           required: true,
      //           name: 'mailingDistrict',
      //           label: 'District',
      //           type: 'select',
      //           validations: {
      //             required: 'This field is required',
      //           },
      //           options: [],
      //         },
      //       ],
      //     },

      //     {
      //       fieldId: 'addresses[0][mailingPostalCode]',
      //       required: true,
      //       name: 'addresses[0][mailingPostalCode]',
      //       label: 'PIN / PO BOX/ ZIP',
      //       type: 'input',
      //       validations: {
      //         required: 'This field is required',
      //         maxLength: {
      //           value: 7,
      //           message: 'This field cannot be more than 7 characters',
      //         },
      //         pattern: {
      //           value: {},
      //           message: 'Please Input a valid Zipcode',
      //         },
      //       },
      //     },
      //   ],
      // },
      // {
      //   sectionName: 'RESIDENTIAL ADDRESS',
      //   subSectionName: '',
      //   sectionVisible: true,
      //   sectionType: 'form',
      //   fields: [
      //     {
      //       fieldId: 'AddressasperAADHAR',
      //       required: true,
      //       name: 'AddressasperAADHAR',
      //       label: 'Address as per AADHAR',
      //       type: 'input',
      //       validations: {
      //         required: 'This field is required',
      //         maxLength: {
      //           value: 200,
      //           message: 'This field cannot be more than 200 characters',
      //         },
      //       },
      //     },
      //     {
      //       name: 'addresses[0]',
      //       type: 'address',
      //       options: [
      //         {
      //           fieldId: 'mailingCountry',
      //           required: true,
      //           name: 'mailingCountry',
      //           isPartOfCollection: true,
      //           label: 'Country',
      //           type: 'select',
      //           validations: {
      //             required: 'This field is required',
      //           },
      //         },
      //         {
      //           fieldId: 'mailingState',
      //           required: true,
      //           name: 'mailingState',
      //           label: 'State',
      //           type: 'select',
      //           validations: {
      //             required: 'This field is required',
      //           },
      //           options: [],
      //         },
      //         {
      //           fieldId: 'mailingDistrict',
      //           required: true,
      //           name: 'mailingDistrict',
      //           label: 'District',
      //           type: 'select',
      //           validations: {
      //             required: 'This field is required',
      //           },
      //           options: [],
      //         },
      //       ],
      //     },

      //     {
      //       fieldId: 'addresses[0][mailingPostalCode]',
      //       required: true,
      //       name: 'addresses[0][mailingPostalCode]',
      //       label: 'PIN / PO BOX/ ZIP',
      //       type: 'input',
      //       validations: {
      //         required: 'This field is required',
      //         maxLength: {
      //           value: 7,
      //           message: 'This field cannot be more than 7 characters',
      //         },
      //         pattern: {
      //           value: {},
      //           message: 'Please Input a valid Zipcode',
      //         },
      //       },
      //     },
      //   ],
      // },
    ],
  },
  {
    stepId: 'Education Details',
    stepName: 'Education Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 4,
    stepType: 'form',
    sections: [
      {
        apiKeyId: 'application',
        sectionName: 'Educational Details',
        sectionId: 'educationHistories',

        sectionPosition: 4,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'applicant[educationalQualification]',
            required: true,
            name: 'applicant[educationalQualification]',
            label: 'Educational Qualification',
            type: 'input',
            disabled:true,
            validationType: 'alphaNumeric',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This field is should be a characters',
              },
            },
            disabled: true, // Add the 'disabled' attribute to make the field non-editable
          },
          
          {
            fieldId: 'application[programName]',
            required: true,
            name: 'application[programName]',
            label: 'Course Name',
            type: 'input',
            disabled:true,
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This field is should be a characters',
              },
            },
            disabled: true, // Add the 'disabled' attribute to make the field non-editable
          },
          // {
          //   fieldId: 'educationHistories[0][batch]',
          //   name: 'educationHistories[0][batch]',
          //   label: 'Batch',
          //   type: 'select',
          //   options: [
          //     {
          //       label: 'January',
          //       value: 'January',
          //     },
          //     {
          //       label: 'February',
          //       value: 'February',
          //     },
          //     {
          //       label: 'March',
          //       value: 'March',
          //     },
          //     {
          //       label: 'April',
          //       value: 'April',
          //     },
          //     {
          //       label: 'May',
          //       value: 'May',
          //     },
          //     {
          //       label: 'June',
          //       value: 'June',
          //     },
          //     {
          //       label: 'July',
          //       value: 'July',
          //     },
          //     {
          //       label: 'August',
          //       value: 'August',
          //     },
          //     {
          //       label: 'September',
          //       value: 'September',
          //     },
          //     {
          //       label: 'October',
          //       value: 'October',
          //     },
          //     {
          //       label: 'November',
          //       value: 'November',
          //     },
          //     {
          //       label: 'December',
          //       value: 'December',
          //     },
          //     // {
          //     //   label: 'Other',
          //     //   value: 'Other',
          //     // },
          //   ],
          // },
          // {
          //   fieldId: 'educationHistories[0][financialYear]',
          //   required: true,
          //   name: 'educationHistories[0][financialYear]',
          //   label: 'Financial Year',
          //   type: 'input',
          //   validationType: 'number',
          //   validations: {
          //     required: 'This field is required',
          //     minLength: {
          //       value: 4,
          //       message: 'This field cannot be less than 4 characters',
          //     },
          //     maxLength: {
          //       value: 4,
          //       message: 'This field cannot be more than 4 characters',
          //     },
          //     pattern: {
          //       value: '',
          //       message: 'Please Input a valid number',
          //     },
          //   },
          // },

          // {
          //   fieldId: 'educationHistories[0][startDate]',
          //   name: 'educationHistories[0][startDate]',
          //   label: 'Starting Date',
          //   type: 'date',
          //   validations: [],
          // },
          // {
          //   fieldId: 'application[courseJoiningDate]',
          //   name: 'application[courseJoiningDate]',
          //   label: 'Joining Date',
          //   type: 'date',
          //   validations: [],
          // },
          // {
          //   fieldId: 'educationHistories[0][endDate]',
          //   name: 'educationHistories[0][endDate]',
          //   label: 'End Date',
          //   type: 'date',
          //   validations: [],
          // },
          {
            fieldId: 'application[courseBecause]',
            required: true,
            name: 'application[courseBecause]',
            label: 'I want to join this course because',
            type: 'input',
            validationType: 'alphaWithSpecialChar',
            validations: {
              required: 'This field is required',
              minLength: {
                value: 2,
                message: 'This field cannot be less than 1 characters',
              },
              maxLength: {
                value: 250,
                message: 'Max 250 characters allowed',
              },
              pattern: {
                value: '',
                message: 'Please enter a uppercase characters only',
              },
            },
          },
          {
            fieldId: 'application[afterCompletion]',
            required: true,
            name: 'application[afterCompletion]',
            label: 'After completion of the course I would like to',
            type: 'select',
            validations: {
              required: 'This field is required',
            },
            options: [
              {
                label: 'JOB',
                value: 'JOB',
              },
              {
                label: 'ENTREPRENEURSHIP',
                value: 'ENTREPRENEURSHIP',
              },
              // {
              //   label: 'Trainer',
              //   value: 'Trainer',
              // },
              {
                label: 'JOINED ONLY FOR LEARNING',
                value: 'JOINED ONLY FOR LEARNING',
              },
              {
                label: 'NONE OF THESE',
                value: 'NONE OF THESE',
              },
              {
                label: 'NOT SURE',
                value: 'NOT SURE',
              },
            ],
          },
          // {
          //   fieldId: 'application[afterCompletion]',
          //   required: true,
          //   name: 'application[afterCompletion]',
          //   label: 'After completion of the course I would like to',
          //   type: 'input',
          //   validations: {
          //     required: 'This field is required',
          //     pattern: {
          //       value: {},
          //       message: 'This field is should be a number',
          //     },
          //   },
          // },
        ],
      },
    ],
  },
  {
    stepId: 'Upload Documents',
    stepName: 'Photo & Document Upload',
    showStepName: false,
    stepVisible: true,
    stepPosition: 5,
    stepType: 'documentUpload',
    
    sections: [],
  },

  {
    stepId: 'summaryWithDeclaration',
    stepName: 'Summary and Declaration',
    stepPosition: 6,
    showStepName: true,
    stepVisible: true,
    stepType: 'summary',
    voucher: true,
    sections: [],
  },
];
