const RuleEffect = {
    HIDE: "HIDE",
    SHOW: "SHOW",
    ENABLE: 'ENABLE',
    DISABLE: 'DISABLE'
}
const isOrCondition = (condition) => condition.type === 'OR';
const isAndCondition = (condition) => condition.type === 'AND';
const isLeafCondition = (condition) => condition.type === 'LEAF';

export const evaluateCondition = (data, { condition }, getValue) => {
    if (isAndCondition(condition)) {
        return condition.conditions.reduce(
            (acc, cur) => acc && evaluateCondition(data, cur),
            true
        );
    } else if (isOrCondition(condition)) {
        return condition.conditions.reduce(
            (acc, cur) => acc || evaluateCondition(data, cur),
            false
        );
    } else if (isLeafCondition(condition)) {
        const value = getValue(condition.scope);
        return value === condition.expectedValue;
    } else {
        // unknown condition
        return true;
    }
}

export const getScopeKeys = ({ condition } = {}) => {
    return [condition?.scope]
}


const evalVisibility = (rule, data, getValue) => {

    const fulfilled = evaluateCondition(data, rule, getValue);


    switch (rule.effect) {
        case RuleEffect.HIDE:
            return !fulfilled;
        case RuleEffect.SHOW:
            return fulfilled;
        // visible by default
        default:
            return true;
    }
}

export const isVisible = function (rule, data, getValue) {

    if (rule) {
        return evalVisibility(rule, data, getValue)
    }
    return true;

}


const evalEnablement = (rule, data, getValue) => {

    const fulfilled = evaluateCondition(data, rule, getValue);


    switch (rule.effect) {
        case RuleEffect.HIDE:
            return !fulfilled;
        case RuleEffect.SHOW:
            return fulfilled;
        // visible by default
        default:
            return true;
    }
}


export const isEnabled = function (rule, data, getValue) {

    if (rule) {
        return evalEnablement(rule, data, getValue)
    }

    return true;
}


export const hasShowRule = (schema) => {

    if (schema.rule && (schema.rule.effect === RuleEffect.HIDE || schema.rule.effect === RuleEffect.SHOW)) {
        return true
    }
    return false
}

export const hasEnableRule = (schema) => {

    if (schema.rule && (schema.rule.effect === RuleEffect.ENABLE || schema.rule.effect === RuleEffect.DISABLE)) {
        return true
    }
    return false
}

