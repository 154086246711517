import { Box, Button, Grid, Modal, Backdrop, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { makeStyles } from '@mui/styles';
import ImageCapture from 'react-image-data-capture';
import KenButton from '../../KenButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  download: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function KenUploadButton(props) {
  const {
    open,
    recordId,
    handleDownload,
    fileType,
    fileName,
    onCapture,
    onError,
    config,
    listofdoc,
    showImgCapture,
    setShowImgCapture,
    
  } = props;
  
  const onChanges = () => {
    setShowImgCapture(true);
  };

  const handleClose = () => {
    setShowImgCapture(false);
  };
  

  
  const classes = useStyles();
  

  return (
    // <div style={{ border: "1px dashed #092682", borderRadius: 6 }}>
    <React.Fragment>
      {!recordId ? (
        <>
          <Button
            variant="secondary"
            onClick={open}
            startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
            style={{
              color: '#092682',
              border: '1px dashed #092682',
              borderRadius: 6,
              width: '100%',
            }}
          >
            Choose or Drag file
            
          </Button>
          {console.log('fileNamefileNamefileNamefileName', listofdoc.title)}
          {(listofdoc.title === 'Passport Size Photo' ||
            listofdoc.title === 'Ration Card' || listofdoc.title === 'Aadhar Card' ||listofdoc.title === 'Electricity Bill'||listofdoc.title === 'Caste Certificate' ||listofdoc.title === 'Income Certificate' || listofdoc.title === 'School leaving Certificate' || listofdoc.title === 'Any other Document'|| listofdoc.title === 'Bank passbook' || listofdoc.title === 'Mark Sheet' || listofdoc.title === 'Marriage Certificate'  ) && (
            <div>
              <Button
                variant="secondary"
                onClick={onChanges}
                startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
                style={{
                  color: '#092682',
                  border: '1px dashed #092682',
                  borderRadius: 6,
                  width: '100%',
                  marginTop: '10px',
                }}
              >
                Capture
              </Button>

              <Modal
                hideBackdrop
                open={showImgCapture}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
              >
                <>
                  <div className={classes.paper}>
                    <Grid
                      container
                      style={{ display: 'flex', marginBottom: '10px' }}
                    >
                      <Grid xs={8}>
                        {' '}
                        <Typography
                          variant="h5"
                          style={{
                            // textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            fontSize: '18px',
                            fontWeight: '600',
                            textTransform: 'capitalize',
                            marginTop: '6px',
                          }}
                        >
                          Capture the document
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        {' '}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <KenButton
                            variant="Error"
                            onClick={handleClose}
                            // icon={<CloseIcon />}
                            label={'close'}
                            style={{
                              background: '#ff0000ad',
                              color: '#fff',
                              height: '20px',
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    {/* <Typography
                          variant="h5"
                          style={{ textAlign: 'center' }}
                        >
                          Capture the document
                        </Typography> */}
                    <div style={{ border: '1px dashed #092682' }}>
                      <ImageCapture
                        onCapture={onCapture}
                        onError={onError}
                        width={630}
                        userMediaConfig={config}
                      />
                    </div>
                    {/* <div>
                          <KenButton
                            variant="Error"
                            onClick={handleClose}
                            // icon={CancelIcon}
                            label={'close'}
                          />
                        </div> */}
                  </div>
                </>
              </Modal>
            </div>
          )}
        </>
      ) : (
        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={5}>
              <Typography
                //   startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
                style={{
                  color: '#ffffff',
                  fontSize: 14,
                  opacity: '0.6',
                  borderRadius: 6,
                  width: '100%',
                  background: '#008000',
                  textTransform: 'uppercase',
                  padding: '12px 0px',
                }}
              >
                Submitted
              </Typography>
            </Grid>
            <Grid item md={2}>
              <FileDownloadIcon
                sx={{ marginTop: '6px' }}
                className={classes.download}
                onClick={() => {
                  handleDownload(recordId, fileType, fileName);
                }}
              />
            </Grid>
            <Grid item md={5}>
              <Button
                variant="secondary"
                onClick={open}
                //   startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
                style={{
                  color: '#092682',
                  border: '1px dashed #092682',
                  borderRadius: 6,
                  width: '100%',
                }}
              >
                ReUpload
              </Button>
              {(listofdoc.title === 'Passport Size Photo' ||
            listofdoc.title === 'Ration Card' || listofdoc.title === 'Aadhar Card' ||listofdoc.title === 'Electricity Bill'||listofdoc.title === 'Caste Certificate' ||listofdoc.title === 'Income Certificate' || listofdoc.title === 'School leaving Certificate' || listofdoc.title === 'Any other Document'|| listofdoc.title === 'Bank passbook' || listofdoc.title === 'Mark Sheet' || listofdoc.title === 'Marriage Certificate'  ) && (
                <div>
                  <Button
                    variant="secondary"
                    onClick={onChanges}
                    // startIcon={
                    //   <AttachFileSharpIcon style={{ color: '#092682' }} />
                    // }
                    style={{
                      color: '#092682',
                      border: '1px dashed #092682',
                      borderRadius: 6,
                      width: '100%',
                      marginTop: '10px',
                    }}
                  >
                    Capture
                  </Button>

                  <Modal
                    hideBackdrop
                    open={showImgCapture}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={classes.modal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                  >
                    <>
                      <div className={classes.paper}>
                        <Grid
                          container
                          style={{ display: 'flex', marginBottom: '10px' }}
                        >
                          <Grid xs={8}>
                            {' '}
                            <Typography
                              variant="h5"
                              style={{
                                // textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                fontSize: '18px',
                                fontWeight: '600',
                                textTransform: 'capitalize',
                                marginTop: '6px',
                              }}
                            >
                              Capture the document
                            </Typography>
                          </Grid>
                          <Grid xs={4}>
                            {' '}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <KenButton
                                variant="Error"
                                onClick={handleClose}
                                // icon={<CloseIcon />}
                                label={'close'}
                                style={{
                                  background: '#ff0000ad',
                                  color: '#fff',
                                  height: '20px',
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        {/* <Typography
                          variant="h5"
                          style={{ textAlign: 'center' }}
                        >
                          Capture the document
                        </Typography> */}
                        <div style={{ border: '1px dashed #092682' }}>
                          <ImageCapture
                            onCapture={onCapture}
                            onError={onError}
                            width={630}
                            userMediaConfig={config}
                          />
                        </div>
                        {/* <div>
                          <KenButton
                            variant="Error"
                            onClick={handleClose}
                            // icon={CancelIcon}
                            label={'close'}
                          />
                        </div> */}
                      </div>
                    </>
                  </Modal>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} textAlign="left">
              <Typography
                align="left"
                variant="subtitle2"
                sx={{
                  marginBottom: '-20px',
                  marginTop: '8px',
                  color: '#7A869A',
                }}
              >
                {fileName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
    // </div>
  );
}
