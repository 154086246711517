import { Grid } from '@mui/material';
import * as React from 'react';
import {
  useForm,
  useFieldArray,
  useWatch,
  useFormContext,
} from 'react-hook-form';
import { FormContext } from '../../Context/formContext';
import { deleteApplicationRecords } from '../../Utils/apiServices';
import { getSplitName } from '../../Utils/Helpers/stringHelper';
// import KenFieldContent from "../KenApplicationForm/Components/KenStepperContent/Components/KenFieldContent";
import KenWidget from '../KenApplicationForm/Components/KenStepperContent/Components/KenWidget';
import KenButton from '../KenButton';
import KenLoader from '../KenLoader';

let renderCount = 0;

const Display = ({ control, index, fieldArrayName }) => {
  const data = useWatch({
    control,
    name: `${fieldArrayName}.${index}`,
  });

  if (!data?.firstName) return null;

  return (
    <div>
      <h3>Submitted Data</h3>
      <p>
        {data?.firstName} {data?.lastName}
      </p>
      <p>{Boolean(data?.working) && data?.working && 'I am working.'}</p>
    </div>
  );
};

const Edit = (props) => {
  const {
    update,
    mainIndex,
    value,
    control,
    bunchOfFields,
    parentId,
    fieldArrayName,
    formJSON,
  } = props;
  const { register, handleSubmit } = useForm({
    defaultValues: value,
  });
  const [values, setValues] = React.useState();
  const methods = useFormContext();
  const getFieldValue = (field) => {
    // console.log("field name::::", field);
    if (!field) return '';
    try {
      //convert names to executable to get value
      const name =
        'values[' + parentId + '][' + mainIndex + '][' + field.name + ']';
      // console.log("name::::", name);
      const keysArray = getSplitName(name, '', true) || [];
      // console.log("keysArray:::", keysArray);
      let str = '';
      keysArray.map((item, index) => {
        if (index === 0) {
          str = item;
        } else {
          str = `${str}['${item}'] && ${str}['${item}']`;
        }
      });
      str = 'values && ' + str;
      // console.log("str::::", str);
      // return "Hello world";
      return eval(str); //covert string to executable
    } catch (err) {
      console.log('error in eval', err);
    }
  };

  React.useEffect(() => {
    const subscription = methods?.watch((value, { name, type }) => {
      // console.log(value, name, type);
      setValues(value);
    });
    return () => subscription.unsubscribe();
  }, [methods?.watch]);

  // React.useEffect(() => {
  // 	console.log("values in useField", values);
  // }, [values]);

  return (
    <>
      {/* <Display
				control={control}
				index={index}
				fieldArrayName={fieldArrayName}
			/> */}
      {bunchOfFields?.map((item, index) => {
        // console.log("conditional field in useFieldArray", item);
        let conditionalField;
        let isConditionMatching;
        if (item.conditional) {
          try {
            conditionalField = formJSON
              ?.filter((s) => item.condition.stepId === s.stepId)[0]
              ?.sections?.filter(
                (sec) => item.condition.sectionId === sec.sectionId
              )[0]
              ?.fields?.filter((f) => item.condition.fieldId === f.fieldId)[0]
              ?.fieldsArray?.filter((fa) => {
                return fa.fieldId === item?.condition?.subFieldId;
              })[0];

            // console.log("conditionalField", conditionalField);
          } catch (err) {
            // alert(
            // 	`The Step Id or Section Id provided in ${field.name} does not exists`
            // );
            console.log(
              'err in searching conditional field',
              item.condition.fieldId,
              err
            );
            return true;
          }

          switch (item?.condition?.conditionType?.operation) {
            case 'equals':
              isConditionMatching =
                getFieldValue(conditionalField) ===
                item.condition.conditionType.value;
              break;

            case 'lessThan':
              isConditionMatching =
                getFieldValue(conditionalField) <
                item.condition.conditionType.value;
              break;
            case 'greaterThan':
              isConditionMatching =
                getFieldValue(conditionalField) >
                item.condition.conditionType.value;
              break;
            case 'lessThanEqual':
              isConditionMatching =
                getFieldValue(conditionalField) <=
                item.condition.conditionType.value;
              break;
            case 'greaterThanEqual':
              isConditionMatching =
                getFieldValue(conditionalField) >=
                item.condition.conditionType.value;
              break;
            case 'true':
              isConditionMatching = true;
              break;
            default:
          }
        }
        // console.log("isConditionMatching field:::", isConditionMatching);
        return (
          <>
            {item?.condition?.behaviorType === 'DISPLAY_CONTROL' ? (
              isConditionMatching &&
              item?.condition?.control?.display && (
                <KenWidget
                  {...item}
                  {...props}
                  name={`${fieldArrayName}[${mainIndex}][${item.name}]`}
                ></KenWidget>
              )
            ) : (
              <KenWidget
                {...item}
                {...props}
                name={`${fieldArrayName}[${mainIndex}][${item.name}]`}
              ></KenWidget>
            )}
            {/* <KenWidget
							{...item}
							{...props}
							name={`${fieldArrayName}[${index}][${item.name}]`}
						></KenWidget> */}
          </>
        );

        return (
          // <input
          // 	placeholder='first name'
          // 	{...register(`${fieldArrayName}[${index}][${item.name}]`, {
          // 		required: true,
          // 	})}
          // />
          // <div style={{ margin: "16px 0px", width: "100%" }}>

          <KenWidget
            {...item}
            {...props}
            name={`${fieldArrayName}[${index}][${item.name}]`}
          ></KenWidget>
          // </div>
        );
        // return <KenFieldContent {...item} {...props} />;
      })}

      {/* {bunchOfFields && bunchOfFields.length > 0 && (
				<>
					{bunchOfFields.map((field, index) => {
						let conditionalField;
						let isConditionMatching;
						if (field.conditional) {
							try {
								console.log(
									"name={`${fieldArrayName}[${index}][${item.name}]`}",
									`${fieldArrayName}[${index}][${field.name}]`
								);
								conditionalField = formJSON
									?.filter((s) => field.condition.stepId === s.stepId)[0]
									?.sections.filter(
										(sec) => field.condition.sectionId === sec.sectionId
									)[0]
									?.bunchOfFields.filter(
										(f) => field.condition.fieldId === f.fieldId
									)[0];
							} catch (err) {
								// alert(
								// 	`The Step Id or Section Id provided in ${field.name} does not exists`
								// );
								console.log(
									"err in searching conditional field",
									field.condition.fieldId,
									err
								);
								return true;
							}

							switch (field?.condition?.conditionType?.operation) {
								case "equals":
									isConditionMatching =
										getFieldValue(conditionalField,index) ===
										field.condition.conditionType.value;
									break;

								case "lessThan":
									isConditionMatching =
										getFieldValue(conditionalField,index) <
										field.condition.conditionType.value;
									break;
								case "greaterThan":
									isConditionMatching =
										getFieldValue(conditionalField,index) >
										field.condition.conditionType.value;
									break;
								case "lessThanEqual":
									isConditionMatching =
										getFieldValue(conditionalField,index) <=
										field.condition.conditionType.value;
									break;
								case "greaterThanEqual":
									isConditionMatching =
										getFieldValue(conditionalField,index) >=
										field.condition.conditionType.value;
									break;
								case "true":
									isConditionMatching = true;
									break;
							}
						}
						// console.log("isConditionMatching field:::", isConditionMatching);
						return field?.condition?.behaviorType === "DISPLAY_CONTROL" ? (
							isConditionMatching && field?.condition?.control?.display && (
								<KenWidget
									{...field}
									{...props}
									name={`${fieldArrayName}[${index}][${field.name}]`}
								></KenWidget>
							)
						) : (
							<KenWidget
								{...field}
								{...props}
								name={`${fieldArrayName}[${index}][${field.name}]`}
							></KenWidget>
						);
					})}
				</>
			)} */}

      {/* <button
				type='button'
				onClick={handleSubmit((data) => {
					update(index, data);
				})}
			>
				Submit
			</button> */}
    </>
  );
};

export default function KenFieldArray(props) {
  const {
    fieldArrayName,
    defaultObject = {},
    bunchOfFields,
    fieldsArray = [],
    fieldId,
    isFormValid,
    name,
    label,
    formJSON,
  } = props;
  const { formState, formDispatch } = React.useContext(FormContext);
  const [loading, setLoading] = React.useState();
  const methods = useFormContext();
  const { control, handleSubmit } = useForm();
  const { fields, append, update, remove, replace } = useFieldArray({
    control,
    name: fieldArrayName,
    defaultValues: {
      [fieldArrayName]: [
        ...(formState?.formData[fieldArrayName] || fieldsArray),
      ],
    },
  });
  const submitForm = (data) => {
    // console.log("additional field data", data);
  };
  const handleRemoveItem = (field, index) => {
    // console.log("field in remove method", field);
  
    const fieldData = formState?.formData[fieldArrayName] || [];

    let dataIndex;
    fields?.forEach((el, ind) => {
      // console.log(el.id, ind, field);
      if (el.id === field.id) {
        dataIndex = ind;
      }
    });

    // console.log(dataIndex, index);
    const currentItem =
      formState?.formData &&
      formState?.formData[fieldArrayName] &&
      formState?.formData[fieldArrayName][dataIndex];
    if (dataIndex > -1) {
      if (currentItem?.recordId) {
        setLoading(true);
        deleteApplicationRecords({
          type: fieldArrayName,
          recordIds: [currentItem?.recordId],
        })
          .then((res) => {
            // console.log("record deleted successfully", res);

            const updatedData = [];
            fieldData?.forEach((elem, ind) => {
              if (ind !== dataIndex) {
                updatedData.push(elem);
              }
            });
            methods?.setValue(fieldArrayName, updatedData);
            // console.log(fieldArrayName, updatedData);
            // console.log("record deleted successfully updatedData", updatedData);
            formDispatch({
              type: 'update',
              payload: {
                ...formState.formData,
                [fieldArrayName]: [...updatedData],
              },
            });
            remove(index);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log('error in delete', err);
          });
      } else {
        const updatedData = fieldData?.splice(dataIndex, 1);
        methods?.setValue(fieldArrayName, updatedData);
        formDispatch({
          type: 'update',
          payload: {
            [fieldArrayName]: [...updatedData],
          },
        });
        remove(index);
      }
    } else {
      const updatedData = fieldData?.splice(dataIndex, 1);
      methods?.setValue(fieldArrayName, updatedData);
      formDispatch({
        type: 'update',
        payload: {
          [fieldArrayName]: [...updatedData],
        },
      });
      remove(index);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(submitForm)();
  };
  React.useEffect(() => {
    console.log(
      fieldArrayName,
      'formState?.formData[fieldArrayName]?.length',
      formState?.formData[fieldArrayName]?.length
    );
    if (!formState?.formData[fieldArrayName]?.length > 0) {
      append({});
    } else {
      formState?.formData[fieldArrayName]?.map((item) => {
        append({});
      });
    }
  }, []);

  return (
    <>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      {/* <form> */}
      {loading && <KenLoader />}
      {fields?.map((field, index) => (
        <React.Fragment key={field.id}>
          <Edit
            parentId={fieldId}
            isFormValid={isFormValid}
            control={control}
            update={update}
            mainIndex={index}
            value={field}
            bunchOfFields={bunchOfFields}
            fieldArrayName={name}
            formJSON={formJSON}
          />
          {fields.length != 1 && (
            <Grid item xs={12} textAlign={'right'}>
              <KenButton
                style={{ margin: '16px 0px' }}
                type="button"
                onClick={() =>
                  fields.length !== 1 && handleRemoveItem(field, index)
                }
              // disabled={fields.length == 1}
              >
                Remove
              </KenButton>
            </Grid>
          )}
        </React.Fragment>
      ))}

      <br />

      <Grid item xs={12} textAlign={'left'}>
        <KenButton
          style={{ margin: '16px 0px' }}
          onClick={() => {
            append({});
          }}
        >
          + Add {label}
        </KenButton>
      </Grid>
      {/* <input type='submit' /> */}
      {/* <KenButton onClick={onSubmit}>Submit</KenButton> */}
      {/* </form> */}
    </>
  );
}
