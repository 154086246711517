import React from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FFFFFF',
    boxShadow:
      '10px 0px 20px rgba(0, 0, 0, 0.04), 2px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    paddingRight: '223px',
    padding: '0',
    'min-height': '60px',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'padding-right': '16px',
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    minHeight: 76,

    [theme.breakpoints.down('xs')]: {
      margin: 12,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '0',
      'min-height': '60px',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      'padding-right': '16px',
      position: 'fixed',
      bottom: '0px',
      width: '100%',
    },
  },
}));

const InstructionFooter = (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <>
      <Box>
        <div className={classes.root}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <React.Fragment>{children}</React.Fragment>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default InstructionFooter;
