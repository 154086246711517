import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';

import { useApplicationContext } from '../../Context/applicationContext';
import {
  addExtrasFieldsToObject,
  applicationAddCurrentStep,
  dirtyValues,
  filterDirtyFields,
  getCurrentStepPayload,
  getDirtyValues,
} from '../../Utils/Helpers/applicationHelper';
import Footer from './Footer';
import Section from './Section';
import { createUpdateApplication } from '../../Utils/apiServices';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAppContext } from '../../Context/appContext';
import KenStepperFooter from '../KenApplicationForm/Components/KenStepperFooter';

function Form({ step: { sections = [] } = {} }, ref) {
  const {
    applicationState: {
      applicationData,
      applicationId,
      activeStep,
      applicantId,
      totalSteps,
    } = {},
    applicationDispatch,
  } = useApplicationContext();
  console.log(useApplicationContext());
  const { dispatch: dispatchAppContext } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  //   Use any pcakge to conver json to schema like json-schema-to-yup
  //   const schema = yup.object().shape({
  //     applicant: yup.object().shape({
  //       firstName: yup.string().required(),
  //       email: yup.string().required(),
  //     }),
  //   });

  console.log(applicationData, '6yu56u');

  // React hook form to maintain form state
  const methods = useForm({
    defaultValues: applicationData,
    mode: 'all',
    reValidateMode: 'onChange',
    // resolver: yupResolver(schema),
    // resolver: yupResolver(validateSchema),
  });

  const {
    formState: { errors, dirtyFields },
    getValues,
    reset,
    setValue,
  } = methods;

  console.log('Form ');
  // reset form on change of activeStep
  useEffect(() => {
    resetForm();
    window.scrollTo(0, 0);
  }, [activeStep]);

  const setEducationHistoryType = (data) => {
    const copyData = [...data];
    if (data?.length === 4) {
      copyData[0].educationHistoryType = 'Post Graduation';
      copyData[1].educationHistoryType = 'Under Graduation';
      copyData[2].educationHistoryType = '12th';
      copyData[3].educationHistoryType = '10th';
    } else if (data?.length === 3) {
      copyData[0].educationHistoryType = 'Under Graduation';
      copyData[1].educationHistoryType = '12th';
      copyData[2].educationHistoryType = '10th';
    } else {
      copyData[0].educationHistoryType = '12th';
      copyData[1].educationHistoryType = '10th';
    }
    return { educationHistories: [...copyData] };
  };

  // Reset form
  const resetForm = (data = applicationData) => {
    reset(data, {
      keepDirty: false,
      keepErrors: false,
      keepDirtyValues: false,
      keepTouched: false,
      keepValues: false,
    });
    // Object.keys(data).forEach((k) => {
    //   setValue(k, data[k], {
    //     shouldDirty: false,
    //     shouldTouch: false,
    //   });
    // });
  };

  // Next handler
  const handleNext = () => {
    if (!_.isEmpty(errors)) {
      //alert "Please resolve all errors"
      console.log('Please resolve all errors');
      enqueueSnackbar(t('messages:Application_Errors_Found'), {
        variant: 'info',
      });
      return;
    }

    const updatedStatus = {
      applicationId,
      applicationStatus: 'Application form Incomplete',
    };

    ref?.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    );
  };

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };

  const handleSave = () => {
    // If any errors in form show alert message
    // Get dirty fields and
    console.log(dirtyFields, errors);

    if (!_.isEmpty(errors)) {
      //alert "Please resolve all errors"
      console.log('Please resolve all errors');
      enqueueSnackbar(t('messages:Application_Errors_Found'), {
        variant: 'error',
      });
      return;
    }

    const changedValues = getDirtyValues(
      filterDirtyFields(dirtyFields),
      getValues()
    );

    // if (changedValues?.educationHistories) {
    //   changedValues = setEducationHistoryType(
    //     changedValues?.educationHistories
    //   );
    // }

    // if no dirtyValues show alert no changes found
    if (_.isEmpty(changedValues)) {
      console.log('No Changes found');
      enqueueSnackbar(t('messages:Application_No_Changes'), {
        variant: 'info',
      });
      return;
    }

    // on error alert to enter all the mandatory fields
    // Check special condition "Relationship >> lastName" if any field of relation has data

    // payload based on the current steps and sections
    // const payload = getCurrentStepPayload(changedValues, applicationData);

    // TODO: check with api if only the dirty can be sent

    // call api to update

    console.log('SAVE>> ', changedValues);

    const payload = addExtrasFieldsToObject(changedValues, {
      applicationId,
      applicantId,
      parentId: applicantId,
      accountID: 'Default',
    });

    createUpdateApplication({ applicationId, ...payload })
      .then((res) => {
        // on success update context data and active step
        if (res?.success) {
          console.log('Saved data successfully');
          enqueueSnackbar(t('messages:Application_Save_Success'), {
            variant: 'success',
          });
          const tempArray = { ...res.data };
          if ((tempArray['applicantRelations'] || []).length === 0) {
            tempArray['applicantRelations'] = [
              {
                firstName: tempArray.applicant.fatherName,
                lastName: tempArray.applicant.lastName,
              },
            ];
          }
          applicationDispatch({
            type: 'update',
            payload: { applicationData: tempArray },
          });
          resetForm(tempArray);
        }
      })
      .catch((err) => {
        //alert user of failure > try again
        console.log('Updating data failed. Try again');
        enqueueSnackbar(t('messages:Application_Save_Failure'), {
          variant: 'error',
        });
      });
  };

  const onSubmit = (formValues) => {
    if (!_.isEmpty(errors)) {
      //alert "Please resolve all errors"
      console.log('Please resolve all errors');
      return;
    }

    // Get values of only dirtyFields

    let changedValues = getDirtyValues(
      filterDirtyFields(dirtyFields),
      getValues()
    );

    // if (changedValues?.educationHistories) {
    //   changedValues = setEducationHistoryType(
    //     changedValues?.educationHistories
    //   );
    // }

    // if (changedValues.applicant?.aadhar) {
    //   applicationData.application = {
    //     ...applicationData.application,
    //     applicantAadhar: changedValues.applicant.aadhar,
    //   };
    // }

   

    console.log('dirtyValues: ', changedValues);
    // if no dirtyValues show alert no changes found
    if (_.isEmpty(changedValues)) {
      console.log('No Changes found');
      applicationDispatch({ type: 'nextStep' });
      return;
    }

    // // payload based on the current steps and sections
    // const payload = getCurrentStepPayload(changedValues, applicationData);
    const payload = addExtrasFieldsToObject(changedValues, {
      applicationId,
      applicantId,
      parentId: applicantId,
      accountID: 'Default',
    });
    // SetLoader
    dispatchAppContext({ type: 'udpateLoading', value: true });
    // update currentStep

    let finalpayload = {
      applicationId,
      ...{
        ...payload,
        applicantRelations: payload.applicantRelations?.map((rel) => {
          const { accountId, ...rest } = rel;
          return rest;
        }),
      },
      ...applicationAddCurrentStep(
        payload.application,
        applicationData,
        activeStep
      ),
    };




    // applicationData.application = {
    //   ...applicationData.application,
    //   courseBecause: finalpayload?.application?.courseBecause,
    //   afterCompletion: finalpayload?.application?.afterCompletion,
    //   applicationStatus: 'Application form Incomplete',
    // };
    // finalpayload.application = applicationData.application;


   



    // if (!finalpayload.application) {
    //   finalpayload.application = {}; // Initialize as empty object if undefined
    // }

    // finalpayload.application.applicantId = "003Hz00000Zf7PuIAJ" ;

    if (activeStep== 0) {
      finalpayload={...finalpayload,applicant:formValues.applicant}
      finalpayload.application.applicationStatus =
        'Application form Incomplete';
        finalpayload.applicant.programName=finalpayload.application.programName;
        finalpayload.applicant.aadharNumber=finalpayload.application.applicantAadhar;
    } 
    // else {
    //   finalpayload.application.applicationStatus = 'Application Completed';
    // }


    createUpdateApplication(finalpayload)
      .then((res) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        // on success update context data and active step
        if (res?.success) {
          // update context
          const tempArray = { ...res.data };
          if ((tempArray['applicantRelations'] || []).length === 0) {
            tempArray['applicantRelations'] = [
              {
                firstName: tempArray.applicant.fatherName,
                lastName: tempArray.applicant.lastName,
              },
            ];
          }

          applicationDispatch({
            type: 'update',
            payload: { applicationData: tempArray },
          });
          applicationDispatch({ type: 'nextStep' });
        }
      })
      .catch((err) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        //alert user of failure > try again
        console.log('Updating data failed. Try again');
      });
    // applicationDispatch({ type: 'nextStep' });
  };

  return (
    <div>
      <form
        ref={ref}
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ marginBottom: 120 }}
      >
        <FormProvider {...methods}>
          {sections.map((section, index) => (
            <Section
              {...section}
              key={index}
              isLast={index === sections.length - 1}
            />
          ))}
        </FormProvider>
      </form>
      {/* // Footer stepper is consistent   */}
      {/* <Footer /> */}
      <KenStepperFooter
        onNext={handleNext}
        onPrev={handlePrev}
        onSave={handleSave}
        activeStep={activeStep}
        totalSteps={totalSteps}
      />
    </div>
  );
}

export default React.forwardRef(Form);
