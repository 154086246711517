import { Box, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import KenButton from '../../../../Components/KenButton';
import KenRadioGroup from '../../../../Components/KenRadioGroup';
import KenSelect from '../../../../Components/KenSelect';
import { AuthContext } from '../../../../Context/authContext';
import { getQuestionare, postQuestionare } from '../../../../Utils/apiServices';
import KenLoader from '../../../../Components/KenLoader/index';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';

const useStyles = makeStyles((theme) => ({
  title: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '18px',
    'line-height': '100%',
    'letter-spacing': '-0.02em',
    'text-transform': 'uppercase',
    color: theme.palette.KenColors.dark,
    display: 'flex',
    position: 'relative',

    '& > .underline': {
      position: 'absolute',
      width: '55px',
      height: '4px',
      top: '24px',
      background: theme.palette.KenColors.statusYellow,
    },
  },
  subHeading: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.KenColors.grey2,
  },
  questions: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.KenColors.dark,
  },
  label: {
    color: theme.palette.KenColors.neutral900,
    textAlign: 'left',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      color: theme.palette.KenColors.neutral900,
    },
  },
}));

export default function OnboardQues() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    state: { user },
  } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getQuestionare(user?.applicantId)
      .then((res) => {
        console.log(res);
        if (res?.success === true && res?.data?.length > 0) {
          let copyData = res?.data;
          copyData?.forEach((app) => {
            app?.questionnaire?.forEach((questionare) => {
              questionare?.questions?.forEach((ques) => {
                ques.hidden = false;
              });
            });
          });
          setLoading(false);
          setData([...copyData]);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleOnChange = (
    event,
    questionareIndex,
    applicationIndex,
    questionIndex,
    question
  ) => {
    const copyData = [...data];
    let indexOfAccomodation = copyData[applicationIndex].questionnaire[
      questionareIndex
    ].questions.findIndex((el) => {
      return el.question === 'Type of accommodation';
    });
    if (
      question.question.includes('Do you need accommodation') &&
      event.target.value === 'No'
    ) {
      copyData[applicationIndex].questionnaire[questionareIndex].questions[
        indexOfAccomodation
      ].hidden = true;
      copyData[applicationIndex].questionnaire[questionareIndex].questions[
        indexOfAccomodation
      ].response = null;
    } else if (
      question.question.includes('Do you need accommodation') &&
      event.target.value === 'Yes'
    ) {
      copyData[applicationIndex].questionnaire[questionareIndex].questions[
        indexOfAccomodation
      ].hidden = false;
    }
    question.response = event.target.value;
    copyData[applicationIndex].questionnaire[questionareIndex].questions[
      questionIndex
    ] = question;
    setData([...copyData]);
  };

  const handleOptions = (data) => {
    const options = data.split(';');
    if (options.length > 0) {
      return options.map((el) => {
        return { label: el, value: el };
      });
    }

    return [];
  };

  const handleSubmit = () => {
    // setLoading(true);
    let flag = false;
    data.forEach((el) => {
      console.log(el);
      el?.questionnaire?.forEach((ques) => {
        ques?.questions?.forEach((question) => {
          if (question?.hidden === false && question?.response === null) {
            flag = true;
          }
        });
      });
    });
    if (flag === true) {
      enqueueSnackbar('Please fill all questions', {
        variant: 'info',
      });
    } else {
      postQuestionare([...data]).then((res) => {
        setLoading(false);
        navigate('/thankYou', {
          state: {
            title: 'Response Received',
            description: 'Your Response has been taken into consideration',
          },
        });
      });
    }
  };

  return (
    <div>
      {loading && <KenLoader />}
      <Box textAlign="left" mt={5} mb={3} mr={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="body8"
              sx={{ float: 'left' }}
              className={classes.title}
            >
              Onboarding Questionnaire
              <span className="underline"></span>
            </Typography>
          </Grid>
          <Grid item>
            {data.length > 0 && (
              <KenButton variant="roundedButton" onClick={handleSubmit}>
                Submit
              </KenButton>
            )}
          </Grid>
        </Grid>
      </Box>
      {data.length > 0 ? (
        data?.map((el, appIndex) => {
          return (
            <>
              <Typography variant="body2" className={classes.label}>
                {el.programName}
              </Typography>
              {el.questionnaire.map((elem, ind) => {
                return (
                  <>
                    <Grid container direction="row" mb={2} mt={2}>
                      <Grid
                        item
                        container
                        md={5}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography className={classes.subHeading}>
                            {elem.term}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.subHeading}>
                            {elem.campus}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider width="100%" />
                    {elem.questions &&
                      elem?.questions.map((question, index) => {
                        return (
                          <>
                            {question.hidden === false && (
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                mt={1}
                              >
                                <Grid item md={6} textAlign="left">
                                  <Typography className={classes.question}>
                                    {
                                      <ForwardOutlinedIcon
                                        sx={{ height: 16 }}
                                      />
                                    }{' '}
                                    {question?.question}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} textAlign="left">
                                  <KenRadioGroup
                                    options={handleOptions(
                                      question?.responseOptions
                                    )}
                                    onChange={(event) => {
                                      handleOnChange(
                                        event,
                                        ind,
                                        appIndex,
                                        index,
                                        question
                                      );
                                    }}
                                    value={question.response}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </>
                        );
                      })}
                  </>
                );
              })}
            </>
          );
        })
      ) : (
        <Typography textAlign="left">No Questionnaire Found</Typography>
      )}
    </div>
  );
}
