import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import KenButton from '../../../../Components/KenButton';
import NoTask from '../../../../Assets/Svg/no-task.svg';
import AddTask from '../AddTask';
import { activeTheme } from '../../../../Utils/Themes/themeHelper';
import { AuthContext } from '../../../../Context/authContext';
import { addTask, getAllTask } from '../../api';
import moment from 'moment';
import {
  deleteApplicationRecords,
  getImportantDates,
} from '../../../../Utils/apiServices';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    '& .MuiTabs-root': {
      border: '1px solid #E3E3E3',
      borderRadius: '12px 12px 0px 0px',
    },
    '& .MuiButtonBase-root': {
      width: '50%',
      'font-family': 'Poppins',
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      color: activeTheme.taskTab.tabColor,
      padding: '8px',
      textTransform: 'capitalize',
    },
    '& .Mui-selected': {
      color: activeTheme.taskTab.activeTabColor,
      background: activeTheme.taskTab.activeTabBackground,
      outline: '1px solid #E3E3E3',
      border: '1px solid #E3E3E3',
      'box-sizing': 'border-box',
      'border-radius': '12px 12px 0px 0px',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tileContainer: {
    textAlign: 'left',
    minHeight: '60px',
    borderBottom: `1px solid ${activeTheme.taskTab.listItemBorderBottom}`,
    paddingBottom: theme.spacing(3),
    '&::marker': {
      color: activeTheme.taskTab.listMarker,
    },
  },
  title: {
    'font-family': 'Poppins',
    'font-style': 'normal',
    'font-weight': '600',
    'font-size': '14px',
    'line-height': '100%',
    color: '#505F79',
    margin: '16px 0 12px 0',
  },
  description: {
    'font-family': 'Poppins',
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    color: '#061938',
    margin: '8px 0',
  },
  addTask: {
    color: '#092682 !important',
    border: '0px !important',
    'box-sizing': 'border-box',
    'border-radius': '32px',
    background: 'white !important',
    'font-family': 'Poppins',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '16px !important',
    'line-height': '100% !important',
  },
  noTaskLabel: {
    'font-family': 'Poppins',
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '16px',
    'line-height': '100%',
    color: '#7A869A',
    margin: '20px auto 16px auto',
  },
  noTaskButton: {
    border: '1px solid #092682 !important',
    'box-sizing': 'border-box',
    filter:
      'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04))',
    'border-radius': '32px',
    background: 'white !important',
    'font-family': 'Poppins',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '14px',
    'line-height': '100%',
    color: '#092682 !important',
    margin: '0px 10px',
    padding: '8px 32px !important',
    borderRadius: '25px !important',
  },
  list: {
    '& ::-webkit-scrollbar': {
      width: 8,
    },
    '& ::-webkit-scrollbar-track': {
      borderRadius: 5,
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#B8B8B8',
      borderRadius: 10,
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#D0D0D0',
    },
    '& ul': {
      maxHeight: 175,
      marginTop: 0,
      height: 175,
      overflow: 'auto',
      paddingLeft: '20px !important',
    },
  },
}));

// const IMPORTANT_TASKS = [
//   {
//     title: "15 Jan | Thursday",
//     description: "Last Date for application -7890",
//   },
//   {
//     title: "15 Jan | Thursday",
//     description: "Last Date for application -7890",
//   },
//   {
//     title: "15 Jan | Thursday",
//     description: "Last Date for application -7890",
//   },
// ];

function ImportantTask(props) {
  const { rows } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  return (
    <Box pt={theme.spacing(2)} className={classes.list}>
      <ul>
        {rows &&
          rows?.map((item) => {
            return (
              <Box component="li" className={classes.tileContainer}>
                <Typography className={classes.title}>{item.title}</Typography>
                <Typography className={classes.description}>
                  {item.description}
                </Typography>
              </Box>
            );
          })}
      </ul>
    </Box>
  );
}

function YourTask() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const classes = useStyles();
  const {
    state: { user },
  } = useContext(AuthContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const loadTasks = async () => {
    try {
      const { data } = await getAllTask(user.id);
      setLoading(false);
      setRows(
        data.map((i) => ({
          ...i,
          title: i.subject,
          day: moment(i.startDate).format('D MMM | ddd'), //16 Jan | Thurs
          time: `${moment(i.startDate).format('hh:mm A')}  -  ${moment(
            i.endDate
          )
            .add(30, 'minutes')
            .format('hh:mm A')}`,
        }))
      );
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      await loadTasks();
    })();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    deleteApplicationRecords({ type: 'task', recordIds: [id] })
      .then((res) => {
        if (res.success === true) {
          const data = rows.filter((el) => el.recordId !== id);
          setRows([...data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {showAddTask && (
        <AddTask
          saving={saving}
          showAddTask={showAddTask}
          onCancel={() => {
            setShowAddTask(false);
          }}
          onAdd={async (data) => {
            try {
              setSaving(true);
              await addTask({
                id: user.id,
                description: data.description,
                subject: data.title,
                date: data.date,
                start: data.start,
                end: data.end,
              });
              await loadTasks();
              setShowAddTask(false);
              setSaving(false);
            } catch (error) {
              alert(error);
            }
          }}
        />
      )}
      {loading ? (
        <CircularProgress
          size={24}
          sx={{
            marginTop: 16,
          }}
        />
      ) : (
        <Box pt={theme.spacing(2)} className={classes.list}>
          {rows.length ? (
            <>
              <Box component="ul">
                {rows.map((item) => {
                  return (
                    <Box component="li" className={classes.tileContainer}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography className={classes.title}>
                            {item.title}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.title}
                            onClick={() => {
                              handleDelete(item.recordId);
                            }}
                          >
                            <DeleteIcon />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box display="flex" justifyContent="space-between">
                        <Typography className={classes.description}>
                          {item.day}
                        </Typography>
                        <Typography className={classes.description}>
                          {item.time}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box textAlign="left" ml="10px">
                <KenButton
                  buttonClass={classes.addTask}
                  onClick={() => {
                    setShowAddTask(true);
                  }}
                >
                  + Add New Task
                </KenButton>
              </Box>
            </>
          ) : (
            <Box
              display={'flex'}
              flexDirection="column"
              justifyContent={'center'}
              alignItems="center"
            >
              <img src={NoTask} alt="" />
              <Typography className={classes.noTaskLabel}>
                No Tasks Yet
              </Typography>
              <Box textAlign="left" ml="10px">
                <KenButton
                  buttonClass={classes.noTaskButton}
                  onClick={() => {
                    setShowAddTask(true);
                  }}
                >
                  Add New Tasks
                </KenButton>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default function TaskTabs(props) {
  const [activeTab, setActiveTab] = useState(1);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Box className={classes.tabsContainer}>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          centered
        >
          <Tab label="Important Dates" value={1} />
          <Tab label="My Tasks" value={2} />
        </Tabs>
      </Box>
      {activeTab === 1 ? <ImportantTask {...props} /> : <YourTask />}
    </Box>
  );
}
