import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import KenTextLabel from '../../../../../../../Components/KenTextLabel';
import KenInput from '../../../../../../../Components/KenInput';
import { Typography, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../../../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  errorStyles: {
    border: '1px solid red',
    padding: '8px',
    borderRadius: '4px',
  },
  box: {
    backgroundColor: '#F3F5F7',
    width: '100%',
    borderRadius: '4px',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingLeft: 0,
      backgroundColor: '#FFFFFF',
    }
  },
  subTitle: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'italic',
      'font-weight': '400',
      'font-size': '12px',
      'line-height': '100%',
      'color': '#A8AFBC',
      paddingLeft: theme.spacing(3.75)
    }
  }
}));

export default function FeeRadioGroup(props) {
  const {
    label,
    value,
    row = true,
    options,
    required,
    optionalLabel,
    error,
    className,
    condition,
    onPartialAmountChange,
    partialAmount,
    partialAmountFieldError,
    labelClassName,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(TABLET_BREAKPOINT));


  return (
    <FormControl>
      {/* <FormLabel id='demo-row-radio-buttons-group-label'>{label}</FormLabel> */}
      <KenTextLabel
        label={label}
        required={required}
        optionalLabel={optionalLabel}
        labelClassName={labelClassName}
      ></KenTextLabel>
      <RadioGroup
        row={row}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        {...props}
        className={error ? classes.errorStyles : className}
      >
        <Grid container spacing={isMobileScreen ? 1 : 2}>
          {options?.map((option) => {
            return (
              <>
                <Grid item xs={8}>
                  <Box className={classes.box} p={1} pl={3}>
                    <FormControlLabel
                      value={option?.value}
                      control={
                        <Radio
                          sx={{
                            '&.Mui-checked': {
                              color: '#25AE88',
                            },
                          }}
                        />
                      }
                      label={option.label}
                      {...option}
                      style={{ color: '#7A869A' }}
                    />
                    {isMobileScreen && option.subTitle &&
                      <Typography className={classes.subTitle}>
                        {option.subTitle}
                      </Typography>
                    }
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  {/* {value === condition && value === option?.value && ( */}
                  <KenInput
                    hideLabel={true}
                    onChange={onPartialAmountChange}
                    value={partialAmount}
                    error={
                      value === condition &&
                      value === option?.value &&
                      partialAmountFieldError
                    }
                    style={{
                      visibility:
                        value === condition && value === option?.value
                          ? ''
                          : 'hidden',
                      //   border: '1px solid #A8AFBC',
                    }}
                    inputProps={{
                      autocomplete: 'off',
                      style: {
                        color: '#7A869A',
                      },
                    }}
                    border="1px solid #A8AFBC"
                  />
                  {/* )} */}
                </Grid>
              </>
            );
          })}
        </Grid>
      </RadioGroup>
      {error && (
        <Typography variant="subtitle1" style={{ color: 'red' }} align="left">
          {error}
        </Typography>
      )}
    </FormControl>
  );
}
