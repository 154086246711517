import React, { useReducer } from "react";

export const initialState = {
	uploadedFiles: [],
	newlyAddedFiles: [],
};

export const reducer = (state, action) => {
	switch (action.type) {
		case "uploaded":
			return {
				...state,
				uploadedFiles: [...state.uploadedFiles, action.payload],
			};
		case "upload":
			return {
				...state,
				newlyAddedFiles: [...state.newlyAddedFiles, action.payload],
			};
		case "delete":
			return {
				...state,
				newlyAddedFiles: [
					...state.newlyAddedFiles.slice(0, action.payload),
					...state.newlyAddedFiles.slice(action.payload + 1),
				],
			};
		default:
			return state;
	}
};

export const UploadDocumentContext = React.createContext();

export const UploadDocumentContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<UploadDocumentContext.Provider
			value={{
				state,
				dispatch,
			}}
		>
			{props.children}
		</UploadDocumentContext.Provider>
	);
};
