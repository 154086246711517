// Note: this is just temporary theme helper for providing support to different client
const themeList = ["SP_JAIN", "ken42"];
let currentTheme = themeList[0];

// Loads theme from localstorage
const localActiveTheme = localStorage.getItem("activeTheme");
if (localActiveTheme && themeList.includes(localActiveTheme)) {
	currentTheme = localActiveTheme;
}

const calendar = {
	SP_JAIN: {
		arrowColor: "#20365C",
		calendarTitle: "#20365C",
		highlightedDayColor: "#158ABB",
		semiHighlightColor: "white",
		semiHighlight: "#158ABB",
		weekdayColor: "#7A869A",
		disabledColor: "#B3BAC5",
		activeDayColor: "#20365C",
	},
	ken42: {
		arrowColor: "#092682",
		calendarTitle: "#091E42",
		highlightedDayColor: "#092682",
		semiHighlight: "#DFE8FF",
		semiHighlightColor: "black",
		weekdayColor: "#7A869A",
		disabledColor: "#B3BAC5",
		activeDayColor: "#20365C",
	},
};
const buttonPrimary = {
	SP_JAIN: {
		textColor: "#092682",
		backgroundColor: "#F6C445",
		disabledTextColor: "rgba(0, 0, 0, 0.26)",
		disabledBackgroundColor: "rgba(0, 0, 0, 0.12)",
		disabledBoxShadow: "none",
		boxShadow:
			"0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
		hoverColor: "",
		borderColor: "",
		btnColor: "#20365C",
	},
	ken42: {
		textColor: "white",
		backgroundColor: "#092682",
		disabledTextColor: "rgba(0, 0, 0, 0.26)",
		disabledBackgroundColor: "rgba(0, 0, 0, 0.12)",
		disabledBoxShadow: "none",
		boxShadow:
			"0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
		hoverColor: "",
		borderColor: "",
		btnColor: '#092682',
	},
};

const taskTab = {
	SP_JAIN: {
		activeTabBackground: "#1E325E",
		activeTabColor: "white",
		tabColor: "#505F79",
		listMarker: "#158ABB",
		listItemBorderBottom: "#F6C446",
	},
	ken42: {
		activeTabBackground: "#DFE8FF",
		activeTabColor: "#092682",
		tabColor: "#505F79",
		listMarker: "#DFE8FF",
		listItemBorderBottom: "#EFEDFA",
	},
};

const componentTheme = {
	SP_JAIN: {},
	ken42: {},
};

[
	{ key: "calendar", style: calendar },
	{ key: "taskTab", style: taskTab },
	{ key: "buttonPrimary", style: buttonPrimary },
].forEach((cTheme) => {
	Object.keys(cTheme.style).forEach((themeKey) => {
		// SP_JAIN -> calendar = styles
		componentTheme[themeKey][cTheme.key] = cTheme.style[themeKey];
	});
});

export const activeTheme = componentTheme[currentTheme];
