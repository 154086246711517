import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { SubTitle, Title } from "../common";
import KenButton from "../../../Components/KenButton";
import OtpInput from "react-otp-input";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import routes from "../../../Constants/routes";
import Countdown from "react-countdown";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { Login } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    minWidth: "500px",
    maxWidth: "500px",
    minHeight: "520px",
    "box-shadow":
      "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
    "border-radius": "16px",
    padding: "48px 84px",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      padding: "8px",
      minWidth: "360px",
      maxWidth: "360px",
    },
  },
  title: {
    marginBottom: "16px",
  },
  subTitle: {
    marginBottom: "16px",
    textAlign: "left",
    "line-height": "150% !important",
  },
  mobileLabel: {
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "100%",
    "text-transform": "capitalize",
    color: "#505F79",
  },
  verify: {
    width: "100%",
    height: "48px",
    marginBottom: "40px",
    fontSize: "16px",
    fontWeight: 600,
    backgroundColor: "#092682",
    fontFamily: "Poppins,sans-serif",
  },
 
  containerStyle: {
    color: "red",
    "& div": {
      width: "70px",
      border: "0",
      borderBottom: "1px solid #A8AFBC;",
      margin: "16px 8px",
    },
  },
  inputStyle: {
    width: "100% !important",
    border: "0",
    height: "35px",

    "&:focus-visible": {
      outline: "none",
    },
  },
  resend: {
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "100%",
    color: "#52C15A",
  },
  backIcon: {
    position: "absolute",
    top: "80px ",
    left: "16px",
    cursor: "pointer",
    zIndex: 10, // Increase z-index to ensure it's on top
  },
}));

let COOL_DOWN_COUNT_IN_SECONDS = 1 * 60;
function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function Verify(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState(
    Date.now() + COOL_DOWN_COUNT_IN_SECONDS * 2000
  );
  const countDownRef = useRef();
  const handleChange = (otp) => {
    setOtp(otp);
    
  };
 

  useEffect(() => {
    if (countDownRef) {
      countDownRef.current.api.start();
    }
  }, [countDown]);

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Button
          className={classes.resend}
          onClick={(e) => {
            e.preventDefault();
            if (typeof props.resendOTP === "function") {
              props.resendOTP();
              
            }
           setOtp('');
            setCountDown(Date.now() + COOL_DOWN_COUNT_IN_SECONDS * 2000);
           
          }}
          
        >
          Resend
        </Button>
        
      );
      
    } else {
      // Render a countdown
      return (
        <span>
          {pad(minutes, 2)}:{pad(seconds, 2)}
        </span>
        
      );
    }
    
  };

  return (
    <Box
      className={classes.contentWrapper}
      display={"flex"}
      flexDirection="column"
      justifyContent={"flex-start"}
      alignItems="flex-start"
    >
      <center>
      <Grid container>
        <Grid item style={{display:"flex",marginLeft:"20%",width:250,marginTop:"4px", cursor:"pointer"}}>
        <ArrowBackIcon
variant="primary"
        buttonClass={classes.backIcon}
        type="submit"
        disabled={props.loading}
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
          }
        } 
      />
        <Title className={classes.title} text={props.title} />
        </Grid>
      </Grid>
      </center>
      <SubTitle className={classes.subTitle} text={props.subTitle} />
      {props.children}
      <OtpInput
        isInputNum
        value={otp}
        onChange={handleChange}
        numInputs={4}
        containerStyle={classes.containerStyle}
        inputStyle={classes.inputStyle}
      />
      <Box
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        my={4}
        width="100%"
      >
        <Countdown
          ref={countDownRef}
          date={countDown}
          renderer={renderer}
        ></Countdown>
      </Box>
      <KenButton
        variant="primary"
        buttonClass={classes.verify}
        type="submit"
        disabled={props.loading}
        onClick={(e) => {
          e.preventDefault();
          if(`${otp}`.length < 4){
            // alert("Please select")
            return enqueueSnackbar(otp?.errorMessage || 'please enter valid OTP', {
              variant: 'error',
            });
          }
          else{
            props.onVerify({ otp });
          }
          // if (`${otp}`.length === 4) {
          //   props.onVerify({ otp });
            
          // }
          
        }}
      >
        {props.submitText ? props.submitText : "Verify"}
       
      </KenButton>
     
      {props.loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "49%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </Box>
  );
}
