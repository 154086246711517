export const handleError = (enqueueSnackbar, error) => {
  if (!error?.response?.data?.success) {
    enqueueSnackbar(
      error?.response?.data?.errorMessage || "Something went wrong",
      { variant: "error" }
    );
  } else {
    enqueueSnackbar(error?.message || "Something went wrong", {
      variant: "error",
    });
  }
};
