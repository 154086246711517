import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useForm } from "react-hook-form";


const useStyles = makeStyles({
  root: {
    width: '100%',
    '&>:nth-child(1)': {
      backgroundColor: '#F3F5F7',
      padding: 0,
    },
  },
  input: {
    borderRadius: '4px',
    padding: '16px',
    backgroundColor: '#F3F5F7',
    color: '#505F79',
    textAlign: 'left',
    '&:focus': {
      backgroundColor: '#FFFFFF',
    },
  },
  error: {
    backgroundColor: '#FFFFFF',
  },
  helperText: {
    marginLeft: 0,
    fontSize: '12px',
    color: '#FC2C32',
  },
});

const StyledTextField = styled(TextField)(({ error, border }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F3F5F7',
      border: border,
    },
    '&:hover fieldset': {
      borderColor: error ? '#FC2C32' : 'grey',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? '#FC2C32' : '#092682',
    },
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#FC2C32',
  },
  '& .MuiInputBase-root-MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline':
    {
      borderColor: '#FC2C32',
    },
  '& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root': {
    backgroundColor: '#F3F5F7',
  },
}));

export default function KenTextField(props) {
  const {
    FormHelperTextProps,
    InputLabelProps,
    inputProps,
    InputProps,
    className,
    error,
    errorLabel,
    children,
    disableKeyDown,
    minDate,
    maxDate,
    name,
    value,
    fieldId
  } = props;



  const classes = useStyles();
  const {setValue,getValues,reset} = useForm();
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (['applicant[mothersName]', 'applicant[branchName]', 'applicant[ifsc]', 'applicant[hobbies]','application[courseBecause]','addresses[1][houseNumber]','addresses[0][houseNumber]','addresses[1][hamlet]','addresses[0][hamlet]','addresses[1][gramPanchayat]','addresses[1][tehsil]','applicant[fatherName]'].includes(name)) {
      e.target.value = e.target.value.toUpperCase();
    }
    if (['firstName','lastName'].includes(fieldId)) {
      e.target.value = value.toUpperCase(); 
    }
  
    if (props.onChange) {
      props.onChange(e);
    }
  };  
 

  return (
    <>
      <StyledTextField
        className={`${className}`}
        classes={{ root: classes.root }}
        InputLabelProps={{ shrink: false, ...InputLabelProps }}
        inputProps={{
          className: error
            ? `${classes.input} ${classes.error}`
            : classes.input,
          ...inputProps,
        }}
        InputProps={{
          components: 'p',
          inputProps: {
            min:
              minDate &&
              (minDate === true
                ? moment().format('YYYY-MM-DD')
                : moment()
                    .subtract(Number(minDate), 'years')
                    .format('YYYY-MM-DD')),
            max:
              maxDate &&
              (maxDate === true
                ? moment().format('YYYY-MM-DD')
                : moment().add(Number(maxDate), 'years').format('YYYY-MM-DD')),
            'data-testid': `test-${name}`,
          },
          ...InputProps,
        }}
        FormHelperTextProps={{
          className: classes.helperText,
          ...FormHelperTextProps,
        }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: '300px',
                marginTop: '8px',
                boxSizing: 'border-box',
                borderTop: '8px solid #ffffff',
                borderBottom: '8px solid #ffffff',
              },
            },
          },
        }}
        {...props}
        onKeyDown={(e) => {
          disableKeyDown && e.preventDefault();
        }}
        onChange={handleChange} 
        label=""
        error={errorLabel ? errorLabel : error}
        helperText={errorLabel ? null : error}
      >
        {children}
      </StyledTextField>
    </>
  );
}
