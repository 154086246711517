import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { TABLET_BREAKPOINT } from '../../../../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F7F8FB',
    borderRadius: '12px 12px 0px 0px',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      borderRadius: 0,
      width: 'calc(100% + 32px)',
      position: 'relative',
      left: '-16px',
    },
  },
  applicationDetails: {
    color: theme.palette.KenColors.grey3,
    padding: '8px 20px',
    background: theme.palette.KenColors.card1,
    // minWidth: 'auto',
    width: 'fit-content',
  },
  title: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '18px',
    'line-height': '100%',
    color: theme.palette.KenColors.dark,
  },
  amount: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '18px',
    'line-height': '100%',
    color: theme.palette.KenColors.dark,
  },
  dueTitle: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '12px',
    'line-height': '100%',
    color: theme.palette.KenColors.dark,
  },
  date: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '12px',
    'line-height': '100%',
    color: '#E75547',
  },
}));

export default function CardHeader(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  return (
    <Box pt={4} className={classes.container}>
      <Typography
        textAlign="left"
        variant="subtitle2"
        className={classes.applicationDetails}
      >
        {props?.applicationName} | {props?.programName}
      </Typography>
      <Box p={2} pl={3}>
        {isMobileScreen ? (
          <Grid container flexDirection="column">
            <Grid
              item
              container
              justifyContent="space-between"
              sx={{ marginBottom: 2 }}
            >
              <Typography variant="h4" className={classes.title}>
                {props?.dueFee?.name} :
              </Typography>
              <Typography variant="h4" className={classes.amount}>
                {props?.dueFee?.remainingAmount || props?.dueFee?.amount}
              </Typography>
            </Grid>
            <Grid item container>
              <Grid>
                <Typography component="span" className={classes.dueTitle}>
                  Due Date:
                </Typography>
                <Typography
                  component="span"
                  style={{ color: '#E75547' }}
                  className={classes.date}
                >
                  {moment(props?.dueFee?.dueDate).format('MMM DD, YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">
              {props?.dueFee?.name} : ₹{' '}
              {props?.dueFee?.remainingAmount || props?.dueFee?.amount}
            </Typography>
            <Typography>
              <Typography component="span">Due Date:</Typography>
              <Typography component="span" style={{ color: '#E75547' }}>
                {moment(props?.dueFee?.dueDate).format('MMM DD, YYYY')}
              </Typography>
            </Typography>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
