import React from "react";

const AppContext = React.createContext({});

const reducer = function (state, action) {
	//   console.log("Context reducer: ", action, state);

	switch (action.type) {
		case "updateConfig":
			return {
				...state,
				config: { ...(state?.config || {}), ...(action.value || {}) },
			};

		case "udpateLoading":
			return {
				...state,
				isLoading: action.value || !state.isLoading
			};

		// Details of the user logged in

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
export const AppContextProvider = ({ children, initValue }) => {
	const [state, dispatch] = React.useReducer(reducer, {
		config: null,
	});
	const value = { state, dispatch };
	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
	const context = React.useContext(AppContext);
	if (!context) {
		throw new Error("useAppContext within provider");
	}
	return context;
};
export default AppContext;
