import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import KenPDFViewer from '../../Components/KenPdfViewer';
import calendar from '../../Assets/Svg/calendar-2.svg';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { getOfferLetter } from '../../Utils/apiServices';
import { AuthContext } from '../../Context/authContext';
import moment from 'moment';
import KenLoader from '../../Components/KenLoader';

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    paddingBottom: '5px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '55px',
      height: '4px',
      bottom: 0,
      left: '0%',
      borderBottom: '4px solid #F6C445',
    },
  },
  container: {
    background: theme.palette.KenColors.card,
    borderRadius: 12,
    height: '100%',
    // maxHeight: 287,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '&:hover': {
      background: '#EDF2FF',
    },
  },
  titleTypo: {
    color: theme.palette.KenColors.dark,
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'left',
  },
  departmentName: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '100%',
    color: theme.palette.KenColors.grey2,
    marginBottom: 0,
    textAlign: 'left',
  },
  applicationDetails: {
    fontSize: 14,
    textAlign: 'left',
    color: theme.palette.KenColors.grey3,
  },
  icon: {
    height: 14,
    marginRight: theme.spacing(1),
  },
  lastDate: {
    fontSize: 14,
    textAlign: 'left',
    color: theme.palette.KenColors.dark,
  },
  lastDateInfo: {
    textAlign: 'left',
    color: theme.palette.KenColors.tertiaryRed503,
  },
}));

export default function OfferLetter() {
  const {
    state: { user },
  } = useContext(AuthContext);
  const [applications, setApplications] = useState();
  const [attachments, setAttachments] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    getOfferLetter(user?.applicantId)
      .then((res) => {
        console.log(res);
        if (res?.success === true) {
          let applicationData = [];
          let attachmentData = [];
          res?.data?.attachments?.forEach((el) => {
            console.log(el);
            applicationData.push(el.application);
            attachmentData.push(el.attachment);
          });
          setAttachments([...attachmentData]);
          setApplications([...applicationData]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box>
      {loading && <KenLoader />}
      <Typography
        variant="h4"
        fontWeight={500}
        mb={2}
        className={classes.title}
      >
        Offer Letters
      </Typography>
      <Grid container spacing={4}>
        {applications &&
          applications.map((el, index) => {
            console.log(attachments[index]);
            return (
              <>
                <Grid item md={3}>
                  <Grid
                    container
                    item
                    md={12}
                    direction="column"
                    justifyContent="space-between"
                    className={classes.container}
                    sx={{ padding: theme.spacing(2) }}
                  >
                    <Grid item sx={{ marginBottom: theme.spacing(2) }}>
                      <>
                        <Grid
                          container
                          direction="column"
                          spacing={1}
                          sx={{ marginLeft: 0, marginTop: 0, width: '100%' }}
                        >
                          <Grid
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                            sx={{ paddingTop: '0 !important' }}
                          >
                            <Grid
                              item
                              container
                              wrap
                              flexDirection="column"
                              position="relative"
                              alignItems="flex-start"
                              sx={{ paddingTop: theme.spacing(1) }}
                            >
                              <Grid item mb={theme.spacing(1.5)}>
                                <Typography className={classes.titleTypo}>
                                  {el?.programName}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  className={classes.departmentName}
                                  gutterBottom
                                >
                                  {el?.specializationName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            paddingTop={`${theme.spacing(1.5)} !important`}
                          >
                            <Divider
                              variant="fullWidth"
                              style={{ background: '#E2E2E2' }}
                            />
                          </Grid>

                          <Grid
                            item
                            paddingTop={`${theme.spacing(1.5)} !important`}
                            sx={{ height: 26 }}
                          >
                            <Typography className={classes.applicationDetails}>
                              {el.courseDuration}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            paddingTop={`${theme.spacing(4)} !important`}
                          >
                            <Grid item sx={{ height: 18 }}>
                              <img
                                src={calendar}
                                className={classes.icon}
                                alt=""
                              />
                            </Grid>
                            <Grid item>
                              <Typography className={classes.lastDate}>
                                Last date:{' '}
                                <span className={classes.lastDateInfo}>
                                  {moment(el?.courseEndDate).format(
                                    'DD MMM YYYY'
                                  )}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    </Grid>
                    <Grid item>
                      <Button
                        className={classes.btnApply}
                        onClick={() => {
                          navigate('/offerLetterDetailed', {
                            state: {
                              attachmentData: attachments[index],
                              applicationData: el,
                            },
                          });
                        }}
                      >
                        {el?.offerLetterStatusByStudent === null ||
                        el?.offerLetterStatusByStudent === 'Generated'
                          ? 'Sign Offer Letter'
                          : 'View Offer Letter'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          })}
      </Grid>
    </Box>
  );
}
