import React, { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, Typography } from '@mui/material';
import { AccountLink, SubTitle, Title } from '../common';
import KenInput from '../../../Components/KenInput';
import KenButton from '../../../Components/KenButton';
import { useNavigate } from 'react-router-dom';
import routes from '../../../Constants/routes';
import Google from '../../../Assets/Svg/google.svg';
import Facebook from '../../../Assets/Svg/facebook.svg';
import LinkedIn from '../../../Assets/Svg/linkedin.svg';
import LoginBG from '../../../Assets/Svg/login-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import { useForm, Controller } from 'react-hook-form';
import validator from 'validator';
import { requestForOTP } from '../api';
import { useSnackbar } from 'notistack';
import { handleError } from '../util';
import {
  signInWithFacebook,
  signInWithGoogle,
} from '../../../Utils/Helpers/firebaseHelper';
import { getApplicantByEmail } from '../../../Utils/apiServices';
import { AuthContext } from '../../../Context/authContext';
import KenLoader from '../../../Components/KenLoader';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },

    // '& form': {
    //   top: () => (1 / window.devicePixelRatio === 1 ? '50%' : 0),
    //   transform: () =>
    //     1 / window.devicePixelRatio === 1 ? 'translateY(-50%)' : 0,
    //   position: () => (1 / window.devicePixelRatio === 1 ? 'relative' : 0),
    // },
  },
  loginContent: {
    minWidth: '500px',
    minHeight: '520px',
    'box-shadow':
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    'border-radius': '16px',
    padding: '11px 52px',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      padding: '8px',
      minWidth: '360px',
    },
  },
  title: {
    marginBottom: '16px',
  },
  subTitle: {
    marginBottom: '32px',
  },
  inputFieldLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    'text-transform': 'capitalize',
    color: '#505F79',
  },
  verify: {
    width: '100%',
    height: '48px',
    marginBottom: '40px',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: '#092682',
    fontFamily: 'Poppins,sans-serif',
  },
  signupWith: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    color: theme.palette.KenColors.neutral100,
  },
  signupWithItem: {
    margin: '0 8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '& img': {
      height: '24px',
      width: '24px',
    },
    '& img.google': {
      width: '37px',
      height: '40px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const mobileOrEmail = (id) => {
  //Note strict mode will enable only validation with country code
  if (
    validator.isEmail(id) ||
    validator.isMobilePhone(id, 'en-IN', { strictMode: false })
  ) {
    return true;
  }

  // return 'Please enter valid email.';
  return 'Please enter valid Mobile number.';
};

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useContext(AuthContext);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await requestForOTP(data);

      if (!res || !res?.success) {
        console.log(res?.errorMessage);
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }
      if(res?.success){
        alert(`Please enter the following OTP: ${res?.OTP}`);

      }

      navigate(routes.loginOTP, {
        state: {
          id: res.applicantId,
          // id: res.userId,
          currentId: data.id,
        },
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
      setTimeout(() => navigate(routes.signup), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleFacebook = async () => {
    setPageLoader(true);
    try {
      const res = await signInWithFacebook();
      console.log(res);
      if (res?.user?.email) {
        const applicantRes = await getApplicantByEmail(res?.user?.email);
        if (!applicantRes || !applicantRes?.success) {
          setPageLoader(false);
          return enqueueSnackbar(
            applicantRes?.errorMessage || 'Something went wrong',
            {
              variant: 'error',
            }
          );
        }

        // TODO: load token
        setPageLoader(false);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: {
              applicantEmail: applicantRes?.applicantEmail,
              applicantId: applicantRes?.applicantId,
              applicantName: applicantRes?.applicantName,
              applicantDob: applicantRes?.applicantDob,
            },
          },
        });
      }
    } catch (err) {
      console.log(err);
      setPageLoader(false);
    }
  };

  const handleGoogle = async () => {
    try {
      setPageLoader(true);
      const res = await signInWithGoogle();
      if (res?.user?.email) {
        const applicantRes = await getApplicantByEmail(res?.user?.email);
        if (!applicantRes || !applicantRes?.success) {
          setPageLoader(false);
          return enqueueSnackbar(
            applicantRes?.errorMessage || 'Something went wrong',
            {
              variant: 'error',
            }
          );
        }

        // TODO: load token
        setPageLoader(false);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: {
              applicantEmail: applicantRes?.applicantEmail,
              applicantId: applicantRes?.applicantId,
              applicantName: applicantRes?.applicantName,
              applicantDob: applicantRes?.applicantDob,
            },
          },
        });
      }
    } catch (err) {
      console.log(err);
      setPageLoader(false);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: '',
    },
  });

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {pageLoader && <KenLoader />}
        <Box
          className={classes.loginContent}
          display={'flex'}
          flexDirection="column"
          justifyContent={'flex-start'}
          alignItems="flex-start"
        >
           <img
          //  style={{ width: '100px', height:'auto',display: 'flex', alignItems: 'center',justifyContent: 'center',flexDirection: 'column'}}
           style={{  width:'400px'}}
                // src={FinalLogo}
                // src={"https://inazstgpfs3001.blob.core.windows.net/assets/Blue-Logo-CAPS-800x117.png"}
                // src={"https://inazstgpfs3001.blob.core.windows.net/assets/ANTTC-Log.png"}
                src={"https://inazstgpfs3001.blob.core.windows.net/assets/Images/LNT/Images/ltpct.png"}
                alt="Ken42 Icon"
                // className={clsx(classes.imageBanner, {
                //   [classes.imageBannerDetailed]: open === true,
                // })}
              />
          <Title className={classes.title} text="Log In" />
          <SubTitle
            className={classes.subTitle}
            text="Please enter your login credentials below to start."
          />
          <Box mb={4} width="100%">
            <Controller
              name="id"
              control={control}
              rules={{ required: true,validate: { mobileOrEmail } }}
              render={({ field, fieldState: { error } }) => (
                <KenInput
                  // required
                  name="id"
                  // label="Email Id"
                  label="Mobile Number"
                  // placeholder="Please enter your Email Id"
                  placeholder="Please enter your Mobile Number"
                  labelClassName={classes.inputFieldLabel}
                  error={error?.message || (error?.type === 'required' && 'This field is required')}
                  {...field}
                />
              )}
            />
          </Box>
          <KenButton
            variant="primary"
            buttonClass={classes.verify}
            type="submit"
            disabled={loading}
          >
            Verify
          </KenButton>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '49%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
          {/* <Box
            display={'flex'}
            justifyContent="center"
            width="100%"
            marginBottom="40px"
          >
            <AccountLink
              text="Don’t have an account?"
              actionText={'Sign up'}
              onAction={() => navigate(routes.signup)}
            />
          </Box> */}
          <Box
            display={'flex'}
            justifyContent="center"
            alignItems={'center'}
            width="100%"
          >
            {/* <Box marginRight={'12px'}>
              <Typography className={classes.signupWith}>
                Or Sign In with
              </Typography>
            </Box> */}
            {/* <Box display={'flex'} justifyContent="space-around">
              <Box className={classes.signupWithItem}>
                <img
                  className="google"
                  src={Google}
                  alt=""
                  onClick={handleGoogle}
                />
              </Box>
              <Box className={classes.signupWithItem}>
                <img src={Facebook} alt="" onClick={handleFacebook} />
              </Box>
              <Box className={classes.signupWithItem}>
                <img src={LinkedIn} alt="" />
              </Box>
            </Box> */}
          </Box>
        </Box>
      </form>
    </Box>
  );
}
