import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Verify from '../common/Verify';
import LoginBG from '../../../Assets/Svg/login-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import routes from '../../../Constants/routes';
import { requestForOTP, verifyOTP } from '../api';
import { useSnackbar } from 'notistack';
import { handleError } from '../util';
import { AuthContext } from '../../../Context/authContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
}));
export default function VerifyEmail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { state: navigateState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!navigateState?.id) {
      navigate(routes.signup);
    }
  }, [navigate, navigateState?.id]);

  const onSubmit = async ({ otp: { otp } }) => {
    setLoading(true);
    try {
      const res = await verifyOTP({
        otp,
        id: navigateState.email,
      });

      if (!res || !res?.success) {
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: navigateState.id,
            applicantEmail: res?.applicantEmail,
            applicantId: res?.applicantId,
            applicantName: res?.applicantName,
          },
        },
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.container}>
      <form>
        <Verify
          loading={loading}
          from="verifyEmail"
          title="Verify Your Email ID"
          subTitle={
            <>
              <span>
                Please enter the OTP sent to your registered Email ID.
              </span>
            </>
          }
          submitText="Verify & Sign Up"
          onVerify={(otp) => {
            onSubmit({ otp });
          }}
          resendOTP={async () => {
            await requestForOTP({ id: navigateState.email });
           
          }}
        ></Verify>
      </form>
    </Box>
  );
}
