import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import FeeStepper from './Components/FeeStepper';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  nav: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  typoCourseDetails: {
    color: theme.palette.KenColors.grey2,
  },
}));

export default function FeeStructure(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const goToDashboard = () => {
    navigate(-1);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      p={isMobileScreen ? theme.spacing(2) : undefined}
    >
      <Grid item sx={{ mb: isMobileScreen ? 1 : 3 }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          className={classes.nav}
          onClick={() => goToDashboard()}
        >
          <ArrowBackIcon style={{ height: '24px', width: '24px' }} />
          <Typography variant="subtitle1" sx={{ ml: 2, fontSize: 16 }}>
            Back
          </Typography>
        </Box>
      </Grid>
      <Grid item sx={{ height: isMobileScreen ? '28px' : undefined }}>
        <Box display="flex" flexDirection="row" alignItems="center">
          {isMobileScreen ? (
            <Grid container spacingX={3} justifyContent="space-between">
              <Grid container item xs={8} spacing={1}>
                <Grid item>
                  <Typography
                    variant="body7"
                    fontWeight={500}
                    className={classes.typoCourseDetails}
                  >
                    {location?.state?.programName}
                  </Typography>
                </Grid>
                {location?.state?.specializations && (
                  <Grid item>
                    <Typography
                      variant="body7"
                      fontWeight={500}
                      className={classes.typoCourseDetails}
                    >
                      {location?.state?.specializations}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body7"
                  fontWeight={500}
                  className={classes.typoCourseDetails}
                >
                  {location?.state?.applicationName}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item>
                <Typography
                  variant="body7"
                  fontWeight={500}
                  className={classes.typoCourseDetails}
                >
                  {location?.state?.programName}
                </Typography>
              </Grid>
              {location?.state?.specializations && (
                <Grid item>
                  <Typography
                    variant="body7"
                    fontWeight={500}
                    className={classes.typoCourseDetails}
                  >
                    {location?.state?.specializations}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography
                  variant="body7"
                  fontWeight={500}
                  className={classes.typoCourseDetails}
                >
                  {location?.state?.applicationName}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <FeeStepper feeData={location.state} />
      </Grid>
    </Grid>
  );
}
