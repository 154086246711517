import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import Verify from "../common/Verify";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../Constants/routes";
import LoginBG from "../../../Assets/Svg/login-bg.svg";
import MobileLoginBG from "../../../Assets/Svg/login-mobile-bg.svg";
import { requestForOTP, verifyOTP } from "../api";
import { useSnackbar } from "notistack";
import { handleError } from "../util";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
}));
export default function VerifyNumber() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state: navigateState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!navigateState?.id) {
      navigate(routes.signup);
    }
  }, [navigate, navigateState?.id]);

  const onSubmit = async ({ otp: { otp } }) => {
    setLoading(true);
    try {
      const res = await verifyOTP({
        otp,
        id: navigateState.phone,
      });

      if (!res?.success) {
        alert(res?.errorMessage || "something went wrong");
        return;
      }

      await requestForOTP({ id: navigateState.email });
      navigate(routes.verifyEmail, {
        state: {
          id: navigateState.applicantId,
          email: navigateState.email,
          phone: navigateState.phone,
        },
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.container}>
      <form>
        <Verify
          loading={loading}
          from="verifyNumber"
          title="Verify Your Number"
          subTitle={
            <>
              <span>
                Please entre the OTP sent on your registered mobile number
              </span>
              <strong style={{ marginLeft: "5px" }}>
                *******{navigateState.phone.slice(-3)}.
              </strong>
            </>
          }
          submitText="Verify & Sign Up"
          onVerify={(otp) => {
            onSubmit({ otp });
          }}
          resendOTP={async () => {
            await requestForOTP({ id: navigateState.phone });
          }}
        ></Verify>
      </form>
    </Box>
  );
}
