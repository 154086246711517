import { useTheme } from '@emotion/react';
import { Grid, Typography, Box, Divider, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { FormContext } from '../../Context/formContext';
import { getSplitName } from '../../Utils/Helpers/stringHelper';
import PrintIcon from '@mui/icons-material/Print';
import useReactToPrint from 'react-to-print';
import { useRef } from 'react';
import React from 'react';
import ReactToPrint from 'react-to-print/lib';
import SummaryToPrint from './summaryToPrint';
import { getDocumentByDocId } from '../../Utils/apiServices';
import { saveAs } from 'file-saver';
import { CheckBox } from '@mui/icons-material';
import moment from 'moment';
import { getData } from '../../Utils/apiServices';
import KenLoader from '../KenLoader';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: 'calc(100% - 16px)',
    paddingTop: theme.spacing(7.125),
    overflow: 'auto',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingTop: theme.spacing(3),
    },
  },
  stepLabel: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  sectionLabel: {
    fontWeight: 400,
  },
  sectionSubLabel: {
    color: '#7A869A',
    fontWeight: 400,
  },
  footerContainer: {
    position: 'absolute',
    bottom: '0px',
    padding: '16px',
    paddingRight: '223px',
    minHeight: 76,
    // height: "100px",
    left: '0px',
    right: '0px',
    overflow: 'hidden',
    background: '#FFFFFF',
    boxShadow:
      '10px 0px 20px rgba(0, 0, 0, 0.04), 2px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    // backgroundColor: "blue",
  },
  container: {
    position: 'absolute',
    top: '270px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  paymentContainer: {
    padding: '16px 223px',
  },
  fieldNameValue: {
    wordBreak: 'break-word',
    fontWeight: 400,
  },
  fieldName: {
    color: '#7A869A',
  },
  fieldValue: {
    color: '#061938',
  },
  fieldContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexDirection: 'column',
      'align-items': 'flex-start',
      'justify-content': 'flex-start',
      'max-width': '100%',
      display: 'flex',
      width: '100%',
      'padding-top': '0 !important',
    },
  },
  fieldKeyContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '40%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      'text-transform': 'capitalize',
      color: '#7A869A',
    },
  },
  fieldValueContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '60%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      color: '#061938',
    },
  },
  divider: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px auto 16px 0',
    },
  },
  dividerContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px',
    },
  },
  dividerWrapper: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '0px',
      paddingTop: '0 !important',
    },
  },
  imgProfile: {
    borderRadius: '4px',
    // padding: '5px',
    width: '150px',
  },
  imgProfile1: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '5px',
    width: '150px',
  },
  heading: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'none',
    },
  },
}));

// const { applicationState } = props;
const KenSummary = React.forwardRef((props, ref) => {
  const { formJson, data, applicationState,applicationId, url, setUrl } = props;
  console.log("lkjhg",applicationState)
  const classes = useStyles();
  const theme = useTheme();
  const { formState, formDispatch } = useContext(FormContext);
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const [file, setFile] = useState();
  const [imgData, setImgData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const convertBase64ToFile = (base64String, fileName, fileType) => {
    // let arr = base64String.split(',');
    let mime = fileType;
    let bstr = atob(base64String);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: fileType });
    return file;
  };
  useEffect(() => {
    if (!(file instanceof File)) {
      setUrl(file);
      setImgData(file);
    }
  }, [file]);

  useEffect(() => {
    if (applicationState?.applicationData?.attachments) {
      const passportChk =
        applicationState?.applicationData?.attachments?.filter(
          (item) =>
            item.title === 'Passport Size Photo' && item.recordId !== null
        );
      if (passportChk.length > 0) {
        let data = passportChk[0];

        if (data?.recordId && data?.fileName && data?.fileType) {
          getDocumentByDocId(data?.recordId)
            .then((res) => {
              if (res?.success && res?.data) {
                // setFilePreview(true);
                setFile(`data:${data?.fileName};base64,${res?.data}`);
                // setPageLoader(false);
                let file = convertBase64ToFile(
                  res?.data,
                  data?.fileName,
                  data?.fileType
                );

                console.log('getDocumentByDocId =>', file);
                // saveAs(file, data?.fileName);
              }
            })
            .catch((err) => {
              console.log(err);
              // setPageLoader(false);
            });
        }
      }
    }
  }, [applicationState?.applicationData]);

  const getNestedFieldValue = (fieldName, index, nestedFieldName) => {
    if (data) {
      if (data[fieldName]) {
        if (data[fieldName][index]) {
          if (data[fieldName][index][nestedFieldName]) {
            return data[fieldName][index][nestedFieldName];
          }
        }
      }
    }
  };

  const checkFormOptions = (value) => {
    let combinedValues = [];
    Object.values(applicationState.formOptions).map((el) => {
      if (Array.isArray(el)) {
        combinedValues.push(...el);
      }
    });
    if (combinedValues.some((el) => el.value === value)) {
      return combinedValues.find((el) => el.value === value)?.label;
    } else if (value === 'true' || value === 'false') {
      return value === 'true' ? 'Yes' : 'No';
    } else {
      return value;
    }
  };

  const getFieldValue = (fieldName) => {
    try {
      //convert names to executable to get value
      const name = 'data[' + fieldName + ']';
      const keysArray = getSplitName(name, '', true) || [];
      let str = '';
      keysArray.map((item, index) => {
        if (index === 0) {
          str = item;
        } else {
          if (isNaN(item)) {
            str = `${str}["${item}"] && ${str}["${item}_select"] || ${str}["${item}"] && ${str}["${item}"]`;
          } else {
            str = `${str}[${item}] && ${str}[${item}]`;
          }
        }
      });
      //   console.log('final str', str);
      // console.log("type::::", field.type, field);
      // if (field.type === "addMultiple") {
      //    return data['newApplicantRelations1'][0]['firstName']
      // }
      return checkFormOptions(eval(str)); //covert string to executable
    } catch {
      console.log('error in eval');
    }
  };

  const getNestedData = (name, structure) => {
    console.log(name, structure);
    const flatStructureData = structure.map((a) => {
      return { name: a?.name, label: a?.label };
    });
    let dataByName;
    if (/\d/.test(name)) {
      dataByName = getFieldValue(name);
    } else {
      dataByName = data[name] || [];
    }
    const allData = dataByName?.map((el) => {
      let obj = {};
      flatStructureData?.forEach((item) => {
        console.log(item.name);
        obj[item.label] =
          item.name.includes('semesterNumber') ||
          item.name.includes('yearNumber')
            ? el[item?.name]?.replace(/\D/g, '')
            : el[item?.name];
      });
      return obj;
    });
    return allData;
  };

  console.log(applicationState);

  let today = new Date().toLocaleDateString();

  return (
    <div ref={ref}>
      <Box p={4}>
        <Grid container spacing={2}>
          <Grid
            item
            sm={3}
            className={classes.heading}
            style={{ textAlign: 'left' }}
          >
            {/* <img 
              src={`https://inazstgpfs3001.blob.core.windows.net/assets/ANTTC-Log.png`}/> */}
            <img
              // src={`https://inazstgpfs3001.blob.core.windows.net/assets/ANTTC-Log.png`}
              src={`https://inazstgpfs3001.blob.core.windows.net/assets/Images/LNT/Images/ltpct-2.png`}
              className={classes.imgProfile}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {' '}
            <Typography
              align={isMobileScreen ? 'left' : 'center'}
              marginBottom={isMobileScreen ? 0 : theme.spacing(4)}
              className={classes.stepLabel}
              fontSize="20px"
            >
              Application Form
            </Typography>
            <Typography
              align="Center"
              marginBottom={isMobileScreen ? 0 : theme.spacing(4)}
              className={classes.stepLabel}
              fontSize="20px"
              fontWeight="bold"
            >
              {props.data.application.programName}
            </Typography>
          </Grid>
          <Grid item sm={3} className={classes.heading}>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {imgData && imgData !== null && (
                <img
                  style={{ height: '192px', width: '192px' }}
                  src={imgData}
                  className={classes.imgProfile1}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Box pt={1}>
          {formJson?.map((step) => {
            console.log(step);
            return (
              <>
                <Grid container spacing={2}>
                  {step?.sections?.map((section, index) => {
                    return (
                      <>
                        {section.sectionVisible === true && (
                          <>
                            <Grid item xs={12}>
                              <Box>
                                {section?.sectionName && (
                                  <Typography
                                    align="left"
                                    marginBottom={
                                      isMobileScreen
                                        ? theme.spacing(1)
                                        : theme.spacing(4)
                                    }
                                    className={classes.sectionLabel}
                                    fontSize="20px"
                                  >
                                    {section?.sectionName}
                                  </Typography>
                                )}
                                {section?.subSectionName && (
                                  <Typography
                                    align="left"
                                    marginBottom={
                                      isMobileScreen
                                        ? theme.spacing(1)
                                        : theme.spacing(4)
                                    }
                                    className={classes.sectionSubLabel}
                                    variant="body2"
                                  >
                                    {section?.subSectionName}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>

                            {/* <Box key={section.sectionId}> */}
                            {/* <Grid container spacing={6} rowSpacing={4}> */}
                            {section.fields.map((field, fieldIndex) => {
                              return field?.metaData?.addMultiple === true ? (
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  md={12}
                                  spacing={3}
                                >
                                  <>
                                    {getNestedData(
                                      field.name,
                                      field.fieldsArray
                                    )?.map((nestedField, nestedFieldIndex) => {
                                      return (
                                        nestedField &&
                                        Object.keys(nestedField).map(
                                          (keyName, keyIndex) => {
                                            console.log(
                                              keyName,
                                              nestedField[keyName],
                                              nestedField
                                            );
                                            if (
                                              nestedField[keyName] !== null &&
                                              keyName !== 'undefined'
                                            )
                                              return (
                                                <>
                                                  <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    md={6}
                                                    spacing={1}
                                                    className={
                                                      classes.fieldContainer
                                                    }
                                                  >
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={6}
                                                      textAlign="left"
                                                      className={
                                                        classes.fieldKeyContainer
                                                      }
                                                    >
                                                      <Typography
                                                        className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                                        variant="h5"
                                                      >
                                                        {keyName}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={6}
                                                      textAlign="left"
                                                      className={
                                                        classes.fieldValueContainer
                                                      }
                                                    >
                                                      <Typography
                                                        className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                                        variant="h5"
                                                      >
                                                        {nestedField[keyName]}
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                  {Object.keys(nestedField)
                                                    ?.length -
                                                    1 ===
                                                    keyIndex &&
                                                    getNestedData(
                                                      field.name,
                                                      field.fieldsArray
                                                    )?.length -
                                                      1 !==
                                                      nestedFieldIndex && (
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                          classes.dividerContainer
                                                        }
                                                      >
                                                        <Divider
                                                          className={
                                                            classes.divider
                                                          }
                                                        />
                                                      </Grid>
                                                    )}
                                                </>
                                              );
                                          }
                                        )
                                      );
                                    })}
                                  </>
                                  {/* </Typography> */}
                                  {/* </Grid> */}
                                </Grid>
                              ) : field?.fieldId ? (
                                <>
                                  {getFieldValue(field.name) && (
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={6}
                                      spacing={2}
                                      className={classes.fieldContainer}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        textAlign="left"
                                        className={classes.fieldKeyContainer}
                                      >
                                        <Typography
                                          className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                          variant="h5"
                                        >
                                          {field?.label}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        textAlign="left"
                                        className={classes.fieldValueContainer}
                                      >
                                        <Typography
                                          className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                          variant="h5"
                                        >
                                          {field && getFieldValue(field.name)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {field?.ui?.divider && (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      className={classes.dividerContainer}
                                    >
                                      <Divider className={classes.divider} />
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <>
                                  {field?.options.map((el) => {
                                    return (
                                      <>
                                        {getFieldValue(
                                          `${field.name}${el.fieldId}`
                                        ) && (
                                          <Grid
                                            item
                                            container
                                            xs={12}
                                            md={6}
                                            spacing={2}
                                            className={classes.fieldContainer}
                                          >
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={6}
                                              textAlign="left"
                                              className={
                                                classes.fieldKeyContainer
                                              }
                                            >
                                              <Typography
                                                className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                                variant="h5"
                                              >
                                                {el?.label}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={6}
                                              textAlign="left"
                                              className={
                                                classes.fieldValueContainer
                                              }
                                            >
                                              <Typography
                                                className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                                variant="h5"
                                              >
                                                {el &&
                                                  getFieldValue(
                                                    `${field.name}${el.fieldId}`
                                                  )}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        )}
                                        {field?.ui?.divider && (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            className={classes.dividerContainer}
                                          >
                                            <Divider
                                              className={classes.divider}
                                            />
                                          </Grid>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                            {/* </Grid> */}
                            {/* {step?.sections?.length - 1 !== index && ( */}
                            <Grid
                              item
                              xs={12}
                              className={classes.dividerWrapper}
                            >
                              <Box
                                mt={3}
                                mb={6}
                                className={classes.dividerContainer}
                              >
                                <Divider className={classes.divider} />
                              </Box>
                            </Grid>
                            {/* )} */}
                            {/* </Box> */}
                          </>
                        )}
                      </>
                    );
                  })}
                </Grid>
              </>
            );
          })}
        </Box>
        {applicationState?.applicationData?.attachments && (
          <div style={{ marginBottom: 80 }}>
            <Typography
              align="left"
              marginBottom={
                isMobileScreen ? theme.spacing(1) : theme.spacing(4)
              }
              className={classes.sectionLabel}
              fontSize="20px"
            >
              Documents Uploaded
            </Typography>
            <Grid
              container
              xs={12}
              md={12}
              spacing={1}
              className={classes.fieldContainer}
            >
              {applicationState?.applicationData?.attachments?.map((el) => {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    spacing={1}
                    className={classes.fieldContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={1}
                      textAlign="left"
                      className={classes.fieldValueContainer}
                    >
                      <CheckBox />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={11}
                      textAlign="left"
                      className={classes.fieldKeyContainer}
                    >
                      <Typography
                        className={`${classes.fieldName} ${classes.fieldNameValue}`}
                        variant="h5"
                      >
                        {el?.title}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
      </Box>
      <Grid item md={12}>
        <Typography style={{ marginLeft: '30px' }}
          align="left"
          marginBottom={isMobileScreen ? 0 : theme.spacing(4)}
          className={classes.stepLabel}
          fontSize="20px"
        >
          Declaration
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          <Box style={{ marginBottom: '150px' }}>
            <Grid item md={13} mb={3}>
              <Typography style={{ marginLeft: '38px' }} align="justify">
                The student hereby undertakes that they have read and understood
                the full requirements, eligibility criteria, terms and
                conditions, and other important information in the Prospectus,
                and confirms that the information provided in this registration
                form is true to the best of their knowledge, understanding that
                any false or misleading statements may result in the
                cancellation of their admission or expulsion from the course at
                any stage.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography style={{ marginLeft: '38px' }}align="left">
                This is a system-generated copy.{' '}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* <Grid item md={12} sm={12} xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          mb={5}
        >
          <Grid item md={4} sm={4} xs={12}>
            <Typography
              marginBottom="60px"
              className={classes.stepLabel}
              fontSize="15px"
            >
              Parent/Guardian's Signature
            </Typography>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <Typography
              className={classes.stepLabel}
              fontSize="15px"
              marginBottom="60px"
            >
              Trainee's Signature
            </Typography>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <Typography
              className={classes.stepLabel}
              fontSize="15px"
              marginBottom="60px"
            >
              Name & Signature Of Principal
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}
    </div>
  );
});

// const KenSummaryStep = (props) => {
//   const componentRef = useRef();
//   const [url, setUrl] = useState();
//   const { applicationState } = props;
//   return (
//     <div>
//       <ReactToPrint
//         // pageStyle="size: 11.0in 8.5in; !important;"
//          pageStyle="@page { size: 8.5in 10.5in; }"
//         trigger={() => (
//           <Grid container justifyContent="space-between">
//             <Grid item></Grid>
//             <Grid item alignItems="right" mb={-5}>
//               <PrintIcon />
//             </Grid>
//           </Grid>
//         )}
//         content={() => componentRef.current}
//       />

//       <KenSummary {...props} url={url} setUrl={setUrl} />
//       <div style={{ display: 'none' }}>
//         <SummaryToPrint ref={componentRef} {...props} url={url} />
//       </div>
//     </div>
//   );
// };

const KenSummaryStep = (props) => {
  const componentRef = useRef();
  const iframeRef = useRef(null);
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(false);

  // Destructure applicationState from props
  const { applicationState } = props;

  // Safely extract applicationId from applicationState
  const applicationId = applicationState?.applicationId;
  console.log("Application ID:", applicationId);

  const fetchAndPrintPDF = async () => {
    setLoading(true)
    if (!applicationId) {
      console.error("Application ID is not available.");
      return;
    }

    try {
      // Fetch the data with the given application ID
      const response = await getData(applicationId);
      setLoading(false)
      // Ensure response has data and extract the base64 string
      if (!response || !response.data) {
        throw new Error("No PDF data found");
      }

      // Extract base64 PDF data from response
      const base64Data = response.data;

      // Decode the base64 PDF data
      const byteCharacters = atob(base64Data);

      // Convert the binary string to a byte array in chunks
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }

      // Create a Blob object from the byte array
      const blob = new Blob(byteArrays, { type: 'application/pdf' });

      // Create a URL for the Blob and set it as the iframe's source
      const pdfUrl = URL.createObjectURL(blob);
      iframeRef.current.src = pdfUrl;

      // Trigger the print dialog once the PDF is loaded in the iframe
      iframeRef.current.onload = () => {
        iframeRef.current.contentWindow.print();
      };
    } catch (error) {
      setLoading(false)
      console.error("Error fetching or displaying PDF:", error);
    }
  };

  return (
    <div>
      {loading && <KenLoader />}
      <ReactToPrint
        pageStyle="size: 11.0in 8.5in; !important;"
        trigger={() => (
          <Grid container justifyContent="space-between">
            <Grid item></Grid>
            <Grid item alignItems="right" mb={-5}>
              <PrintIcon onClick={fetchAndPrintPDF} />
            </Grid>
          </Grid>
        )}
      />
      <KenSummary {...props} url={url} setUrl={setUrl} />
      {/* <div style={{ display: 'none' }}>
        <SummaryToPrint ref={componentRef} {...props} url={url} />
      </div> */}
      <iframe
        ref={iframeRef}
        style={{ display: 'none', width: '100%', height: '600px' }}
        title="PDF Viewer"
      />
    </div>
  );
};

export default KenSummaryStep;

