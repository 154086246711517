import React, { useContext, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import KenButton from '../../Components/KenButton';
import { useNavigate } from 'react-router-dom';
import KenCircularProgressWithLabel from '../../Components/KenProgress/KenCircularProgressWithLabel';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FormContext } from '../../Context/formContext';
import { useAppContext } from '../../Context/appContext';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { ExtraCourseCard } from '../../Containers/HomePage/Components/CoursesBox';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    minHeight: '236px',
    background: theme.palette.card.backgroundColor,
    mixBlendMode: 'normal',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '12px',

    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.card.hover,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '100%',
      marginLeft: 0,
    },
  },

  title: {
    margin: '8px 0px 4px 0px',
    textAlign: 'left',
    fontWeight: '500',
    fontSize: 18,
    marginBottom: 12,
  },
  applicationId: {
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.labels.medium,
  },
  infoLocation: {
    textAlign: 'left',
    marginTop: '4px',
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.labels.medium,
  },
  infoDate: {
    textAlign: 'center',
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    textTransform: 'capitalize',
    color: theme.palette.labels.main,
    marginTop: '24px',
  },
  btnApply: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.primary.main,
    flex: 'none',
    order: '0',
    flexGrow: '0',
  },
  courseCount: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.labels.light,
    textAlign: 'left',
  },
  typoOtherCourse: {
    marginBottom: '60px',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '100%',
  },
}));

export default function CourseCard(props) {
  const classes = useStyles();
  const {
    item,
    programId,
    programName,
    endDate,
    startDate,
    universities,
    location,
    status,
    courseBatch,
    courseDuration,
    variant,
    setApplicationStatus,
    active,
    setActive,
    currentStep,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formState, formDispatch } = useContext(FormContext);
  const {
    state: { config },
    dispatch,
  } = useAppContext();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  const activeBorder = (active) => {
    setActive(active);
  };

  const goToApplicationForm = (item) => {
    formDispatch({
      type: 'new',
      payload: {
        programDetails: item,
        applicationId: item.recordId,
        formId: item.applicationForm,
      },
    });

    navigate({
      pathname: '/form',
      search: `?applicationId=${item.recordId}&formId=${item.applicationForm}&type=${item?.departmentName}`,
    });
  };
  const getCardByVariant = () => {
    switch (variant) {
      case 'no-content':
        return isMobileScreen ? (
          <ExtraCourseCard />
        ) : (
          <React.Fragment>
            <Grid
              container
              spacing={2}
              p={2}
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              height="100%"
              className={classes.mainBox}
              sx={{ margin: '1px 4px', maxWidth: '290px' }}
            >
              <Grid item>
                <Typography className={classes.typoOtherCourse}>
                  Looking For Other Courses?
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  className={classes.btnApply}
                  onClick={() => {
                    navigate('/courses');
                  }}
                >
                  {t('labels:Apply')}
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <Card
              className={classes.mainBox}
              onClick={(e) => {
                setActive(item?.recordId);
                setApplicationStatus(item?.applicationStatus);
              }}
              style={{
                border: active === item?.recordId ? '1px solid #092682 ' : '',
                maxWidth: '290px',
              }}
              elevation={0}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={9} sm={9} md={9} wrap>
                    <Typography className={classes.title} gutterBottom>
                      {programName}
                    </Typography>

                    <Typography className={classes.infoLocation} gutterBottom>
                      {courseDuration} {courseBatch}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3}>
                    <Box display={'flex'} justifyContent="center">
                      {currentStep ? (
                        <KenCircularProgressWithLabel
                          value={
                            item.applicationStatus ==
                            'Application form Submitted'
                              ? 100
                              : (item.currentStep / 6) * 100 || 0
                          }
                          size={35}
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Box className={classes.infoDate}>
                      <Typography gutterBottom>
                        {t('labels:Last_date')}: {endDate}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions style={{ justifyContent: 'center' }}>
                <Button
                  className={classes.btnApply}
                  onClick={() => goToApplicationForm(item)}
                >
                  {item.applicationStatus
                    ? item?.applicationStatus === 'Application form Incomplete'
                      ? 'Complete Application'
                      : 'View Application'
                    : 'Apply'}
                </Button>
                {/* <KenButton
                  label={
                    status ? t("labels:Complete_Application") : t("labels:View")
                  }
                  onClick={() => {
                    navigate("/courses");
                  }}
                /> */}
              </CardActions>
            </Card>
          </React.Fragment>
        );
    }
  };
  return <>{getCardByVariant()}</>;
}
