import React from 'react';
import { Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    lineHeight: '16px',
    color: ' #505F79',
    // textTransform: "capitalize",
    marginBottom: 6,
  },
  optional: {
    // color: theme.palette.KenColors.neutral100,
  },
  labelWrap: {
    marginBottom: 2,
  },
  asterisk: {
    color: 'red',
  },
}));
export default function KenTextLabel(props) {
  const classes = useStyles();
  const { label, required, optionalLabel } = props;

  return (
    <div className={classes.labelWrap}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography
            className={`${classes.label} ${
              props.labelClassName ? props.labelClassName : ''
            }`}
            variant="subtitle1"
          >
            {label}{' '}
            {required ? <span className={classes.asterisk}>*</span> : ''}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.optional} variant="subtitle1">
            {!required && optionalLabel ? '' : ' '}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
