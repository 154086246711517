import { REGEX } from '../../Constants/constant';

export default function getValidation(validationType) {
  switch (validationType) {
    case 'email':
      return REGEX.EMAIL;

    case 'alpha':
      return REGEX.NAME;
    
    case 'alphaWithSpecialChar':
      return REGEX.NAME_WITH_SPECIAL_CHAR;

    case 'alphaNumeric':
      return REGEX.MIXED;

    case 'number':
      return REGEX.ONLYNUMBER;

    case 'numberWithDecimal':
      return REGEX.NUMBERWITHDECIMAL;

      case 'ifsc':
        return REGEX.IFSC;

      case 'pincode':
        return REGEX.PINCODE;

    default:
      return '';
  }
}
