import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, FormControl } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DatePicker from 'react-date-picker';
import { AccountLink, SubTitle, Title } from '../common';
import KenTextLabel from '../../../Components/KenTextLabel';
import KenInput from '../../../Components/KenInput';
import KenSelect from '../../../Components/KenSelect';
import KenButton from '../../../Components/KenButton';
import KenPhoneInput from '../../../Components/KenPhoneInput';
import { useNavigate } from 'react-router-dom';
import routes from '../../../Constants/routes';
import LoginBG from '../../../Assets/Svg/login-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import { Controller, useForm } from 'react-hook-form';
import KenError from '../../../Components/KenError';
import validator from 'validator';
import { requestForOTP, signUp } from '../api';
import { useSnackbar } from 'notistack';
import { handleError } from '../util';

const useStyles = makeStyles((theme) => ({
  customDatePicker: {
    '& .react-date-picker__calendar': {
      zIndex: 2,
    },
    '& .react-date-picker__wrapper': {
      border: 0,
      borderRadius: '4px',
      padding: '6px 16px',
      backgroundColor: '#F3F5F7',
      textAlign: 'left',
      '&:focus': {
        backgroundColor: theme.palette.KenColors.kenWhite,
      },
      '& input': {
        color: theme.palette.KenColors.grey2,
      },
      '& .react-date-picker__inputGroup__divider': {
        color: theme.palette.KenColors.grey2,
      },
      '& .react-date-picker__button': {
        paddingRight: 0,
      },
    },
  },
  // paper: {
  //   position: "absolute",
  //   width: 500,
  //   minHeight: "520px",
  //   maxHeight: "520px",
  //   overflowY: "auto",
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow:
  //     "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
  //   padding: "48px 84px 32px",
  //   borderRadius: "16px"
  // },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },

    '& form': {
      top: () => (1 / window.devicePixelRatio === 1 ? '50%' : 0),
      transform: () =>
        1 / window.devicePixelRatio === 1 ? 'translateY(-50%)' : 0,
      position: () => (1 / window.devicePixelRatio === 1 ? 'relative' : 0),
    },
  },
  loginContent: {
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '16px',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
    },
    '& .scrollbar-container': {
      maxWidth: '500px',
      minHeight: '520px',
      padding: '48px 84px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '16px',
        paddingRight: '16px',
        maxWidth: '360px',
      },
      [theme.breakpoints.up('sm')]: {
        maxHeight: '850px',
        overflowY: 'auto',
      },
    },
  },
  title: {
    margin: () =>
      1 / window.devicePixelRatio !== 1 ? '0 auto 16px auto' : '0 0 16px 0',
  },
  subTitle: {
    margin: () =>
      1 / window.devicePixelRatio !== 1 ? '0 auto 32px auto' : '0 0 32px 0',
  },
  inputFieldLabel: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    textTransform: 'capitalize',
    color: theme.palette.KenColors.grey2,
  },
  verify: {
    width: '100%',
    height: '48px',
    marginBottom: '40px',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: '#092682',
    fontFamily: 'Poppins,sans-serif',
  },
  signupWith: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.KenColors.neutral100,
  },
  signupWithItem: {
    margin: '0 8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '& img': {
      height: '24px',
      width: '24px',
    },
  },
  phoneContainer: {
    display: 'flex',
    '& .phone-input': {
      fontFamily: 'Poppins,sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '100%',
      color: theme.palette.KenColors.grey2,
      padding: '16px',
      order: 2,
      background: '#F3F5F7',
      border: 0,
      height: '48px',
      borderRadius: '4px',
      marginLeft: '11px',
      '&::placeholder': {
        fontSize: '16px',
        lineHeight: '100%',
        color: '#A8AFBC',
        fontFamily: 'Poppins,sans-serif',
      },
    },
    '& .phone-dropdown': {
      position: 'relative',
      order: 1,
      border: 0,
      background: '#F3F5F7',
      '&.open': {
        background: '#F3F5F7',
        '& .selected-flag': {
          background: '#F3F5F7',
        },
      },
      '& .selected-flag': {
        width: '50px',
        padding: '10px',
        '&:hover': {
          background: '#F3F5F7',
        },
      },
    },
  },
}));

const email = (value) => validator.isEmail(value);
const phoneValidator = (value) => {
  if (validator.isMobilePhone(value, 'en-IN', { strictMode: true })) {
    return true;
  }

  return 'Please enter valid phone';
};
const dateValidator = (value) => validator.isDate(value);

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  // const [date, onDateChange] = useState(null);
  // const [program, setProgram] = useState(null);
  // const [modalStyle] = React.useState(getModalStyle);
  // const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, setValue, trigger } = useForm({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      // date: null,
      // program: "",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await signUp(data);

      console.log('inside');

      if (!res || !res?.success) {
        console.log('inside');
        console.log(Array.isArray(res?.errorMessage));
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      if (!res?.success) {
        if (res.errorMessage) {
          alert(res?.errorMessage || 'Something went wrong');
        }
      }

      // TODO: Change it back to verifyLogin and id to phone
      await requestForOTP({ id: data.email });

      if (!res?.success) {
        if (res.errorMessage) {
          console.log('inside');

          alert(
            res?.errorMessage
              ? Array.isArray(res?.errorMessage) &&
                  res?.errorMessage[0]?.errorMessage
              : res?.errorMessage || 'Something went wrong'
          );
        }
      }

      navigate(routes.verifyEmail, {
        state: {
          id: res.contactId,
          email: data.email,
          phone: data.phone,
        },
      });
    } catch (error) {
      return enqueueSnackbar(
        error && error?.response?.data
          ? Array.isArray(error?.response?.data?.errorMessages) &&
              error?.response?.data?.errorMessages[0]?.errorMessage
          : error?.response?.data?.errorMessage || 'Something went wrong',
        {
          variant: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleDateChange = date => {
  //   setSelectedDate(date);
  // };

  // const body = (
  //   <div style={modalStyle} className={classes.paper}>
  //     {/* Modal */}
  //   </div>
  // );

  return (
    <>
      {/* 
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal> */}
      <Box className={classes.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.loginContent}>
            <PerfectScrollbar>
              <Title className={classes.title} text="Get Started!" />
              <SubTitle
                className={classes.subTitle}
                text="Sign Up to start using this platform."
              />
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: true, min: 2 }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenInput
                        required
                        name="firstName"
                        label="First Name"
                        placeholder="Enter First Name"
                        errorLabel={false}
                        labelClassName={classes.inputFieldLabel}
                        {...field}
                        error={error}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenInput
                        name="middleName"
                        label="Middle Name"
                        placeholder="Enter Middle Name"
                        labelClassName={classes.inputFieldLabel}
                        errorLabel={false}
                        {...field}
                        error={error}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true, min: 2 }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenInput
                        required
                        name="lastName"
                        label="Last Name"
                        placeholder="Enter Last Name"
                        errorLabel={false}
                        labelClassName={classes.inputFieldLabel}
                        {...field}
                        error={error}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, validate: email }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenInput
                        required
                        name="email"
                        label="Email"
                        placeholder="Enter Email"
                        labelClassName={classes.inputFieldLabel}
                        errorLabel={false}
                        {...field}
                        error={error}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="phone"
                  control={control}
                  // rules={{ required: true, validate: phoneValidator }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenPhoneInput
                        name="phone"
                        containerClass={classes.phoneContainer}
                        inputClass={`${classes.inputFieldLabel} phone-input`}
                        buttonClass="phone-dropdown"
                        country={'in'}
                        placeholder="Enter Number"
                        errorLabel={false}
                        {...field}
                        error={error}
                        onChange={(phone) => {
                          setValue('phone', phone);
                          trigger('phone');
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
              {/* <FormControl fullWidth style={{ marginBottom: "24px" }}>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: true, validate: dateValidator }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <>
                        <KenTextLabel
                          label="Academic Calendar"
                          labelClassName={classes.inputFieldLabel}
                          required={true}
                        />
                        <DatePicker
                          name="date"
                          className={classes.customDatePicker}
                          clearIcon={null}
                          {...field}
                          onChange={(date) => {
                            setValue("date", date);
                            onDateChange(date);
                          }}
                          value={date}
                          calendarIcon={
                            <img src={calendarIcon} alt="calender" />
                          }
                        />
                      </>
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: "24px" }}>
                <Controller
                  name="program"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenSelect
                        name="program"
                        required
                        select
                        label="Academic Programs"
                        value={program}
                        labelClassName={classes.inputFieldLabel}
                        options={[
                          { label: "Program 1", value: "1" },
                          { label: "Program 2", value: "2" },
                        ]}
                        {...field}
                        error={error}
                        onChange={(event, item, v) => {
                          setProgram(event.target.value);
                          setValue("program", event.target.value);
                        }}
                      />
                    );
                  }}
                />
              </FormControl> */}
              <Box width="100%">
                <KenButton
                  variant="primary"
                  buttonClass={classes.verify}
                  type="submit"
                  disabled={loading}
                >
                  Verify
                </KenButton>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '49%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                )}
              </Box>
              <Box
                display={'flex'}
                justifyContent="center"
                alignItems={'center'}
                width="100%"
              >
                <AccountLink
                  text="Already Have An Account?"
                  actionText={'Log In'}
                  onAction={() => navigate(routes.login)}
                />
              </Box>
            </PerfectScrollbar>
          </Box>
        </form>
      </Box>
    </>
  );
}
