import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useContext, useState } from 'react';
import PaymentCard from '../../Cards/PaymentCard';
import TransactionsHistory from './Components/TransactionsHistory';
import { useNavigate } from 'react-router-dom';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { getFeeDetails } from '../../Utils/apiServices';
import KenLoader from '../../Components/KenLoader';
import { AuthContext } from '../../Context/authContext';

const useStyles = makeStyles((theme) => ({
  bg: {
    background: theme.palette.card.backgroundColor,
  },
  titleContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      background: 'white',
      padding: theme.spacing(2),
    },
  },
  cardContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  },
  title: {
    textTransform: 'uppercase',
    display: 'inline-block',
    paddingBottom: '5px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '55px',
      height: '4px',
      bottom: 0,
      left: '0%',
      borderBottom: '4px solid #F6C445',
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '18px',
      'line-height': '100%',
      color: theme.palette.KenColors.neutral900,
    },
  },
}));

export default function FeePayment(props) {
  // const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  const {
    state: { user },
    dispatch,
  } = useContext(AuthContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState();
  const [transactionHistory, setTransactionHistory] = useState([]);

  const handleViewDetails = (item) => {
    navigate('/feeStructure', { state: item });
  };

  React.useEffect(() => {
    setLoading(true);
    getFeeDetails(user?.applicantId)
      .then((res) => {
        console.log(res);
        if (res?.data) {
          const data = [];
          res?.data?.forEach((el) => {
            let dueFee = [];
            let feeDetailsList = [];
            el?.feeDetailsList?.map((elem) => {
              if (elem?.status === 'Due' || elem?.status === 'Partial') {
                dueFee.push(elem);
              }
              if (
                feeDetailsList.some(
                  (el) => el?.status === 'Due' || el?.status === 'Partial'
                )
              ) {
                elem.status = '';
                feeDetailsList.push({ ...elem });
              } else {
                feeDetailsList.push({ ...elem });
              }
            });
            if (dueFee.length > 0) {
              data.push({
                dueFee: dueFee[0],
                ...el,
                feeDetailsList: feeDetailsList,
              });
            }
          });
          if (data.length === 0 && res?.data[0]?.feeDetailsList.length > 0) {
            res?.data?.forEach((el) => {
              if (el?.feeDetailsList?.length > 0) {
                data.push({ ...el });
              }
            });
          }

          const transHistory = [];
          res?.data?.forEach((el) => {
            let subData = [];
            el?.allTransactionList?.forEach((elem) => {
              let index = subData.findIndex(
                (subD) =>
                  subD.programName === el.programName &&
                  subD.feeType === elem.feeType
              );
              if (index > -1) {
                // subRowsData.push({ programName: el.programName, ...elem });
                const subRowsData = subData[index].subRows;
                subData[index].subRows = [
                  ...subRowsData,
                  { programName: el.programName, ...elem },
                ];
                subData[index].feeAmount = subData[index].subRows.reduce(
                  (total, data) => {
                    return (
                      total +
                      Number(data.status === 'Failed' ? 0 : data.feeAmount)
                    );
                  },
                  0
                );
                delete subData[index].transactionId;
                delete subData[index].mode;
                delete subData[index].transactionDate;
                delete subData[index].status;
                delete subData[index].paymentType;
                delete subData[index].recordId;
              } else {
                // subRowsData.push({ programName: el.programName, ...elem });
                subData.push({
                  programName: el.programName,
                  subRows: [{ programName: el.programName, ...elem }],
                  feeType: elem.feeType,
                });
              }
            });
            transHistory.push(...subData);
          });

          setTransactionHistory([...transHistory]);
          setCardData(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <Box>
      {loading && <KenLoader />}
      <Box
        mb={2}
        p={1}
        className={`${isSmall ? classes.bg : ''} ${classes.titleContainer}`}
      >
        <Grid container item xs={12}>
          <Grid item xs={12} textAlign="left">
            <Typography
              variant="h4"
              fontWeight={500}
              mb={2}
              className={classes.title}
            >
              Fee Payment
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3} className={classes.cardContainer}>
        {cardData.length > 0 ? (
          cardData?.map((item) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                maxWidth={`${theme.spacing(56)} !important`}
              >
                <PaymentCard
                  handleViewDetails={() => {
                    handleViewDetails(item);
                  }}
                  {...item}
                />
              </Grid>
            );
          })
        ) : (
          <Typography variant="h5" sx={{ marginLeft: '32px' }}>
            No Fee Found
          </Typography>
        )}
      </Grid>
      <Box
        mb={2}
        p={isMobileScreen ? theme.spacing(2) : 1}
        className={`${isSmall ? classes.bg : ''} ${classes.titleContainer}`}
      >
        {transactionHistory.length > 0 && (
          <Grid container item xs={12} mt={2}>
            <Grid item xs={12} textAlign="left" mb={2}>
              <Typography
                variant="body7"
                fontWeight={500}
                className={classes.title}
              >
                My Transaction History
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="left">
              {transactionHistory && (
                <TransactionsHistory data={transactionHistory} />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}
