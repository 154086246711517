import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import KenTextLabel from '../KenTextLabel';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  errorStyles: {
    border: '1px solid red',
    padding: '8px',
    borderRadius: '4px',
  },
});

export default function KenRadioGroup(props) {
  const {
    label,
    row = true,
    options,
    required,
    optionalLabel,
    error,
    className,
    radioSx,
    labelClassName,
    ...otherProps
  } = props;
  const classes = useStyles();

  return (
    <FormControl>
      {/* <FormLabel id='demo-row-radio-buttons-group-label'>{label}</FormLabel> */}
      <KenTextLabel
        label={label}
        required={required}
        optionalLabel={optionalLabel}
        labelClassName={labelClassName}
      ></KenTextLabel>
      <RadioGroup
        row={row}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        {...otherProps}
        className={error ? classes.errorStyles : className}
      >
        {options?.map((option) => {
          return (
            <FormControlLabel
              value={option?.value}
              control={<Radio disabled={option?.value ? otherProps?.disabled : false} sx={radioSx} />}
              label={option.label}
              {...option}
            />
          );
        })}
      </RadioGroup>
      {error && (
        <Typography variant="subtitle1" style={{ color: 'red' }} align="left">
          {error}
        </Typography>
      )}
    </FormControl>
  );
}
